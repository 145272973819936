import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "../../store/actions/userManagement/management";
import useCurrency from "../../hooks/useCurrency";
import TransactionTable from "../../components/dashboard/transaction/TransactionTable";
import { fetchAllTransactions } from "../../store/actions/transactions/fetchAllTransactions";

function AllTransaction() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.portfolio.pending) || false;
  const [_currency, getAmount] = useCurrency();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  useEffect(() => {
    dispatch(getAllUser());
    dispatch(fetchAllTransactions(currency));
  }, []);

  return (
    <DashboardLayout>
      <div className="px-4 sm:px-8">
        <div className="flex items-center mb-4 sm:mb-8">
          <img src="/Assets/bread.png" alt="" className="w-6 h-6 mr-2" />
          <img src="/Assets/chev.png" alt="" className="w-6 h-6 mr-2" />
          <span className="font-semibold text-gray-700 text-base">
            Transaction Management
          </span>
          <img src="/Assets/userDot.png" alt="" className="w-6 h-6" />
          <img src="/Assets/chev.png" alt="" className="w-6 h-6 mr-2" />
          <span className="font-semibold text-gray-700 text-base bg-gray-100 p-1 rounded-md w-36 ml-2">
            All Transactions
          </span>
        </div>
        <div className="">
          <div className="flex justify-between items-center flex-wrap mb-5 sm:mb-10">
            <div className="flex justify-between items-center ">
              <h1 className="mb-10 font-[600] text-2xl ">All Transactions</h1>
            </div>
          </div>
          {/* {loading && <Loader />} */}

          <div className="mt-4 sm:mt-10">
            <TransactionTable />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AllTransaction;
