import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "../../../hooks/useCurrency";

const HalcreditTransactionTable = ({}) => {
  const dispatch = useDispatch();

  const [_currency, getAmount] = useCurrency();
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";

    if (!isIconClick) {
      navigate(`/transaction/${params?.row?.transaction_id}`);
    }
  };

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 150,
      renderCell: (params) => params.row?.transaction_id,
    },
    {
      field: "firstName",
      headerName: "Investor First Name",
      width: 150,
      renderCell: (params) => params.row?.investor_name?.split(" ")[0],
    },
    {
      field: "lastName",
      headerName: "Investor Last Name",
      width: 150,
      renderCell: (params) => params.row?.investor_name?.split(" ")[1],
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      width: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => `${_currency}${getAmount(params.row?.amount)}`,
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params) => params.value,
    },

    {
      field: "status",
      headerName: "Status",
      width: 327,
      renderCell: (params) => {
        let color, backgroundColor, statusText;

        switch (params.value) {
          case "1":
            color = "#175CD3";
            backgroundColor = "#B2DDFF";
            statusText = "Pending";
            break;
          case "success":
            color = "#128591";
            backgroundColor = "#E4F9FC";
            statusText = "Successful";

            break;
          case "0":
            color = "#C11574";
            backgroundColor = "#FCCEEE";
            statusText = "Failed";

            break;
          default:
            color = "";
            backgroundColor = "";
        }

        return (
          <div
            style={{
              display: "inline-block",
              width: "fit-content",
              height: "22px",
              paddingLeft: "10px",
              paddingRight: "10px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              textAlign: "center",
              lineHeight: "22px",
              color: color,
              backgroundColor: backgroundColor,
            }}
          >
            {statusText}
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-10 mb-8">
      <div className="py-6 ">
        <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
          <GridTable
            rows={filteredTransactions}
            columns={columns}
            pageSizeOptions={[5]}
            getRowId={(row) => row?.transaction_id}
            handleRowClick={(params, event) => handleRowClick(params, event)}
          />
        </Box>
      </div>
    </div>
  );
};

export default HalcreditTransactionTable;
