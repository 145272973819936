import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_RESEND_OTP } from "../../../config/api";

export const resendOTP = createAsyncThunk("users/resendOTP", async () => {
  try {
    const response = await Client({
      method: "POST",
      path: API_RESEND_OTP,
    });
    toast.success("Verification OTP sent successfully!");

    return response.data;
  } catch (error) {
    const message = proccessErrorMessage(error);
    toast.error(message || "Invalid user ID or token");
    throw error;
  }
});
