import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../Buttons/Button";
import { closeModal } from "../../store/reducers/messageSlice";

const MessageModal = () => {
  const dispatch = useDispatch();
  const { isModalOpened, modalText, success, error } = useSelector(
    (state) => state.message
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog
      open={isModalOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"></DialogTitle>
      <DialogContent className="sm:min-w-[500px]">
        <DialogContentText id="alert-dialog-description">
          <div className="flex items-center flex-col">
            <img
              width="100px"
              height="100px"
              src={`/Assets/${success ? "success" : "error"}.png`}
              alt={success ? "Success" : "Error"}
            />
            <h1 className="mt-5 text-[28px] text-center font-bold">
              {success ? "Success" : "Error"}
            </h1>
            <p className="text-center mt-3">{modalText}</p>
          </div>
        </DialogContentText>
        <div className="flex justify-center mt-5">
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="OK"
            width="100%"
            height="50px"
            margin="10px"
            padding="10px"
            onClick={handleClose}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MessageModal;
