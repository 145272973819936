import React from "react";
import CustomAccordion from "../accordion/CustomAccordion";
import { useSelector } from "react-redux";

function AboutInvestment() {
  const investment = useSelector((state) => state?.investment?.investment);

  const accordionData = [
    {
      title: "Campaign Description",
      content: investment?.description,
    },
    // {
    //   title: "Campaign Key Highlights",
    //   content: investment?.highlights,
    // },
    // {
    //   title: "Campaign Investment Structure",
    //   image: "/Assets/bigstruct.png",
    // },
  ];

  return (
    <div className="w-full mb-12">
      <img src={investment?.banner_link} alt="" className="w-[50%] my-4" />
      {accordionData?.map((data, index) => (
        <CustomAccordion
          key={index}
          title={data.title}
          content={data.content}
          image={data.image}
        />
      ))}
    </div>
  );
}

export default AboutInvestment;
