import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_ASSIGN_USER_ROLE } from "../../../config/api";

export const makeAdmin = createAsyncThunk(
  "roles/post",
  async ({ data: payload, id, cb, setSubmitting }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: `${API_ASSIGN_USER_ROLE}${id}`,
        data: payload,
      });
      setSubmitting(false);

      toast.success(data?.message);

      cb();
    } catch (error) {
      setSubmitting(false);

      const msg = proccessErrorMessage(error);
      toast.error(msg);
    }
  }
);
