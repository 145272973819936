import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_APPROVE_FREE_USER_PLAN,
  API_GET_ALL_USER,
  API_GET_DELETE_USER,
  API_GET_RESTORE_ALL_USERS,
  API_GET_RESTORE_USER,
  API_GET_RETRIEVE_ALL_TRASHED_USERS,
  API_GET_RETRIEVE_ALL_USER_WITH_THRASHED,
  API_GET_UPDATE_USER,
  API_GET_USER_DETAIL,
  API_GET_USER_PLAN,
  API_GET_USER_SUBSCRIPTION_PACKAGE,
  API_USER_EMAIL_UPDATE,
  API_USER_ROLE_ASSIGN,
} from "../../../config/api";

export const getAllUser = createAsyncThunk(
  "allUser/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_ALL_USER,
      });
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);

      return rejectWithValue(error.message);
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "getUser/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_GET_USER_DETAIL}${data?.id}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "updateUser/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: `${API_GET_UPDATE_USER}${data?.id}`,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);

export const restoreUser = createAsyncThunk(
  "userRestore/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_GET_RESTORE_USER}${data?.id}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const restoreAllUser = createAsyncThunk(
  "restoreAllUser/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_RESTORE_ALL_USERS,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const retrieveAllTrashedUser = createAsyncThunk(
  "retrieveAllTrashedUser/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_RETRIEVE_ALL_TRASHED_USERS,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const retrieveAllUserWithTrashed = createAsyncThunk(
  "retrieveAllUserWithTrashedUser/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_RETRIEVE_ALL_USER_WITH_THRASHED,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const userEmailUpdate = createAsyncThunk(
  "userEmail/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_USER_EMAIL_UPDATE}${data?.id}`,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const userRoleAssign = createAsyncThunk(
  "userRole/assign",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_USER_ROLE_ASSIGN,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const userSubscriptionPackage = createAsyncThunk(
  "userSubscription/getPackage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_GET_USER_SUBSCRIPTION_PACKAGE}${data?.id}`,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const getUsersPlan = createAsyncThunk(
  "usersPlan/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_USER_PLAN,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
