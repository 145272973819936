import { createSlice } from "@reduxjs/toolkit";
import { addFunds, getBalance } from "../actions/dashboard/manageFunds";
import { getRoles } from "../actions/roles/getRoles";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: [],
    fetchedData: [],
    paginationModel: {
      page: 0,
      pageSize: 50,
    },
    _rows: [],
    searchWord: "",
    searchRows: null,
    currPageInfo: {},
    error: null,
    pending: false,
    success: false,
    halvestBalance: null,
    savedCreditCards: [],
    transactions: [],
    userGroupFilter: "halvest",
    transactionGroupFilter: "halvest",
    currency: "NGR",
    userFilter: 1,
    investmentFilter: 1,
  },

  reducers: {
    setPaginationModel: (state, action) => {
      state.paginationModel = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearchRows: (state, action) => {
      state.searchRows = action.payload;
    },
    setPageInfo: (state, action) => {
      state.currPageInfo = action.payload;
    },
    setSearchWord: (state, action) => {
      state.searchWord = action.payload;
    },
    setRows: (state, action) => {
      state._rows = action.payload;
    },
    setFetchedData: (state, action) => {
      state.fetchedData = action.payload;
    },
    clearState: (state, action) => {
      state.isLoading = [];
      state.fetchedData = [];
      state.paginationModel = {
        page: 0,
        pageSize: 50,
      };
      state._rows = [];
      state.searchWord = "";
      state.searchRows = null;
      state.currPageInfo = {};
    },
    setUserGroup: (state, action) => {
      state.userGroupFilter = action.payload;
    },
    setTransactionGroup: (state, action) => {
      state.transactionGroupFilter = action.payload;
    },
    setUserFilter: (state, action) => {
      state.userFilter = action.payload;
    },
    setInvestmentFilter: (state, action) => {
      state.investmentFilter = action.payload;
    },
    addCreditCards: (state, action) => {
      state.savedCreditCards = [...state.savedCreditCards, action.payload];
    },
    updateCreditCards: (state, action) => {
      state.savedCreditCards = action.payload;
    },
    switchCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
    builder.addCase(addFunds.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(addFunds.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(addFunds.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getBalance.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getBalance.fulfilled, (state, action) => {
      state.pending = false;
      state.halvestBalance = action.payload;
    });
    builder.addCase(getBalance.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

export const {
  updateCreditCards,
  addCreditCards,
  setUserGroup,
  setTransactionGroup,
  switchCurrency,
  setUserFilter,
  setInvestmentFilter,
  setIsLoading,
  setFetchedData,
  setPageInfo,
  clearState,
  setRows,
  setPaginationModel,
  setSearchRows,
  setSearchWord,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
