import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_APPROVE_FREE_USER_PLAN,
 
} from "../../../config/api";
import { getAllUser } from "./management";

export const approveFreePlan = createAsyncThunk(
    "approveFreePlan/get",
    async ({userId, callback}, {dispatch, rejectWithValue }) => {
      try {
        const response = await Client({
          method: "GET",
          path: `${API_APPROVE_FREE_USER_PLAN}${userId}`,
        });
        toast.success("User Approved Successfully");
        callback()
        dispatch(getAllUser());
        return response?.data;
      } catch (error) {
   
        const msg = proccessErrorMessage(error);
        toast.error(msg);
        return rejectWithValue(msg);
      }
    }
  );
  