import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import CurrencySwitch from "../Buttons/CurrencySwitch";
import NotificationIcon from "../Icons/NotificationIcon";
import { switchCurrency } from "../../store/reducers/dashboardSlice";
import MenubarIcon from "../Icons/MenubarIcon";

const Header = ({ title, handleOpenSideBar }) => {
  const [usdNgnChecked, setUsdNgnChecked] = useState(false);
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  // const currency = "NGR";

  const handleUsdNgnToggle = () => {
    // setUsdNgnChecked(!usdNgnChecked)
    dispatch(switchCurrency(currency === "USD" ? "NGR" : "USD"));
  };

  useEffect(() => {
    // dispatch()
  }, [currency]);
  return (
    <header
      style={{ zIndex: 1 }}
      className="pt-6 pr-10 pl-[15px] border-[#eeeff7] sticky top-0 right-0 bg-white border-[1px] pb-2 lg:pb-[23px] w-full overflow-hidden"
    >
      <div className="header__content flex items-center justify-between w-full lg:justify-between">
        <div className="lg:hidden flex items-center justify-between">
          <img
            src="/Assets/logo.png"
            alt="logo"
            width="80px"
            height="37px"
            className="max-w-max"
          />
        </div>

        <div className="flex items-center justify-between w-full gap-5 lg:justify-between">
          {/* <div className="search-wrapper hidden lg:flex items-center">
            <SearchIcon />
            <input placeholder="Search" />

            <img src="/Assets/helpIcon.png" alt="" style={{ width: "15px" }} />
            <img
              src="/Assets/shortcut.png"
              alt=""
              style={{ width: "25px", marginLeft: "5px" }}
            />
          </div> */}

          <div className="flex items-center gap-1 justify-end w-full ">
            <div className="border border-gray-300 w-[150px] lg:w-[150px] lg:h-[35px] px-4 py-1  rounded-lg lg:bg-[#F9FAFB] hidden sm:flex items-center lg:mr-6 lg:justify-end">
              <CurrencySwitch
                checked={currency === "NGR" ? true : false}
                onChange={handleUsdNgnToggle}
              />
            </div>
            <React.Fragment key={"right"}>
              <Tooltip title="Click here to see your notifications">
                <div style={{ cursor: "pointer" }}>
                  <Badge badgeContent={0} color="error">
                    <NotificationIcon />
                  </Badge>
                </div>
              </Tooltip>
            </React.Fragment>
          </div>

          <div onClick={handleOpenSideBar} className="lg:hidden cursor-pointer">
            <MenubarIcon />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
