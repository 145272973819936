import { createSlice } from "@reduxjs/toolkit";
import { fetchAllWithdrawalReqs } from "../actions/withdrawal/fetchAllWithdrawalReqs";
import { approveWithdrawalReqs } from "../actions/withdrawal/approveWithdrawalReqs";
import { cancelWithdrawalReqs } from "../actions/withdrawal/CancelWithdrawal";

export const withdrawalSlice = createSlice({
  name: "withdrawalRequests",
  initialState: {
    error: null,
    pending: false,
    success: false,
    allrequests: [],
    withdrawalInfo: null,
    approveRequestPending: false,
    cancelRequestPending: false,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchAllWithdrawalReqs.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(fetchAllWithdrawalReqs.fulfilled, (state, action) => {
      state.pending = false;
      state.allrequests = action.payload?.data;
      state.withdrawalInfo = action.payload;
    });
    builder.addCase(fetchAllWithdrawalReqs.rejected, (state, action) => {
      state.pending = false;
    });

    builder.addCase(approveWithdrawalReqs.pending, (state, action) => {
      state.approveRequestPending = true;
    });
    builder.addCase(approveWithdrawalReqs.fulfilled, (state, action) => {
      state.approveRequestPending = false;
    });
    builder.addCase(approveWithdrawalReqs.rejected, (state, action) => {
      state.approveRequestPending = false;
      state.error = action.error.message;
    });

    builder.addCase(cancelWithdrawalReqs.pending, (state, action) => {
      state.cancelRequestPending = true;
    });
    builder.addCase(cancelWithdrawalReqs.fulfilled, (state, action) => {
      state.cancelRequestPending = false;
    });
    builder.addCase(cancelWithdrawalReqs.rejected, (state, action) => {
      state.cancelRequestPending = false;
      state.error = action.error.message;
    });
  },
});

export default withdrawalSlice.reducer;
