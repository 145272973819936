import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "../../store/actions/userManagement/management";
import AdminUserIcon from "../../components/Icons/AdminUserIcon";
import useCurrency from "../../hooks/useCurrency";
import TransactionTable from "../../components/dashboard/transaction/TransactionTable";
import { fetchAllTransactions } from "../../store/actions/transactions/fetchAllTransactions";
import BackArrow2 from "../../components/BackArrow2";

function TransactionDetail() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.portfolio.pending) || false;
  const [_currency, getAmount] = useCurrency();

  const investmentDetails = [
    {
      id: 1,
      icon: <AdminUserIcon />,
      title: "Total Investments Amount",
      cardValue: `${_currency}${getAmount(12059420, 2)}`,
    },

    {
      id: 2,
      icon: <AdminUserIcon />,
      title: "Total Payout Amount",
      cardValue: `${_currency}${getAmount(10059420, 2)}`,
    },

    {
      id: 3,
      icon: <AdminUserIcon />,
      title: "Total Payout",
      cardValue: 350,
    },
    {
      id: 4,
      icon: <AdminUserIcon />,
      title: "Total Subscription Amount",
      cardValue: `${_currency}${getAmount(12059420, 2)}`,
    },
    {
      id: 5,
      icon: <AdminUserIcon />,
      title: "Total Subscribers",
      cardValue: 2000,
    },
    {
      id: 6,
      icon: <AdminUserIcon />,
      title: "Total admin fee Amount",
      cardValue: `${_currency}${getAmount(12059420, 2)}`,
    },
  ];

  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  useEffect(() => {
    dispatch(getAllUser());
    dispatch(fetchAllTransactions(currency));
  }, []);

  return (
    <DashboardLayout>
      <div className="px-4 sm:px-8">
        <div className=" mb-8 flex items-center flex-wrap mb-4 ">
          <div className="flex items-center mt-4 flex-wrap">
            <img src="/Assets/bread.png" alt="" className="w-6 h-6 mr-2" />
            <img src="/Assets/chev.png" alt="" className="w-6 h-6 mr-2" />
            <span className="font-semibold text-gray-700 text-base">
              Transaction Management
            </span>
            <img src="/Assets/userDot.png" alt="" className="w-6 h-6" />
            <img src="/Assets/chev.png" alt="" className="w-6 h-6 " />
            <span className="w-full sm:w-40 font-semibold text-gray-700 text-base p-1 mb-2 sm:mb-0">
              All Transactions
            </span>
            <img src="/Assets/chev.png" alt="" className="w-6 h-6 mr-2" />
            <span className="w-full sm:w-48 font-semibold text-gray-700 text-base bg-gray-100 p-1 rounded-md">
              Transaction Details
            </span>
          </div>
        </div>
        <div className="">
          <BackArrow2 mb={10} ml={0} />

          {loading && <Loader />}

          <div className="mt-4 sm:mt-10">
            <TransactionTable />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default TransactionDetail;
