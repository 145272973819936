import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { borderRadius, Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../SharedLayouts/Loader";
import { BsThreeDots } from "react-icons/bs";
import useCurrency from "../../../hooks/useCurrency";
import { timeformatter } from "../../../config/dateformatter";
import ApplicationMenu from "../../menus/ApplicationMenu";
import { getApplications } from "../../../store/actions/applications/getApplications";

const ApplicationsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [_currency, getAmount] = useCurrency();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const { transactionGroupFilter } = useSelector((state) => state?.dashboard);
  const { applications, applicationsPending } = useSelector(
    (state) => state?.transactions
  );

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);

    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";

    setSelectedRow(params?.row);
    if (!isIconClick) {
      navigate(`/application-summary/${params?.row?.id}`);
    }
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5">
            {timeformatter(params.row?.transactionTime, "formal", false, true)}
          </h3>
        );
      },
    },
    {
      field: "applicationNumber",
      headerName: "Application Number",
      width: 180,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5 ">
            {params.row?.applicationNumber}
          </h3>
        );
      },
    },
    {
      field: "applicant",
      headerName: "Applicant",
      width: 180,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5">
            {params.row?.applicant}
          </h3>
        );
      },
    },
    {
      field: "business_name",
      headerName: "Business Name",
      width: 180,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5">
            {params.row?.businessName}
          </h3>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount (N)",
      width: 150,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5 ">
            {getAmount(params.row?.fullAmount)}
          </h3>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <div className="flex gap-6 items-center">
            {params?.row?.status === "rejected" ? (
              <div className="w-[80px] h-[26px] bg-[#FFEBEB] rounded-[6px] flex justify-center items-center pl-[8px] pr-[8px]">
                <h4 className="text-[#FF5555] text-[11px] font-Poppins font-[500]">
                  Rejected
                </h4>
              </div>
            ) : params?.row?.status === "disbursement ongoing" ? (
              <div className="w-[80px] h-[26px] bg-[#FFF6EB] rounded-[6px] flex justify-center items-center pl-[8px] pr-[8px]">
                <h4 className="text-[#FD9007] text-[11px] font-Poppins font-[500]">
                  Pending
                </h4>
              </div>
            ) : params?.row?.status === "approved" ? (
              <div className="w-[80px] h-[26px] bg-[#EBFFF0] rounded-[6px] flex justify-center items-center pl-[8px] pr-[8px]">
                <h4 className="text-[#01B72F] text-[11px] font-Poppins font-[500]">
                  Approved
                </h4>
              </div>
            ) : (
              <div className="w-[80px] h-[26px] bg-[#FFEBEB] rounded-[6px] flex justify-center items-center pl-[8px] pr-[8px]">
                <h4 className="text-[#FF5555] text-[11px] font-Poppins font-[500] capitalize">
                  {params?.row?.status}
                </h4>
              </div>
            )}
            <div
              onClick={(e) => {
                e.target.setAttribute("data-is-icon", "true");
                handleContextMenu(e);
              }}
              style={{
                cursor: "pointer",
                position: "relative",
              }}
              // className="group relative "
            ></div>
          </div>
        );
      },
    },
    {
      field: "eligibilityStatus",
      headerName: "Eligibility Status",
      width: 150,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <div className="flex gap-6 items-center">
            {params?.row?.status || true ? (
              <div className="w-[68px] h-[22px] bg-[#ECFDF3] rounded-[12px] flex justify-between items-center pl-[8px] pr-[8px]">
                <div className="bg-[#14BA6D] p-[2px] w-[6px] h-[6px] rounded-full"></div>
                <h4 className="text-[#037847] text-[11px] font-[500] font-Poppins">
                  Eligible
                </h4>
              </div>
            ) : (
              <div className="w-[80px] h-[22px] bg-[#F2F4F7] rounded-[12px] flex justify-between items-center pl-[8px] pr-[8px]">
                <div className="bg-[#6C778B] p-[2px] w-[6px] h-[6px] rounded-full"></div>
                <h4 className="text-[#364254] text-[11px] font-[500] font-Poppins">
                  Not Eligible
                </h4>
              </div>
            )}
            <div
              onClick={(e) => {
                e.target.setAttribute("data-is-icon", "true");
                handleContextMenu(e, params?.row);
              }}
              style={{
                cursor: "pointer",
                position: "relative",
              }}
            >
              <BsThreeDots color="#1C1C1C" size={24} />
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getApplications());
  }, []);

  return (
    <div className="mt-10 mb-8 ">
      <div className="bg-white py-6 rounded-lg ">
        {applicationsPending && applications?.length < 1 ? (
          <div className="h-10">
            <Loader />
          </div>
        ) : (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              columns={columns}
              rows={applications || []}
              // searchPlaceholder={"Search for users"}
              getRowId={(row) => row?.id}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              handleRowClick={(params, event) => handleRowClick(params, event)}
            />
          </Box>
        )}
      </div>
      <ApplicationMenu
        open={contextMenu !== null}
        close={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        selectedRow={selectedRow}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default ApplicationsTable;
