import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_GET_INVESTMENT_PORTFOLIO } from "../../../config/api";

export const getPortfolio = createAsyncThunk(
  "portfolio/getList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_INVESTMENT_PORTFOLIO,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);
