import React, { useEffect, useState } from "react";
import BarChartsComponent from "./BarChartsComponent";

const durations = [
  { label: "12 months", value: "12months" },
  { label: "3 months", value: "3months" },
  { label: "30 days", value: "30days" },
  { label: "7 days", value: "7days" },
  { label: "24 hours", value: "24hours" },
];

function NewBarCharts({ allUser }) {
  const [activeDuration, setActiveDuration] = useState("12months");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const currentDate = new Date();

    const filterUsersByDuration = (duration) => {
      if (!allUser) {
        return [];
      }

      switch (duration) {
        case "12months":
          return allUser.filter((user) => {
            const creationDate = new Date(user.account_creation_date);
            const diffInMonths =
              (currentDate - creationDate) / (1000 * 60 * 60 * 24 * 30);
            return diffInMonths <= 12;
          });

        case "3months":
          return allUser.filter((user) => {
            const creationDate = new Date(user.account_creation_date);
            const diffInMonths =
              (currentDate - creationDate) / (1000 * 60 * 60 * 24 * 30);
            return diffInMonths <= 3;
          });

        case "30days":
          return allUser.filter((user) => {
            const creationDate = new Date(user.account_creation_date);
            const diffInDays =
              (currentDate - creationDate) / (1000 * 60 * 60 * 24);
            return diffInDays <= 30;
          });

        case "7days":
          return allUser.filter((user) => {
            const creationDate = new Date(user.account_creation_date);
            const diffInDays =
              (currentDate - creationDate) / (1000 * 60 * 60 * 24);
            return diffInDays <= 7;
          });

        case "24hours":
          return allUser.filter((user) => {
            const creationDate = new Date(user.account_creation_date);
            const diffInHours = (currentDate - creationDate) / (1000 * 60 * 60);
            return diffInHours <= 24;
          });

        default:
          return allUser;
      }
    };

    setFilteredUsers(filterUsersByDuration(activeDuration));
  }, [allUser, activeDuration]);

  return (
    <div className="p-4 bg-white rounded-md shadow-md">
      <h1 className="font-[600] text-2xl mb-10">Membership Growth</h1>

      <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
        {durations.map((duration) => (
          <div
            key={duration.value}
            style={{ cursor: "pointer" }}
            className={`${
              activeDuration === duration.value
                ? "w-28 h-12 bg-gray-100 rounded-lg flex items-center justify-center"
                : ""
            }`}
            onClick={() => setActiveDuration(duration.value)}
          >
            <p
              style={{ color: "#667085" }}
              className={`${
                activeDuration ? "text-black" : "text-[#667085]"
              } font-semibold`}
            >
              {duration.label}
            </p>
          </div>
        ))}
      </div>
      <div className="flex flex-col md:flex-row justify-between mt-8">
        <div className="mb-4 md:mb-0 md:w-[30%]">
          <h1 className="text-[#667085] mb-2">Total Users</h1>
          <p className="mb-4">{allUser?.length}</p>
          <div className="bg-[#EBEAED] h-2 md:h-[8px] w-full md:w-[70%] relative">
            <div className="bg-[#FF9C66] h-full w-1/2 absolute top-0 left-0"></div>
          </div>
        </div>
        <div className="mb-4 md:mb-0 md:w-[30%]">
          <h1 className="text-[#667085] mb-2">New sign-ups</h1>
          <p className="mb-4">76,596</p>
          <div className="bg-[#EBEAED] h-2 md:h-[8px] w-full md:w-[70%] relative">
            <div className="bg-[#004EEB] h-full w-1/2 absolute top-0 left-0"></div>
          </div>
        </div>
        <div className="mb-4 md:mb-0 md:w-[30%]">
          <h1 className="text-[#667085] mb-2">Subscription renewal</h1>
          <p className="mb-4">13,596</p>
          <div className="bg-[#EBEAED] h-2 md:h-[8px] w-full md:w-[70%] relative">
            <div className="bg-[#E478FA] h-full w-1/2 absolute top-0 left-0"></div>
          </div>
        </div>
      </div>

      <BarChartsComponent activeDuration={activeDuration} />
    </div>
  );
}

export default NewBarCharts;
