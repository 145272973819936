import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RevenueChartsComponent = () => {
  const data = [
    { month: "Jan", subscription: 10000, adminFee: 2000 },
    { month: "Feb", subscription: 12000, adminFee: 2500 },
    { month: "Mar", subscription: 15000, adminFee: 3000 },
    { month: "Apr", subscription: 18000, adminFee: 3500 },
    { month: "May", subscription: 20000, adminFee: 4000 },
    { month: "Jun", subscription: 22000, adminFee: 4500 },
    { month: "Jul", subscription: 24000, adminFee: 5000 },
    { month: "Aug", subscription: 26000, adminFee: 5500 },
    { month: "Sep", subscription: 28000, adminFee: 6000 },
    { month: "Oct", subscription: 30000, adminFee: 6500 },
    { month: "Nov", subscription: 32000, adminFee: 7000 },
    { month: "Dec", subscription: 34000, adminFee: 7500 },
  ];

  return (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart
        width={1200}
        height={250}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorSubscription" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorAdminFee" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="month" />
        <YAxis />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Area
          type="monotone"
          dataKey="subscription"
          stroke="#159AA8"
          fillOpacity={0.2}
          fill="#c9f2f2"
        />
        <Area
          type="monotone"
          dataKey="adminFee"
          stroke="#3CD6E6"
          fillOpacity={0.4}
          fill="url(#colorAdminFee)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default RevenueChartsComponent;
