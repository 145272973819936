import React from "react";
import Loader from "../../SharedLayouts/Loader";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";

import useCurrency from "../../../hooks/useCurrency";
import { useSelector } from "react-redux";

function AccountSummaryTable({}) {
  const navigate = useNavigate();
  const isUserLoading = useSelector((state) => state?.user?.allUsersPending);
  const { allUsers, allUsersInfo } = useSelector((state) => state?.user);

  const [_currency, getAmount] = useCurrency();

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";

    if (!isIconClick) {
      navigate(`/account-summary/${params?.row?.id}`);
    }
  };

  const columns = [
    {
      field: "Email",
      headerName: "Email",
      width: 200,
      renderCell: (params) => params.row?.email,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      renderCell: (params) => params.row?.name?.split(" ")[0],
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      renderCell: (params) => params.row?.name?.split(" ")[1],
    },

    {
      field: "userId",
      headerName: "User ID",
      width: 150,
      renderCell: (params) => params.row?.id,
    },
    {
      field: "walletBalance",
      headerName: "Wallet Balance",
      width: 150,
      renderCell: (params) =>
        params.row?.wallet && _currency === "₦"
          ? `₦${getAmount(params.row?.wallet?.walletBalance)}`
          : params.row?.wallet && _currency === "$"
          ? `$${getAmount(params.row?.wallet?.dollarBalance)}`
          : "",
    },
    {
      field: "accountName",
      headerName: "Account  Name",
      width: 150,
      renderCell: (params) => params.row?.wallet?.accountName,
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      width: 150,
      renderCell: (params) => params.row?.wallet?.accountNumber,
    },
    {
      field: "bankName",
      headerName: "Bank Name",
      width: 150,
      renderCell: (params) => params.row?.wallet?.bankName,
    },
  ];

  return (
    <div className="mt-10 mb-8">
      <div className="bg-white">
        {!isUserLoading ? (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              rows={allUsers}
              pageInfo={allUsersInfo}
              columns={columns}
              type={"users"}
              getRowId={(row) => row.id}
              handleRowClick={handleRowClick}
            />
          </Box>
        ) : (
          <div className="h-10">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}

export default AccountSummaryTable;
