import React from "react";

const SimpleButton = ({ title, disabled, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={`group py-[6px] bg-white px-[12px] w-max rounded-md ${
        disabled ? "cursor-not-allowed" : "hover:bg-black"
      }`}
    >
      <h3
        className={`text-xs leading-4 text-[#1C1C1C] ${
          disabled ? "text-gray-500" : "group-hover:text-white cursor-pointer"
        }`}
      >
        {title}
      </h3>
    </button>
  );
};

export default SimpleButton;
