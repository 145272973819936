import React from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import ApplicationsTable from "../../components/dashboard/applications/ApplicationsTable";

const Application = () => {
  return (
    <DashboardLayout>
      <div className="px-4 lg:px-8">
        <div className="flex items-center mb-6 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <div className=" font-semibold text-[#475467] text-sm sm:text-base bg-[#F9FAFB] p-1 px-3 rounded-md  ">
            Applications
          </div>
        </div>

        <div className="">
          <div className="mt-10">
            <ApplicationsTable />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Application;
