import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createRecordJournal } from "../../store/actions/journal/createRecordJournal";

import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";

function CreateInvestment() {
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      createRecordJournal({
        category,
        amount: inputValue,
        cb: () => setLoading(false),
      })
    );
  };

  const categories = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <DashboardLayout>
      <div className="px-2 lg:px-8 ">
        <div className="flex items-center mb-8 flex-row md:items-center">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <Link
            to="/investment"
            className="font-semibold text-[#475467] text-base flex"
          >
            Investment
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 md:mr-2" />
          <span className="font-semibold text-[#475467] text-base bg-[#F9FAFB] p-1 rounded-md md:w-[100px]">
            Chart
          </span>
        </div>
        <div className="ml-4">
          <h1 className=" text-2xl font-bold p-2   mb-2">Chart Of Account </h1>
          <form onSubmit={handleSubmit}>
            <div className="flex  p-2   flex-col  ">
              <div className="   flex flex-col   ">
                <label
                  htmlFor="category"
                  className=" text-[18px] text-normal text-left"
                >
                  Select a category:
                </label>
                <select
                  id="category"
                  value={category}
                  onChange={handleCategoryChange}
                  className=" border-[1px] border-gray-200 p-1 rounded-lg ml-2 h-[45px] w-[250px]"
                >
                  <option
                    value=""
                    className="border-2 h-[45px] bg-gray-300"
                  ></option>
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {`Stage ${category}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex  flex-col">
                <label htmlFor="inputValue" className="text-[18px] p-2">
                  Amount:{" "}
                </label>
                <input
                  className=" h-[45px] p-2 border-[1px]  border-gray-200  w-[250px] rounded-lg"
                  type="text"
                  id="inputValue"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Enter Amount"
                />
              </div>
            </div>
            <button
              type="submit"
              className="text-white text-center bg-[#159AA8] w-[120px] h-[50px] p-2 mt-5 rounded-lg ml-6 "
            >
              {loading ? (
                <div className="flex items-center">
                  <img
                    width="24px"
                    height="24px"
                    alt="Button Icon"
                    src={"/Assets/loading.gif"}
                  />
                  <span className="text-white ">Please wait</span>
                </div>
              ) : (
                <span className="text-white">Submit</span>
              )}
            </button>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CreateInvestment;
