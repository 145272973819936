import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllUser,
  getUserDetail,
} from "../../store/actions/userManagement/management";
import { Link, useNavigate, useParams } from "react-router-dom";

import { deleteUser } from "../../store/actions/userManagement/deleteUser";
import UserProfileForm from "../../components/user/UserProfileForm";
import { getBusinessUserById } from "../../store/actions/userManagement/getBusinessUserById";
import BackArrow from "../../components/BackArrow";

const BusinessUserManagementDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const { id } = useParams();

  const { businessUserDetails } = useSelector((state) => state?.user);

  const userDetail = useSelector((state) => state?.user?.user?.data) || null;

  useEffect(() => {
    if (id) {
      dispatch(getBusinessUserById({ id }));
    }
  }, [id]);

  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <BackArrow mt={20} />
        <div className="ml-9 mt-10">
          <UserProfileForm
            businessUserDetails={businessUserDetails}
            user={{
              can: { sendMe: true },
              created_at: "2021-10-04T14:27:27.000000Z",
              deleted_at: null,
              document_verified: false,
              email: "ridwan@muslimfinancecoach.com",
              email_verified: false,
              free_approved_plan: null,
              hasBeenSuspended: false,
              hasCompletedProfile: true,
              hasPassword: true,
              hasSetPin: false,
              has_subscription: null,
              has_wallet: null,
              id: 2,
              isCompleted: false,
              isDetails: false,
              isEligible: false,
              isGuarantor: false,
              isInvoice: false,
              isStatement: false,
              isVerified: false,
              is_subscribed_to_convertKit: true,
              masterPassword: false,
              name: "Ridwan Sanusi",
              permissions: [],
              profile: null,
              roles: [],
              selectedPlan: "free",
              tyms_uuid: "e8509984a95843e9b57ec49a790ce30f",
              type: null,
              updated_at: "2024-04-04T11:36:49.000000Z",
              user_id: "fcfa4678-aa89-403a-ac44-921217beedca",
            }}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BusinessUserManagementDetail;
