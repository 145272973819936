import React from "react";
import SelectInput from "../inputs/SelectInput";
import { setSelectFeildValue } from "../../Helper/setFieldValues";

const FormSelect = ({
  form,
  id,
  label,
  value,
  defaultValue,
  disabled,
  options,
  hideError,
  handleSelf,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <SelectInput
        form={form}
        hideError={hideError}
        label={label}
        name={id}
        disabled={disabled}
        id={id}
        defaultValue={defaultValue}
        addTitle
        style={{ height: "max-content", marginBottom: 0 }}
        placeholder="Select type"
        options={options}
        selectedOption={value || form?.values[id]}
        handleChange={
          handleSelf
            ? handleSelf
            : (selected) => setSelectFeildValue(form, selected, id)
        }
        icon="/Assets/fi_chevron-down.png"
      />
    </div>
  );
};

export default FormSelect;
