import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_RESET_PASSWORD } from "../../../config/api";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (payload) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_RESET_PASSWORD,
        data: payload.data,
      });

      // If the response status is a success status, show a success toast
      if (payload?.callBack) {
        payload.callBack();
      } else {
        toast.success("Password reset successfully!");
        payload.navigate("/verification/success");
      }
      return response;
    } catch (error) {
      toast.error(proccessErrorMessage(error));
      throw error;
    }
  }
);
