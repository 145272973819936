import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getApplication } from "../../store/actions/applications/getApplication";
import WithStatusAndButton from "../../components/cards/WithStatusAndButton";
import BackArrow from "../../components/BackArrow";
import { approveApplication } from "../../store/actions/applications/approveApplication";
import { rejectApplication } from "../../store/actions/applications/rejectApplication";

const ApplicationDetails = () => {
  const dispatch = useDispatch();

  const {
    application,
    applicationPending,
    rejectingApplication,
    approvingApplication,
  } = useSelector((state) => state?.transactions);

  const { id } = useParams();
  useEffect(() => {
    dispatch(getApplication(id));
  }, [id]);
  const identifications = [
    {
      id: 1,
      status: "Completed",
      Btn1Title: "View profile",
      CardTitle: "Profile Status",
      Info: "Complete your profile to be eligible for Halcredit funding",
      path: "",
    },
    {
      id: 2,
      CardTitle: "Invoice",
      status: "Uploaded",
      Info: "Provide invoice from supplier to be eligible for Halcredit funding",
      Btn1Title: "View invoice",
      path: "",
    },
    {
      id: 3,
      CardTitle: "Guarantors",
      status: "Completed",
      Info: "Complete your profile to be eligible for Halcredit funding",
      Btn1Title: "View guarantors",
      path: "",
    },
    {
      id: 4,
      CardTitle: "Bank Details",
      status: "Eligible",
      Info: "Check your if you’re eligible to get funding from Halcredit ",
      Btn1Title: "View bank details",
      path: "",
    },
    {
      id: 5,
      CardTitle: "Bank Statement ",
      status: "Uploaded",
      Info: "Check your if you’re eligible to get funding from Halcredit ",
      Btn1Title: "View statement",
      path: "",
    },
  ];

  const handleReject = (invoiceID) => {
    const body = {
      status: "rejected",
    };
    dispatch(rejectApplication({ body, id: invoiceID }));
  };
  const handleApprove = (invoiceID) => {
    const body = {
      status: "approved",
      profitPercentage: application?.profitPercentage,
      repaymentDuration: application?.repaymentDuration,
      fullAmount: application?.fullAmount,
    };
    dispatch(approveApplication({ body, id: invoiceID }));
  };

  return (
    <DashboardLayout>
      <div className="flex w-full h-full items-center mb-8 px-4">
        {applicationPending ? (
          <div className="h-10 flex-1">
            <Loader />
          </div>
        ) : (
          <div className="w-full flex-1 flex flex-col h-[85vh] pl-2">
            <BackArrow mt={20} ml={0} />
            <h2 className="font-Poppins font-[500] text-[24px] mt-5 mb-4">
              Application Details
            </h2>
            <div className="flex-1 flex flex-col justify-between ">
              <div className="  pt-4 pb-3 flex-wrap  overflow-auto gap-5 flex">
                {identifications.map(
                  ({ id, status, cardTitle, Btn1Title, Info, path }) => (
                    <WithStatusAndButton
                      key={id}
                      status={status}
                      disabled={true}
                      cardTitle={cardTitle}
                      btnTitle={Btn1Title}
                      info={Info}
                      route={path}
                    />
                  )
                )}
              </div>

              <div className="flex justify-end items-center gap-3 border-t border-t-[#000] pt-4 pb-7 ">
                <div
                  onClick={
                    rejectingApplication
                      ? null
                      : () => handleReject(application?.invoiceID)
                  }
                  className="w-[96px] h-[54px] cursor-pointer justify-center rounded-[5px] items-center flex bg-[#FFEBEB]"
                >
                  {rejectingApplication ? (
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading.gif"}
                    />
                  ) : (
                    <h4 className="font-[500] text-base text-[#FF5555]">
                      Reject
                    </h4>
                  )}
                </div>

                <div
                  onClick={
                    approvingApplication
                      ? null
                      : () => handleApprove(application?.invoiceID)
                  }
                  className="w-[111px] cursor-pointer rounded-[5px] h-[54px] justify-center items-center flex bg-[#000000]"
                >
                  {approvingApplication ? (
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading.gif"}
                    />
                  ) : (
                    <h4 className="font-[500] text-base text-[#FFF]">
                      Approve
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ApplicationDetails;
