import { height } from "@mui/system";
import React, { useEffect } from "react";

const CreditImprove = ({ cardTitle, pr, pl, bgColor, value }) => {
  const style = {
    height: height || "711px",
    paddingLeft: pl,
    paddingRight: pr,
    backgroundColor: "#020605",
    backgroundImage: 'url("/Assets/Spiral.svg")',
  };

  return (
    <div
      style={{ ...style }}
      className={"h-[121px] rounded-[9px] w-[256px] px-6 py-3 flex flex-col "}
    >
      <div className={`flex flex-col h-full justify-between`}>
        <h5 className="text-[14px]  font-[500] text-[#fff] ">{cardTitle}</h5>
        <h3 className="text-[24px]  font-[500] text-[#fff] ">{value}</h3>
      </div>
    </div>
  );
};

export default CreditImprove;
