import { Menu, Popover } from "@mui/material";
import React, { useState } from "react";

const PayoutMenu = ({
  handlePayout,
  handleClose,
  anchorEl,
  ...props
}) => {

  return (
    <Menu {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "180px",
          paddingLeft: 5,
          paddingRight: 5,
          cursor: "pointer",
        }}
      >
      

        <span
          className="font-[500] hover:bg-gray-100   p-3 hover:rounded-lg w-full"
          onClick={handlePayout}
        >
          Pay User Manually
        </span>
      </div>
    </Menu>
  );
};

export default PayoutMenu;
