import React from "react";

import Button from "../inputs/Button";
import TextInput from "../inputs/TextInput";

const CloseAccount = ({ form }) => {
  return (
    <div className="w-[650px] lg:w-[800px] mt-10">
      <div className="w-full border-b-[1px] mb-6 pb-4 border-b-[#F0F0F0] ">
        <h3 className=" text-base font-[500] text-[#333333] ">Close Account</h3>
      </div>
      <div className="">
        <div
          className="get-funding__settings"
          style={{
            display: "block",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <h5 className="text-[14px] font-500 pb-[10px]">Close Account</h5>
          <p className="text-[12px] py-[10px]">
            Learn about your options, and close your account if you wish.{" "}
          </p>
          <button
            // onClick={}
            className="text-[12px] font-400"
            style={{ color: "#CA5040" }}
          >
            Close account{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CloseAccount;
