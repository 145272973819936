import React from "react";

import { useNavigate } from "react-router-dom";

import { IoChevronBackOutline } from "react-icons/io5";
const BackArrow2 = ({ mt, ml, mb }) => {
  const navigate = useNavigate();
  const marginStyle = {
    marginTop: mt,
    marginLeft: ml,
    marginBottom: mb,
  };

  const goBack = () => navigate(-1);

  return (
    <div className={`flex mx-[29px] `} style={marginStyle} onClick={goBack}>
      <div className="flex items-center gap-1 cursor-pointer">
        <IoChevronBackOutline size={"20px"} />
        <h4 className="font-Source-Sans-Pro text-[18px] font-[500]">Back</h4>
      </div>
    </div>
  );
};

export default BackArrow2;
