import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_FORGOT_PASSWORD } from "../../../config/api";
import { toast } from "react-toastify";

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (payload) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_FORGOT_PASSWORD,
        data: payload.data,
      });

      // If the response status is a success status, show a success toast
      if (payload?.callBack) {
        payload.callBack();
      } else {
        payload.navigate("/verification", {
          state: { email: payload.data.email },
        });
      }

      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);
