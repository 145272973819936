export const countryOptions = [
  { value: "", label: "Choose country", icon: "" },
  { value: "NG", label: "Nigeria", icon: "/Assets/NG.png" },
  { value: "US", label: "USA", icon: "/Assets/US.png" },
];
export const subscriptionsOptions = [
  { value: "", label: "Select Subscription" },
  { value: "gold", label: "Gold" },
  { value: "platinum", label: "platinum" },
];

export const stateOptions = [
  { value: "", label: "Choose state", icon: "" },
  { value: "AB", label: "Abia" },
  { value: "AD", label: "Adamawa" },
  { value: "AK", label: "Akwa Ibom" },
  { value: "AN", label: "Anambra" },
  { value: "BA", label: "Bauchi" },
  { value: "BE", label: "Benue" },
  { value: "BO", label: "Borno" },
  { value: "CR", label: "Cross River" },
  { value: "DE", label: "Delta" },
  { value: "EB", label: "Ebonyi" },
  { value: "ED", label: "Edo" },
  { value: "EK", label: "Ekiti" },
  { value: "EN", label: "Enugu" },
  { value: "FC", label: "Federal Capital Territory" },
  { value: "GO", label: "Gombe" },
  { value: "IM", label: "Imo" },
  { value: "JI", label: "Jigawa" },
  { value: "KD", label: "Kaduna" },
  { value: "KN", label: "Kano" },
  { value: "KT", label: "Katsina" },
  { value: "KE", label: "Kebbi" },
  { value: "KO", label: "Kogi" },
  { value: "KW", label: "Kwara" },
  { value: "LA", label: "Lagos" },
  { value: "NA", label: "Nasarawa" },
  { value: "NI", label: "Niger" },
  { value: "OG", label: "Ogun" },
  { value: "ON", label: "Ondo" },
  { value: "OS", label: "Osun" },
  { value: "OY", label: "Oyo" },
  { value: "PL", label: "Plateau" },
  { value: "RI", label: "Rivers" },
  { value: "SO", label: "Sokoto" },
  { value: "TA", label: "Taraba" },
  { value: "YO", label: "Yobe" },
  { value: "ZA", label: "Zamfara" },
];

export const formatArray = (array, objKey) => {
  return array?.map((item) => {
    return { value: item, label: item };
  });
};

export const _countries = formatArray([
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua &amp; Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia &amp; Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
]);

export const banks = formatArray([
  "Guaranty Trust Bank Plc",
  "First Bank",
  "Access Bank Plc",
  "Fidelity Bank Plc",
  "First City Monument Bank Limited",
  "Union Bank of Nigeria Plc",
  "United Bank for Africa Plc",
  "Zenith Bank Plc",
  "Citibank Nigeria Limited",
  "Ecobank Nigeria Plc",
  "Heritage Bank Plc",
  "Keystone Bank Limited",
  "Optimus Bank Limited",
  "Polaris Bank Limited",
  "Stanbic IBTC Bank Plc",
  "Standard Chartered",
  "Sterling Bank Plc",
  "Titan Trust bank",
  "Unity Bank Plc",
  "Wema Bank Plc",
]);

export const idTypesOptions = [
  { value: "national_identity_card", label: "National Identity Card" },
  { value: "international_passport", label: "International Passport" },
  { value: "driver_license", label: "Driver's License" },
  { value: "permanent_voter_card", label: "Permanent Voter's Card (PVC)" },
  {
    value: "national_identification_number",
    label: "National Identification Number (NIN)",
  },
];
export const currencyOptions = [
  { value: "", label: "Select Currency", icon: "" },
  { value: "NGR", label: "Nigerian Naira (₦)" },
  { value: "USD", label: "United State Dollars ($)" },
];
export const investmentCategoryOptions = [
  { value: 1, label: "SME Financing" },
  { value: 2, label: "Real Estate" },
  { value: 3, label: "Startup Investing" },
];

export const investmentType = [
  { value: "", label: "Select Investment Type", icon: "" },
  { value: "old-investment", label: "Old Investment" },
  { value: "new-investment", label: "New Investment" },
];

const invcat = [
  {
    id: 1,
    name: "SME Financing",
    slug: "SME financing",
    description: "SME financing",
    created_at: "2021-10-04T05:14:13.000000Z",
    updated_at: "2021-10-04T05:14:13.000000Z",
  },
  {
    id: 2,
    name: "Real Estate",
    slug: "Real Estate",
    description: "Real Estate",
    created_at: "2021-10-04T05:14:49.000000Z",
    updated_at: "2021-10-04T05:14:49.000000Z",
  },
  {
    id: 3,
    name: "Startup Investing",
    slug: "Startup Investing",
    description: "Startup Investing",
    created_at: "2021-10-09T11:23:14.000000Z",
    updated_at: "2021-10-09T11:23:14.000000Z",
  },
];
export const investmentSectorOptions = [
  //{ value: "", label: "Select Sector", icon: "" },
  { value: "commodity_export", label: "Commodity Export" },
  { value: "health_care", label: "Health Care" },
  { value: "education", label: "Education" },
  { value: "mobile_gadgets", label: "Mobile Gadgets" },
  { value: "constructions", label: "Constructions" },
  { value: "food_beverages", label: "Food and Beverage" },
  { value: "transportation", label: "Transportation" },
  { value: "import", label: "Import" },
  { value: "electronics", label: "Electronics" },
  { value: "media", label: "Media" },
  { value: "fashion_textile", label: "Fashion and Textile" },
  { value: "hospitality", label: "Hospitality" },
  { value: "beauty", label: "Beauty" },
  { value: "finance", label: "Finance" },
  { value: "supply_and_logistics", label: "Supply and Logistics" },
];
export const investmentSectorOptions2 = [
  //{ value: "", label: "Select Sector", icon: "" },
  { value: "commodity", label: "Commodity" },
  { value: "health_care", label: "Health Care" },
  { value: "education", label: "Education" },
  { value: "mobile_gadgets", label: "Mobile Gadgets" },
  { value: "constructions", label: "Constructions" },
  { value: "food_beverages", label: "Food and Beverage" },
  { value: "transportation", label: "Transportation" },
  { value: "import", label: "Import" },
  { value: "electronics", label: "Electronics" },
  { value: "media", label: "Media" },
  { value: "fashion_textile", label: "Fashion and Textile" },
  { value: "hospitality", label: "Hospitality" },
  { value: "beauty", label: "Beauty" },
  { value: "finance", label: "Finance" },
  { value: "supply_and_logistics", label: "Supply and Logistics" },
];
export const paymentFrequencyOptions = [
  { value: "", label: "Select Frequency", icon: "" },
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "maturity", label: "At Maturity" },
];

export const campaignFrequencyOptions = [
  { value: "", label: "Select", icon: "" },
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
];

export const riskOptions = [
  { value: "", label: "Select" },
  { value: "High", label: "High" },
  { value: "Moderate", label: "Moderate" },
  { value: "Low", label: "Low" },
];

export const publicCategoryOptions = [
  { value: "members", label: "Members only" },
  { value: "non_members", label: "Non-members only" },
  { value: "everyone", label: "Everyone" },
  { value: "internal", label: "Internal" },
];
export const annualDividendOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const subscriptionOptions = [
  { value: "Platinum", label: "Membership Subscription" },
];

export const payoutOption = [
  { value: "all", label: "All Users" },
  { value: "selected_users", label: "Selected Users" },
];

export const paymentType = [
  { value: "", label: "Select ", icon: "" },
  { value: "PMCE", label: "Profit Monthly, Capital at the end" },
  { value: "PACM", label: "Profit and Capital Monthly" },
  { value: "PACE", label: "Profit and Capital at the end" },
];

export const zipcodeOptions = [
  { value: "", label: "Select code", icon: "" },
  { value: "12345", label: "12345" },
  { value: "67890", label: "67890" },
  { value: "54321", label: "54321" },
];

export const upcomingDetails = [
  {
    id: 1,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 2,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 3,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 5,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 5,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 6,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 7,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 8,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 9,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 10,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 11,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
  {
    id: 12,
    company: "Pernacle Limited",
    dueDate: "06/2024",
    price: 244.04,
  },
];

export const genderOptions = [
  { value: "", label: "Choose Gender", icon: "" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];
export const relationshipOptions = [
  { value: "", label: "Choose relation status", icon: "" },
  { value: "single", label: "Single" },
  { value: "engaged", label: "Enageged" },
  { value: "married", label: "Married" },
];

export const phoneOptions = [
  { value: "NG", label: "+234", icon: "/Assets/NG.png" },
];

export const investmentOptions = [
  { value: "investment_statement", label: "Investment Statement" },
];

export const userFilterOptions = [
  {
    name: "All users",
    id: 1,
  },
  {
    name: "Members",
    id: 2,
  },
  {
    name: "Non members",
    id: 3,
  },
  {
    name: "Incomplete profiles",
    id: 4,
  },
];
export const investmentFilterOptions = [
  {
    name: "All Investments",
    id: 1,
  },
  {
    name: "Active Investments",
    id: 2,
  },
  {
    name: "Closed Investments",
    id: 3,
  },
];
