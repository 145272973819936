import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_MOVE_INVESTMENT_DOWN } from "../../../config/api";
import { getAllProductInvestment } from "./getInvestments";


export const moveInvestmentdown = createAsyncThunk(
  "investment/moveDown",
  async (payload, {dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_MOVE_INVESTMENT_DOWN + payload.data?.id,
        data: payload.data,
      });
      toast.success("Product Moved down successfully");
      dispatch(getAllProductInvestment())
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
