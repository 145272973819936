import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_FETCH_USER_SUBSCRIPTIONS } from "../../../config/api";

export const fetchSubscriptionHistory = createAsyncThunk(
  "subscription/getAll",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_FETCH_USER_SUBSCRIPTIONS}${id}`,
      });

      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(msg);
    }
  }
);
