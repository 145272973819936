import React from "react";
import WithStatusAndButton from "../cards/WithStatusAndButton";

const Identification = ({ form }) => {
  const identifications = [
    {
      id: 1,
      Status: "Verified",
      Btn1Title: "View",
      CardTitle: "BVN Number",
      Info: "Verify your BVN to unlock so many other possibilities of the Halcredit ",
      path: "",
    },
    {
      id: 2,
      Status: "Verified",
      Btn1Title: "View",
      CardTitle: "ID Verification",
      Info: "Verify your ID to unlock so many other possibilities of the Halcredit ",
      path: "",
    },
  ];

  return (
    <div className="w-[650px] lg:w-[800px] mt-10">
      <div className="w-full border-b-[1px] mb-6 pb-4 border-b-[#F0F0F0] ">
        <h3 className=" text-base font-[500] text-[#333333] ">
          Identifications
        </h3>
      </div>
      <div className=" px-2 pt-4 pb-3 flex-1 overflow-auto gap-5 flex">
        {identifications.map(
          ({ id, Status, CardTitle, Btn1Title, Info, path }) => (
            <WithStatusAndButton
              key={id}
              status={Status}
              disabled={true}
              cardTitle={CardTitle}
              btnTitle={Btn1Title}
              info={Info}
              route={path}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Identification;
