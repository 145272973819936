import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const InvestmentCard = ({
  icon,
  title,
  investment,
  iconColor,
  width,
  height,
}) => {
  const iconStyle = {
    width: "40px",
    height: "40px",
    backgroundColor: iconColor,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    marginBottom: "40px",
  };

  return (
    <Card
      sx={{
        width: width,
        height: height,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        border: "0.8px solid #D0D5DD",
        borderRadius: "15px",
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <div style={iconStyle}>{icon}</div>
              <Typography
                variant="h5"
                component="div"
                fontSize={14}
                sx={{ color: "#475467", marginBottom: "5px" }}
              >
                {title}
              </Typography>
            </Box>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: 1.5 }}
                color="text.secondary"
                fontWeight={800}
                fontSize={20}
              >
                {investment}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InvestmentCard;
