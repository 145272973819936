import { Menu, Popover } from "@mui/material";
import React from "react";

const WithdrawalMenu = ({
  handleAdminUserClick,
  handleApproveWithdrawal,
  handleCancelWithdrawal,
  handleClose,
  handleDeleteUserClick,
  handleFundWalletClick,
  handleSubscriptionUserClick,
  anchorEl,
  selectedRow,
  ...props
}) => {


  return (
    <Menu {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "180px",
          padding: 5,
     
          cursor: "pointer",
        }}
      >

   
          
            <span
              className=" font-[500] hover:bg-gray-100   p-3  hover:rounded-lg w-full"
              onClick={handleApproveWithdrawal}
            >
              Approve Request
            </span>
            <span
              className=" font-[500] hover:bg-gray-100   p-3  hover:rounded-lg w-full"
              onClick={handleCancelWithdrawal}
            >
              Cancel Request
            </span>
          





      </div>
    </Menu>
  );
};

export default WithdrawalMenu;
