import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_GET_SETTLEMENT_BY_ID } from "../../../config/api";

export const getSettlementById = createAsyncThunk(
  "settlement/getOne",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: API_GET_SETTLEMENT_BY_ID + id,
      });

      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
