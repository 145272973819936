import React from "react";

function WalletIcon({ stroke }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
      <path d="M3.15837 10.491C3.08489 11.887 3.16936 13.373 1.92213 14.3084C1.34164 14.7438 1 15.427 1 16.1527C1 17.1508 1.7818 18 2.8 18H17.2C18.2182 18 19 17.1508 19 16.1527C19 15.427 18.6584 14.7438 18.0779 14.3084C16.8306 13.373 16.9151 11.887 16.8416 10.491C16.6501 6.85223 13.6438 4 10 4C6.35617 4 3.34988 6.85222 3.15837 10.491Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.5 2.125C8.5 2.95343 9.17157 4 10 4C10.8284 4 11.5 2.95343 11.5 2.125C11.5 1.29657 10.8284 1 10 1C9.17157 1 8.5 1.29657 8.5 2.125Z" stroke={stroke} stroke-width="1.5" />
      <path d="M13 18C13 19.6569 11.6569 21 10 21C8.34315 21 7 19.6569 7 18" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default WalletIcon;


export function Account({ stroke }) {
  return (
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 16L6 12" stroke={stroke} stroke-width="1.5" stroke-linecap="round"/>
<path d="M11 16L11 6" stroke={stroke} stroke-width="1.5" stroke-linecap="round"/>
<path d="M16 16L16 10" stroke={stroke} stroke-width="1.5" stroke-linecap="round"/>
<path d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z" stroke={stroke} stroke-width="1.5" stroke-linejoin="round"/>
</svg>


  );
}
export function Transactions({ stroke }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
      <path d="M1 5C1 3.59987 1 2.8998 1.27248 2.36502C1.51217 1.89462 1.89462 1.51217 2.36502 1.27248C2.8998 1 3.59987 1 5 1C6.40013 1 7.1002 1 7.63498 1.27248C8.10538 1.51217 8.48783 1.89462 8.72752 2.36502C9 2.8998 9 3.59987 9 5V15C9 16.4001 9 17.1002 8.72752 17.635C8.48783 18.1054 8.10538 18.4878 7.63498 18.7275C7.1002 19 6.40013 19 5 19C3.59987 19 2.8998 19 2.36502 18.7275C1.89462 18.4878 1.51217 18.1054 1.27248 17.635C1 17.1002 1 16.4001 1 15V5Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 15H5.00898" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1 5H9" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.4486 6.26843C10.0937 4.93838 9.91628 4.27336 10.0385 3.69599C10.146 3.18812 10.4108 2.72747 10.7951 2.38005C11.2319 1.98508 11.8942 1.80689 13.2187 1.4505C14.5432 1.09412 15.2055 0.915926 15.7804 1.03865C16.2862 1.1466 16.7449 1.41256 17.0909 1.79841C17.4842 2.23706 17.6617 2.90209 18.0166 4.23213L20.5514 13.7316C20.9063 15.0616 21.0837 15.7266 20.9615 16.304C20.854 16.8119 20.5892 17.2725 20.2049 17.62C19.7681 18.0149 19.1058 18.1931 17.7813 18.5495C16.4568 18.9059 15.7945 19.0841 15.2196 18.9614C14.7138 18.8534 14.2551 18.5874 13.9091 18.2016C13.5158 17.7629 13.3383 17.0979 12.9834 15.7679L10.4486 6.26843Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.7812 14.6953L16.7899 14.693" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 6.00019L17.5001 4" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

