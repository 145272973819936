import { MenuItem, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../inputs/SelectField";
import { paymentType } from "../../config/data";
import { startInvestment } from "../../store/actions/investment/startInvestment";
import SelectMember from "./SelectMember";
import { startInvestmentSchema } from "../../Helper/validationSchemas";
import HalDatePicker from "../inputs/HalDatePicker";
import CustomInput from "../inputs/CustomInput";
import dayjs from "dayjs";

function StartInvestmentModal({
  open,
  onClose,
  invType,
  payout_type,
  currency,
  amount,
  invID,
  name,
  setOpenStartModal,
}) {
  const [showMembers, setShowMembers] = useState(false);
  const loading = useSelector((state) => state?.investment?.pending);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const data = {
      id: invID,

      total_amount: form.values?.total_amount,
      payout_date: new Date(form.values?.payout_date)
        .toISOString()
        .substring(0, 10),
    };
    dispatch(
      startInvestment({ data, callback: () => setOpenStartModal(false) })
    );
  };
  useEffect(() => {
    if (currency) form.setFieldValue("currency", { value: currency });
    if (amount) form.setFieldValue("total_amount", amount);
    form.setFieldValue(
      "payout_date",
      dayjs(new Date().toISOString().substring(0, 10))
    );
  }, [currency, amount]);

  const form = useFormik({
    initialValues: {
      // payment_type: "",
      // payout_type: "",
      currency: { value: currency },
      total_amount: 0,
      payout_date: new Date(),
    },
    onSubmit: handleSubmit,
    validationSchema: startInvestmentSchema,
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 19,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full p-5" style={{ padding: "10px 20px" }}>
            <div>
              <h1 className="font-[600] text-2xl mb-4">
                <span className="font-[400] text-2xl ">Start</span> {name}
              </h1>
              <p className="text-sm mb-4 w-[432px]">
                Are you sure you want to start this investment
              </p>
              <div className=" w-full items-center mr-4">
                {/* <div className="mt-4">
                  <label>Payout Type</label>
                  <div className="mb-4">
                    <SelectField
                      id="payout_type"
                      options={paymentType}
                      form={form}
                      sx={{
                        width: "432px",
                        height: "48px",
                        borderRadius: "7px",
                        borderColor: "#D0D5DD",
                        color: "#101828",
                      }}
                      fw={400}
                      fz={"16px"}
                    />
                  </div>
                </div> */}
                <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
                  <p className="">Total Amount Invested</p>
                  <CustomInput
                    id="total_amount"
                    width="100%"
                    form={form}
                    type="currency"
                    placeholder={"Enter Amount"}
                    height="12px"
                    mt="10"
                    mb="2px"
                    mr="10px"
                    borderRadius={"10px"}
                    className="mobile-input-width"
                  />
                </div>

                <div className="flex flex-col mr-4 w-full">
                  {/* <label className="mb-2">Payout Date</label> */}

                  <div
                    style={{
                      // border: "1px solid #D0D5DD",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <HalDatePicker
                      defaultStyles={{
                        borderRadius: "4px",
                        width: "212px",
                        minWidth: "212px",
                      }}
                      label="Payout Date"
                      form={form}
                      id={"payout_date"}
                      rightImage
                    />
                  </div>
                </div>

                <div className="flex mt-6 w-full items-center">
                  <CustomButton
                    variant="outlined"
                    customColor="#fff"
                    children="Cancel"
                    onClick={onClose}
                    width="100%"
                    height="50px"
                    margin="0px 25px 0px 0px"
                    borderColor="#D0D5DD"
                    color="#159AA8"
                    iconWidth="22px"
                  />

                  <CustomButton
                    variant="contained"
                    customColor="#159AA8"
                    loading={loading}
                    children="Continue"
                    width="100%"
                    height="50px"
                    padding="10px"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <SelectMember
        open={showMembers}
        form={form}
        percentage={form?.values?.disburse_perc}
        onClose={() => setShowMembers(false)}
      />
    </>
  );
}

export default StartInvestmentModal;
