import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./accordion.css";

function CustomAccordion({ title, content, image }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  const description = { __html: `${content}` };

  return (
    <Accordion expanded={isAccordionOpen} onChange={handleAccordionToggle}>
      <AccordionSummary
        className="font-bold mt-10 p-5"
        style={{ backgroundColor: "#F9FAFB", marginBottom: "20px" }}
        expandIcon={<ExpandMoreIcon />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {image ? (
          <img src={image} alt="Image" style={{ width: "50%" }} />
        ) : (
          <div
            style={{
              lineHeight: "1.8",
              listStyle: "disc",
              paddingLeft: "20px",
            }}
            className="accord"
            dangerouslySetInnerHTML={description}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default CustomAccordion;
