import * as Yup from "yup";
const passwordRules = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const bankDetailsSchema = Yup.object().shape({
  accountName: Yup.string().required("This field is required"),
  accountNumber: Yup.string()
    .length(10, "This field must have exactly 10 characters")
    .required("This field is required"),
  bankName: Yup.object().required("This field is required"),
  BVN: Yup.number()
    .typeError("Only numeric values are allowed")
    .required("This field is required"),
});

export const paymentSchema = Yup.object().shape({
  expiryDate: Yup.string().required("Required"),
  //  .length(5, "Invalid expiry date."),
  cvv: Yup.string().required("Required").length(3, "Invalid pin"),
  cardNumber: Yup.string().required("Required"),
  // .length(19, "This field requires a fourteen-digit number."),
});

export const signUpValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(passwordRules, {
      message:
        "An uppercase, a lowercase, a digit, and a special character are required.",
    }),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .when("newPassword", (newPassword, schema) => {
      if (newPassword) return schema.required("Confirm Password is required");
    })
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

export const bankSchema = Yup.object().shape({
  account_name: Yup.string().required("Account name is required"),
  account_number: Yup.string().required("Account number is required"),
  bank_name: Yup.string().required("Bank name is required"),
});

export const userDetailsSchema = Yup.object().shape({
  // first_name: Yup.string().required("First Name is required"),
  // last_name: Yup.string().required("Last Name is required"),
  address_line_1: Yup.string().required("Street Address is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  postalCode: Yup.string().required("Postal code is required"),
  gender: Yup.string().required("Gender is required"),
  phone: Yup.string().required("Phone Number is required"),
  birth_date: Yup.date().required("Date of Birth is required"),
  profession: Yup.string().required("Profession is required"),
  //linkedInProfile: Yup.string().url("Invalid LinkedIn Profile URL"),
  // referral: Yup.string().required("Referral Name is required"),
  idType: Yup.string().required("ID Type is required"),
});

export const startInvestmentSchema = Yup.object().shape({
  payment_type: Yup.object().required("Required"),
});

export const makeMemberSchema = Yup.object().shape({
  subscription_type: Yup.object().required("Required"),
  amount: Yup.string().required("Required"),
});

export const fundWalletSchema = Yup.object().shape({
  amount: Yup.string().required("Required"),
  currency: Yup.object().required("Required"),
});

export const payoutSchema = Yup.object().shape({
  paymentSchedules: Yup.object().required("Please select a payment schedule"),
  beneficiary: Yup.object().required("Please select beneficiary"),
});
export const manualPayoutSchema = Yup.object().shape({
  paymentSchedules: Yup.object().required("Please select a payment schedule"),
});
export const dividendSchema = Yup.object().shape({
  type: Yup.object().required("Please select a payout type"),
  percentage: Yup.number().required("Please enter percentage"),
});

export const createInvestmentSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  // duration: Yup.string().required("Required"),
  // roi: Yup.string().required("Required"),
  payout_type: Yup.object().required("Required"),
  minimum_amount: Yup.string().required("Required"),
  maximum_amount: Yup.string().required("Required"),
  // description: Yup.string().required("Required"),
  // start_date: Yup.string().required("Required"),
  // end_date: Yup.string().required("Required"),
  // payment_frequency: Yup.object().required("Required"),
  // investment_category_id: Yup.string().required("Required"),
});
