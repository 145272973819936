import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getApplication } from "../../store/actions/applications/getApplication";
import BackArrow from "../../components/BackArrow";
import CreditImprove from "../../components/cards/CreditImprove";
import { approveApplication } from "../../store/actions/applications/approveApplication";
import { rejectApplication } from "../../store/actions/applications/rejectApplication";
import useCurrency from "../../hooks/useCurrency";

const ApplicationSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [_, getAmount] = useCurrency();

  const {
    application,
    applicationPending,
    rejectingApplication,
    approvingApplication,
  } = useSelector((state) => state?.transactions);

  const { id } = useParams();
  useEffect(() => {
    dispatch(getApplication(id));
  }, [id]);

  const identifications = [
    {
      id: 1,

      cardTitle: "Applicant Name:",
      value: application?.applicant,
    },
    {
      id: 2,
      cardTitle: "Company name:",
      value: application?.businessName,
    },
    {
      id: 3,
      cardTitle: "Estimated Profit:",
      value: `${application?.profitPercentage}%`,
    },
    {
      id: 4,
      cardTitle: "Total Invoice Amount:",
      value: `N${getAmount(application?.fullAmount)}`,
    },
    {
      id: 5,
      cardTitle: "Duration of Sales:",
      value: application?.salesDuration
        ? `${application?.salesDuration} Month${
            Number(application?.salesDuration) === 1 ? "" : "s"
          }`
        : "",
    },
    {
      id: 6,
      cardTitle: "Duration of Sourcing:",
      value: `${application?.repaymentDuration} Month${
        Number(application?.repaymentDuration) === 1 ? "" : "s"
      }`,
    },
  ];

  const handleReject = (invoiceID) => {
    const body = {
      status: "rejected",
    };
    dispatch(rejectApplication({ body, id: invoiceID }));
  };
  const handleApprove = (invoiceID) => {
    const body = {
      status: "approved",
      profitPercentage: application?.profitPercentage,
      repaymentDuration: application?.repaymentDuration,
      fullAmount: application?.fullAmount,
    };
    dispatch(approveApplication({ body, id: invoiceID }));
  };

  return (
    <DashboardLayout>
      <div className="flex w-full h-full items-center mb-8 px-4">
        {applicationPending ? (
          <div className="h-10 flex-1">
            <Loader />
          </div>
        ) : (
          <div className="w-full flex-1 flex flex-col h-[85vh] pl-2">
            <BackArrow mt={20} ml={0} />
            <h2 className="font-Poppins font-[500] text-[24px] mt-5 mb-4">
              Application Summary
            </h2>
            <div className="flex-1 flex flex-col justify-between ">
              <div className="  pt-4 pb-3 flex-wrap  overflow-auto gap-5 flex">
                {identifications.map(({ id, cardTitle, value }) => (
                  <CreditImprove
                    key={id}
                    cardTitle={cardTitle}
                    value={value}
                    pl={"11px"}
                  />
                ))}
              </div>

              <div className="flex justify-end items-center gap-3 border-t border-t-[#000] pt-4 pb-7 ">
                <div
                  onClick={
                    rejectingApplication
                      ? null
                      : () => handleReject(application?.invoiceID)
                  }
                  className="w-[96px] h-[54px] cursor-pointer justify-center rounded-[5px] items-center flex bg-[#FFEBEB]"
                >
                  {rejectingApplication ? (
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading.gif"}
                    />
                  ) : (
                    <h4 className="font-[500] text-base text-[#FF5555]">
                      Reject
                    </h4>
                  )}
                </div>

                <div
                  onClick={
                    approvingApplication
                      ? null
                      : () => handleApprove(application?.invoiceID)
                  }
                  className="w-[111px] cursor-pointer rounded-[5px] h-[54px] justify-center items-center flex bg-[#000000]"
                >
                  {approvingApplication ? (
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading.gif"}
                    />
                  ) : (
                    <h4 className="font-[500] text-base text-[#FFF]">
                      Approve
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ApplicationSummary;
