import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_GET_PRODUCT_INTERESTS } from "../../../config/api";

export const getInvestmentInterests = createAsyncThunk(
  "investmentProduct/getInterest",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_PRODUCT_INTERESTS,
      });

      return response?.data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
