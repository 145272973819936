import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_CREATE_DISBURSEMENT,
  API_GET_DISBURSEMENT,
  API_GET_DISBURSEMENT_LOG,
} from "../../../config/api";

export const createDisbursement = createAsyncThunk(
  "disburse/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CREATE_DISBURSEMENT,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);

export const getDisbursement = createAsyncThunk(
  "disburse/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_DISBURSEMENT,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const getDisbursementLog = createAsyncThunk(
  "disburseLog/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_DISBURSEMENT_LOG,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
