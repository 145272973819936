import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Client } from "../../../client";
import { API_GOOGLE_LOGIN } from "../../../config/api";
import { Cookies } from "react-cookie";

export const loginWithGoogle = createAsyncThunk(
  "users/googleLogin",
  async ({ code }, { rejectWithValue }) => {
    try {
      const cookies = new Cookies();
      const tokenResponse = await fetch(
        process.env.REACT_APP_GOOGLE_EXCHANGE_TOKEN,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
            grant_type: "authorization_code",
            code: code,
            redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
          }),
        }
      );

      const tokenData = await tokenResponse.json();

      if (!tokenResponse.ok) {
        throw new Error(
          tokenData.error || "Failed to exchange the authorization code."
        );
      }

      const response = await Client({
        method: "POST",
        path: API_GOOGLE_LOGIN,
        queryParams: { accessToken: tokenData.access_token },
      });

      const { accessToken, user } = response.data;

      const date = new Date();
      const nextDate = date.setMinutes(date.getMinutes() + 20);

      cookies.set("accessToken", accessToken, {
        expires: new Date(nextDate),
      });
      toast.success("Login successful!");

      return user;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Login with Google failed";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
