import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import UserCard from "../../components/cards/UserCard";
import UserIcon from "../../components/Icons/UserIcon";
import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import AdminUserIcon from "../../components/Icons/AdminUserIcon";
import UserTable from "../../components/dashboard/user/UserTable";
import { getAllUser } from "../../store/actions/userManagement/management";
import { Link } from "react-router-dom";
import CustomButton from "../../components/Buttons/GroupButtons";
import HalcreditUserTable from "../../components/dashboard/user/HalcreditUserTable";
import { getUserAnalytcs } from "../../store/actions/userManagement/getUserAnalytcs";
import { setUserGroup } from "../../store/reducers/dashboardSlice";

function UserManagement() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.portfolio.pending) || false;
  const { allUsers, pending, getUserAnalytcsData, allUsersPending } =
    useSelector((state) => state?.user) || [];

  const { userGroupFilter } = useSelector((state) => state?.dashboard);

  // const [userGroup, setUserGroup] = useState("halvest");

  const UserDetails = [
    {
      id: 1,
      icon: <AdminUserIcon />,
      title: "Total Users",
      cardValue: getUserAnalytcsData?.total_user,
    },
    {
      id: 2,
      icon: <UserIcon />,
      title: "Members",
      cardValue: getUserAnalytcsData?.users_with_premium_plan,
    },
    {
      id: 3,
      icon: <UserIcon />,
      title: "Non members",
      cardValue: getUserAnalytcsData?.users_with_free_plan,
    },
    {
      id: 3,
      icon: <UserIcon />,
      title: "Incomplete profiles",
      cardValue: getUserAnalytcsData?.users_with_incomplete_profile,
    },
  ];

  useEffect(() => {
    dispatch(getAllUser());
    dispatch(getUserAnalytcs());
  }, []);
  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <div className="flex items-center mb-6 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <div className=" font-semibold text-[#475467] text-sm sm:text-base bg-[#F9FAFB] p-1 px-3 rounded-md  ">
            User Management
          </div>
        </div>

        {/* <h1 className=" mb-8 font-[500] text-xl md:text-2xl lg:text-3xl">
          User Management
        </h1> */}
        <div className="flex flex-wrap gap-y-2 mb-6">
          <CustomButton
            variant="outlined"
            customColor={userGroupFilter === "halvest" ? "#159AA8" : "#fff"}
            children="HNI"
            width="250px"
            height="40px"
            padding="10px"
            className={`!border  lg:border-none lg:!rounded-tr-none lg:!rounded-br-none !rounded-lg`}
            borderColor={"#D0D5DD"}
            onClick={() => dispatch(setUserGroup("halvest"))}
            color={userGroupFilter === "halvest" ? "#fff" : "#000"}
          />
          <CustomButton
            variant="outlined"
            children="Business Clients"
            width="250px"
            height="40px"
            padding="10px"
            className={`!border  lg:!border-l-0 lg:border-none  lg:!rounded-none `}
            color={userGroupFilter === "business" ? "#fff" : "#000"}
            borderColor={"#D0D5DD"}
            customColor={userGroupFilter === "business" ? "#159AA8" : "#fff"}
            onClick={() => dispatch(setUserGroup("business"))}
          />
          <CustomButton
            variant="outlined"
            customColor={userGroupFilter === "halcredit" ? "#159AA8" : "#fff"}
            children="Teal"
            width="250px"
            height="40px"
            padding="10px"
            className={
              "!border  lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-bl-none !rounded-lg"
            }
            onClick={() => dispatch(setUserGroup("halcredit"))}
            color={userGroupFilter === "halcredit" ? "#fff" : "#000"}
            borderColor={"#D0D5DD"}
          />
        </div>
        {(loading || pending) && <Loader />}
        {userGroupFilter === "halvest" ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {UserDetails?.map((detail, index) => (
                <div key={index} className="mb-4">
                  <UserCard
                    loading={
                      allUsersPending && (!allUsers || allUsers?.length < 1)
                    }
                    currency="NG"
                    currencyCode="NGN"
                    width={"100%"}
                    height={150}
                    icon={detail.icon}
                    title={detail.title}
                    cardValue={detail.cardValue}
                  />
                </div>
              ))}
            </div>
            <div className="mt-6">
              <UserTable />
            </div>
          </>
        ) : (
          <div className="mt-6">
            <HalcreditUserTable />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default UserManagement;
