import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../inputs/SelectField";
import { payoutOption } from "../../config/data";
import { submitPayout } from "../../store/actions/disbursement/submitPayout";
import SelectMember from "./SelectMember";
import { dividendSchema, payoutSchema } from "../../Helper/validationSchemas";
import CustomInput from "../inputs/CustomInput";
import { useParams } from "react-router-dom";

function DividendModal({
  open,
  onClose,
  setButtonText,
  selectedRow,
  setOpenModal,
}) {
  const { id: investmentId } = useParams();
  const [showMembers, setShowMembers] = useState(false);
  const loading = useSelector((state) => state?.disbursement?.pending);
  const investment = useSelector((state) => state?.investment?.investment);
  const expected_dates = investment?.paymentSchedules?.expected_date || [];
  const paymentSchedules = expected_dates
    ?.map((item, index) => ({
      ...item,
      roi: Number(investment?.roi).toFixed(0) + "%",
      installment: "Payout " + (+index + 1),
      value: item?.id,
      label:
        "Payout " +
        (+index + 1) +
        " (" +
        new Date(item?.date).toDateString() +
        ")",
    }))
    .filter((item) => item.status !== "Paid");

  const dispatch = useDispatch();
  const payoutType = [{ value: "dividend", label: "Dividend" }];

  const handleSubmit = (values) => {
    let beneficiaries = [];
    const data = {
      payment_schedule_id: +investmentId,
      percentage: +values.percentage,
      type: values?.type?.value,
    };

    // if (form.values?.beneficiary?.value === "all") {

    if (selectedRow?.id) {
      dispatch(
        submitPayout({
          ...data,
          beneficiaries: [selectedRow?.id],
          callback: () => setOpenModal(false),
        })
      );
    } else {
      beneficiaries = investment?.investments?.map((item) => item?.user_id);
      dispatch(
        submitPayout({
          ...data,
          beneficiaries,
          callback: () => setOpenModal(false),
        })
      );
    }
  };

  const form = useFormik({
    initialValues: {
      beneficiary: "",
      percentage: "",
      investment_product_id: investmentId,
      type: "dividend",
    },
    onSubmit: handleSubmit,
    validationSchema: dividendSchema,
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 19,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full p-5">
            {/* <img
              src="/Assets/approveIcon.png"
              alt=""
              className="w-12 h-12 mb-5 "
            /> */}
            <div>
              {selectedRow?.id ? (
                <h1 className="font-[500] text-2xl mb-4">{`Make manual payout to ${selectedRow?.firstName}`}</h1>
              ) : (
                <h1 className="font-[500] text-2xl mb-4">Payout</h1>
              )}
              {/* <p className="text-sm mb-4 w-[432px]">
                Are you sure you want to make this payout
              </p> */}
              <div className=" w-full items-center mr-4">
                <div className="mt-4">
                  <label>Payout Type</label>
                  <div className="mb-4">
                    <SelectField
                      id="type"
                      options={payoutType}
                      form={form}
                      sx={{
                        width: "432px",
                        height: "48px",
                        borderRadius: "7px",
                        borderColor: "#D0D5DD",
                        color: "#101828",
                      }}
                      fw={400}
                      fz={"16px"}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label>Percentage</label>
                  <CustomInput
                    width="432px"
                    height="16px"
                    id="percentage"
                    type={"number"}
                    form={form}
                    placeholder={"Enter percentage"}
                    borderColor="#D0D5DD"
                    borderRadius={"5px"}
                    pr="14px"
                  />
                </div>
                {/* <div className="mt-6">
                  <label>Payout To</label>
                  <div className="mb-4">
                    <SelectField
                      id="beneficiary"
                      options={payoutOption}
                      form={form}
                      sx={{
                        width: "432px",
                        height: "48px",
                        borderRadius: "7px",
                        borderColor: "#D0D5DD",
                        color: "#101828",
                      }}
                      fw={400}
                      fz={"16px"}
                    />
                  </div>
                </div> */}

                <div className="flex mt-10 w-full items-center">
                  <CustomButton
                    variant="outlined"
                    customColor="#fff"
                    children="Cancel"
                    width="100%"
                    onClick={onClose}
                    height="50px"
                    borderColor="#D0D5DD"
                    margin="0px 25px 0px 0px"
                    color="#159AA8"
                    iconWidth="22px"
                  />

                  <CustomButton
                    variant="contained"
                    customColor="#159AA8"
                    loading={loading}
                    children="Continue"
                    width="100%"
                    height="50px"
                    padding="10px"
                    onClick={form.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <SelectMember
        open={showMembers}
        form={form}
        percentage={form?.values?.disburse_perc}
        onClose={() => setShowMembers(false)}
      />
    </>
  );
}

export default DividendModal;
