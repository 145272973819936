import React from "react";
import * as XLSX from 'xlsx';

const ExcelImportComponent = ({ onExcelDataChange }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        onExcelDataChange(data); 
      } catch (error) {
        console.error('Error reading the Excel file:', error);
      }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div className="w-[200px] bg-[#159aa8] text-white relative text-center rounded-lg">
      <p className="absolute w-full text-center h-full top-2">Import Data</p>
      <input type="file" onChange={handleFileUpload} accept=".xlsx, .xls" 
            className="import-file !appearance-none border border-gray-300 py-2 px-4 leading-tight focus:outline-none focus:border-blue-500 opacity-0"
 />
    </div>
  );
};

export default ExcelImportComponent;
