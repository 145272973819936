import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSettlementById } from "../../../store/actions/settlement/getSettlementbyId";
import useCurrency from "../../../hooks/useCurrency";
import { getCurrencySymbol } from "../../../Helper/getCurrencySymbol";
import GridTable from "../../table/gridTable";
import { Box } from "@mui/system";
import Loader from "../../SharedLayouts/Loader";

const SettlementTable = () => {
  const [_currency, getAmount] = useCurrency();

  const loading = useSelector((state) => state?.settlement?.loading);
  const { settlement, settlementDetails } = useSelector(
    (state) => state?.settlement
  );
  useEffect(() => {
    dispatch(getSettlementById(id));
  }, []);
  const handleRowClick = (params, event) => {
    // const isIconClick = event.target.getAttribute("data-is-icon") === "true";
    // setSelectedUserId(params?.row?.id);
    // if (!isIconClick) {
    //   setSelectedUserId(params?.row?.id);
    //   navigate(`/account-summary/${params?.row?.id}`);
    // }
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  const columns = [
    {
      field: "user_id",
      headerName: "User ID",
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) =>
        `${getCurrencySymbol(params.row?.currency)} ${getAmount(
          params.row?.transaction_amount
        )}`,
    },

    {
      field: "session_id",
      headerName: "Session ID",
      width: 300,
    },
    {
      field: "source_account_name",
      headerName: "Account  Name",
      width: 150,
    },
    {
      field: "account_number",
      headerName: "Account Number",
      width: 150,
    },
    {
      field: "tran_remarks",
      headerName: "Remarks",
      width: 300,
    },
  ];
  return (
    <div>
      <div className="bg-white">
        {!loading ? (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              rows={settlement}
              pageInfo={settlementDetails}
              columns={columns}
              getRowId={(row) => row.id}
              handleRowClick={handleRowClick}
            />
          </Box>
        ) : (
          <div className="h-10">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettlementTable;
