import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SelectField = ({
  label,
  form,
  id,
  options,
  onChange,
  defaultValue,
  sx,
  fw,
  fz,
  className,
}) => {
  const handleSelectionChange = (event) => {
    form.setFieldValue(id, event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl fullWidth sx={{ ...sx }}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        className={className}
        labelId={id}
        id={id}
        label={label}
        value={
          form?.values[id] || {
            value: defaultValue?.id,
            label: defaultValue?.name,
          }
        }
        onChange={handleSelectionChange}
        // error={form?.errors[id] ? true : false}
        sx={sx}
        renderValue={(selected) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: fz,
              fontWeight: fw,
            }}
          >
            {selected.icon && (
              <img src={selected.icon} alt="" className="w-4 h-4 mr-2" />
            )}
            {selected.label}
          </div>
        )}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.value}
            value={option}
            classes={{ selected: "selected-option" }}
          >
            {option.icon && (
              <img src={option.icon} alt="" className="w-4 h-4 mr-2" />
            )}
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {form?.errors[id] && (
        <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ">
          {form?.errors[id]}
        </div>
      )}
    </FormControl>
  );
};

export default SelectField;
