import { createSlice } from "@reduxjs/toolkit";

import {
  getAllUser,
  getUserDetail,
  getUsersPlan,
  restoreAllUser,
  restoreUser,
  retrieveAllTrashedUser,
  retrieveAllUserWithTrashed,
  updateUserInfo,
  userEmailUpdate,
  userRoleAssign,
  userSubscriptionPackage,
} from "../actions/userManagement/management";
import { makeAdmin } from "../actions/userManagement/makeAdmin";
import { approveFreePlan } from "../actions/userManagement/approveUser";
import { changeSubscription } from "../actions/userManagement/manageSubscription";
import { fetchTransactions } from "../actions/userManagement/getTransactionHistory";
import { fetchSubscriptionHistory } from "../actions/userManagement/getSubscriptionHistory";
import { approveDocument } from "../actions/profile/approveDocument";
import { deleteUser } from "../actions/userManagement/deleteUser";
import { fundWalletConfirmation } from "../actions/userManagement/fundWalletConfirmation";
import { fundWalletOTP } from "../actions/userManagement/fundWalletOTP";
import { resetTransactionPin } from "../actions/userManagement/resetPin";
import { setMasterPassword } from "../actions/userManagement/setMasterPassword";
import { removeMasterPassword } from "../actions/userManagement/removeMasterPassword";
import {
  deleteTemplate,
  getAllTemplate,
  saveTemplate,
  sendEmail,
} from "../actions/userManagement/sendEmail";
import { getAllTemplates } from "../actions/userManagement/getAllTemplates";
import { getUserAnalytcs } from "../actions/userManagement/getUserAnalytcs";
import { getWaitList } from "../actions/userManagement/getWaitList";
import { getBusinessUserById } from "../actions/userManagement/getBusinessUserById";
import { getBusinessUsers } from "../actions/userManagement/getBusinessUsers";
import { toggleInternal } from "../actions/roles/toggleInternal";
import { getBIPUsers } from "../actions/userManagement/getBIPUsers";

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    error: null,
    allUsers: [],
    businessUsers: [],
    getUserAnalytcsLoading: false,
    getUserAnalytcsData: null,
    user: null,
    businessUserDetails: null,
    getAllSubPackage: [],
    userTransactions: [],
    userTransactionsDetails: null,
    getUsersPlan: [],
    investmentByProduct: {},
    investmentProductCurrency: {},
    approveUserDetail: {},
    allUsersPending: false,
    getUserPending: false,
    userEmailPending: false,
    updateUserPending: false,
    deleteUserPending: false,
    userRolePending: false,
    userSubPackagePending: false,
    investmentProductCurrencyPending: false,
    investmentByInvestorPending: false,
    loading: false,
    pending: false,
    success: false,
    approveDocLoading: false,
    fetchSuccess: false,
    resetTransactionPinPending: false,
    setPending: false,
    removePending: false,
    saveTemplatePending: false,
    sendMailPending: false,
    getTemplatepending: false,
    templates: [],
    allUsersInfo: null,
    waitingUsers: [],
    BIPUsers: [],
    subscriptionHistory: [],
    subscriptionHistoryDetails: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getUserAnalytcs.pending, (state, action) => {
      state.getUserAnalytcsLoading = true;
    });
    builder.addCase(getUserAnalytcs.fulfilled, (state, action) => {
      state.getUserAnalytcsLoading = false;
      state.getUserAnalytcsData = action.payload;
    });
    builder.addCase(getUserAnalytcs.rejected, (state, action) => {
      state.getUserAnalytcsLoading = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getBusinessUsers.pending, (state, action) => {
      state.allUsersPending = true;
    });
    builder.addCase(getBusinessUsers.fulfilled, (state, action) => {
      state.allUsersPending = false;
      state.businessUsers = action.payload;
    });
    builder.addCase(getBusinessUsers.rejected, (state, action) => {
      state.allUsersPending = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getWaitList.pending, (state, action) => {
      state.allUsersPending = true;
    });
    builder.addCase(getWaitList.fulfilled, (state, action) => {
      state.allUsersPending = false;
      state.waitingUsers = action.payload;
    });
    builder.addCase(getWaitList.rejected, (state, action) => {
      state.allUsersPending = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getBIPUsers.pending, (state, action) => {
      state.allUsersPending = true;
    });
    builder.addCase(getBIPUsers.fulfilled, (state, action) => {
      state.allUsersPending = false;
      state.BIPUsers = action.payload;
    });
    builder.addCase(getBIPUsers.rejected, (state, action) => {
      state.allUsersPending = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getAllUser.pending, (state, action) => {
      state.allUsersPending = true;
    });
    builder.addCase(getAllUser.fulfilled, (state, action) => {
      state.allUsersPending = false;
      state.allUsersInfo = action.payload;
      state.allUsers = action.payload?.data;
    });
    builder.addCase(getAllUser.rejected, (state, action) => {
      state.allUsersPending = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getUserDetail.pending, (state, action) => {
      state.getUserPending = true;
      state.user = null;
    });
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      state.getUserPending = false;
      state.user = action.payload;
    });
    builder.addCase(getUserDetail.rejected, (state, action) => {
      state.getUserPending = false;

      state.error = action?.error?.message;
    });
    builder.addCase(getBusinessUserById.pending, (state, action) => {
      state.getUserPending = true;
      state.businessUserDetails = null;
    });
    builder.addCase(getBusinessUserById.fulfilled, (state, action) => {
      state.getUserPending = false;
      state.businessUserDetails = action.payload;
    });
    builder.addCase(getBusinessUserById.rejected, (state, action) => {
      state.getUserPending = false;

      state.error = action?.error?.message;
    });
    builder.addCase(updateUserInfo.pending, (state, action) => {
      state.updateUserPending = true;
    });
    builder.addCase(updateUserInfo.fulfilled, (state, action) => {
      state.updateUserPending = false;
    });
    builder.addCase(updateUserInfo.rejected, (state, action) => {
      state.updateUserPending = false;
      state.error = action?.error?.message;
    });
    builder.addCase(deleteUser.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.pending = false;
      state.error = action?.error?.message;
    });
    builder.addCase(restoreUser.pending, (state, action) => {
      state.deleteUserPending = true;
    });
    builder.addCase(restoreUser.fulfilled, (state, action) => {
      state.deleteUserPending = false;
    });
    builder.addCase(restoreUser.rejected, (state, action) => {
      state.deleteUserPending = false;
      state.error = action?.error?.message;
    });
    builder.addCase(restoreAllUser.pending, (state, action) => {
      state.deleteUserPending = true;
    });
    builder.addCase(restoreAllUser.fulfilled, (state, action) => {
      state.deleteUserPending = false;
    });
    builder.addCase(restoreAllUser.rejected, (state, action) => {
      state.deleteUserPending = false;
      state.error = action.error.message;
    });
    builder.addCase(retrieveAllTrashedUser.pending, (state, action) => {
      state.deleteUserPending = true;
    });
    builder.addCase(retrieveAllTrashedUser.fulfilled, (state, action) => {
      state.deleteUserPending = false;
    });
    builder.addCase(retrieveAllTrashedUser.rejected, (state, action) => {
      state.deleteUserPending = false;
      state.error = action.error.message;
    });
    builder.addCase(retrieveAllUserWithTrashed.pending, (state, action) => {
      state.deleteUserPending = true;
    });
    builder.addCase(retrieveAllUserWithTrashed.fulfilled, (state, action) => {
      state.deleteUserPending = false;
    });
    builder.addCase(retrieveAllUserWithTrashed.rejected, (state, action) => {
      state.deleteUserPending = false;
      state.error = action.error.message;
    });
    builder.addCase(userEmailUpdate.pending, (state, action) => {
      state.userEmailPending = true;
    });
    builder.addCase(userEmailUpdate.fulfilled, (state, action) => {
      state.userEmailPending = false;
    });
    builder.addCase(userEmailUpdate.rejected, (state, action) => {
      state.userEmailPending = false;
      state.error = action.error.message;
    });
    builder.addCase(userRoleAssign.pending, (state, action) => {
      state.userRolePending = true;
    });
    builder.addCase(userRoleAssign.fulfilled, (state, action) => {
      state.userRolePending = false;
    });
    builder.addCase(userRoleAssign.rejected, (state, action) => {
      state.userRolePending = false;
      state.error = action.error.message;
    });
    builder.addCase(userSubscriptionPackage.pending, (state, action) => {
      state.userSubPackagePending = true;
    });
    builder.addCase(userSubscriptionPackage.fulfilled, (state, action) => {
      state.userSubPackagePending = false;
      state.getAllSubPackage = action.payload;
    });
    builder.addCase(userSubscriptionPackage.rejected, (state, action) => {
      state.userSubPackagePending = false;
      state.error = action.error.message;
    });
    builder.addCase(getUsersPlan.pending, (state, action) => {
      state.userSubPackagePending = true;
    });
    builder.addCase(getUsersPlan.fulfilled, (state, action) => {
      state.userSubPackagePending = false;
      state.getUsersPlan = action.payload;
    });
    builder.addCase(getUsersPlan.rejected, (state, action) => {
      state.userSubPackagePending = false;
      state.error = action.error.message;
    });
    builder.addCase(approveFreePlan.pending, (state, action) => {
      state.userSubPackagePending = true;
    });
    builder.addCase(approveFreePlan.fulfilled, (state, action) => {
      state.userSubPackagePending = false;
      state.approveUserDetail = action.payload;
    });
    builder.addCase(approveFreePlan.rejected, (state, action) => {
      state.userSubPackagePending = false;
      state.error = action.error.message;
    });

    builder.addCase(toggleInternal.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(toggleInternal.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(toggleInternal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(makeAdmin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(makeAdmin.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(makeAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(setMasterPassword.pending, (state, action) => {
      state.setPending = true;
    });
    builder.addCase(setMasterPassword.fulfilled, (state, action) => {
      state.setPending = false;
    });
    builder.addCase(setMasterPassword.rejected, (state, action) => {
      state.setPending = false;
      state.error = action.error.message;
    });

    builder.addCase(removeMasterPassword.pending, (state, action) => {
      state.removePending = true;
    });
    builder.addCase(removeMasterPassword.fulfilled, (state, action) => {
      state.removePending = false;
    });
    builder.addCase(removeMasterPassword.rejected, (state, action) => {
      state.removePending = false;
      state.error = action.error.message;
    });

    builder.addCase(resetTransactionPin.pending, (state, action) => {
      state.resetTransactionPinPending = true;
    });
    builder.addCase(resetTransactionPin.fulfilled, (state, action) => {
      state.resetTransactionPinPending = false;
    });
    builder.addCase(resetTransactionPin.rejected, (state, action) => {
      state.resetTransactionPinPending = false;
      state.error = action.error.message;
    });

    builder.addCase(changeSubscription.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(changeSubscription.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(changeSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchTransactions.pending, (state, action) => {
      state.loading = true;
      state.userTransactionsDetails = null;
      state.userTransactions = [];
    });

    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.userTransactionsDetails = action.payload;
      state.userTransactions = action.payload?.data;
    });
    builder.addCase(fetchTransactions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchSubscriptionHistory.pending, (state, action) => {
      state.loading = true;
      state.subscriptionHistory = [];
      state.subscriptionHistoryDetails = null;
    });
    builder.addCase(fetchSubscriptionHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.subscriptionHistory = action.payload?.data;
      state.subscriptionHistoryDetails = action.payload;
    });
    builder.addCase(fetchSubscriptionHistory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(approveDocument.pending, (state, action) => {
      state.approveDocLoading = true;
    });
    builder.addCase(approveDocument.fulfilled, (state, action) => {
      state.approveDocLoading = false;
    });
    builder
      .addCase(approveDocument.rejected, (state, action) => {
        state.approveDocLoading = false;
        state.error = action.error.message;
      })
      .addCase(fundWalletOTP.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(fundWalletOTP.fulfilled, (state, action) => {
        state.pending = false;
      })

      .addCase(fundWalletConfirmation.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(fundWalletConfirmation.fulfilled, (state, action) => {
        state.pending = false;
      });

    builder.addCase(sendEmail.pending, (state, action) => {
      state.sendMailPending = true;
    });

    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.sendMailPending = false;
      state.error = action.error.message;
    });

    builder.addCase(saveTemplate.pending, (state, action) => {
      state.sendMailPending = true;
    });

    builder.addCase(saveTemplate.fulfilled, (state, action) => {
      state.saveTemplatePending = false;
    });
    builder.addCase(saveTemplate.rejected, (state, action) => {
      state.saveTemplatePending = false;
      state.error = action.error.message;
    });

    builder.addCase(getAllTemplates.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getAllTemplates.fulfilled, (state, action) => {
      state.pending = false;
      state.templates = action.payload;
    });
    builder.addCase(getAllTemplates.rejected, (state, action) => {
      state.getTemplatepending = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteTemplate.pending, (state, action) => {
      state.getTemplatepending = true;
    });

    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      state.getTemplatepending = false;
    });
    builder.addCase(deleteTemplate.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
  },
});

export default userManagementSlice.reducer;
