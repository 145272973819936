import React from "react";

function OpportunitiesIcon({stroke}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M9 12.3333C9 12.0233 9 11.8683 9.03407 11.7412C9.12654 11.3961 9.39609 11.1265 9.74118 11.0341C9.86835 11 10.0233 11 10.3333 11H11.6667C11.9767 11 12.1317 11 12.2588 11.0341C12.6039 11.1265 12.8735 11.3961 12.9659 11.7412C13 11.8683 13 12.0233 13 12.3333V13C13 14.1046 12.1046 15 11 15C9.89543 15 9 14.1046 9 13V12.3333Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.9 12.5H14.0826C15.3668 12.5 16.0089 12.5 16.5556 12.3842C18.138 12.049 19.429 11.0207 19.9939 9.64553C20.1891 9.17043 20.2687 8.59552 20.428 7.4457C20.4878 7.01405 20.5177 6.79823 20.489 6.62169C20.4052 6.10754 19.9932 5.68638 19.4381 5.54764C19.2475 5.5 19.0065 5.5 18.5244 5.5H3.47562C2.99351 5.5 2.75245 5.5 2.56187 5.54764C2.00682 5.68638 1.59477 6.10754 1.51104 6.62169C1.48229 6.79823 1.51219 7.01405 1.57198 7.4457C1.73128 8.59552 1.81092 9.17043 2.00609 9.64553C2.571 11.0207 3.86198 12.049 5.44436 12.3842C5.99105 12.5 6.63318 12.5 7.91743 12.5H9.1" stroke={stroke} stroke-width="1.5"/>
  <path d="M2.5 10.5V12.5C2.5 16.2712 2.5 18.1569 3.60649 19.3284C4.71297 20.5 6.49383 20.5 10.0556 20.5H11.9444C15.5062 20.5 17.287 20.5 18.3935 19.3284C19.5 18.1569 19.5 16.2712 19.5 12.5V10.5" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.5 5.5L14.4227 5.14679C14.0377 3.38673 13.8452 2.50671 13.3869 2.00335C12.9286 1.5 12.3199 1.5 11.1023 1.5H10.8977C9.68014 1.5 9.07139 1.5 8.61309 2.00335C8.15478 2.50671 7.96228 3.38673 7.57727 5.14679L7.5 5.5" stroke={stroke} stroke-width="1.5"/>
</svg>
  );
}

export default OpportunitiesIcon;
