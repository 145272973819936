import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
   API_SET_MASTER_PASSWORD,
 
} from "../../../config/api";
import {  getAllUser, getUserDetail } from "./management";

export const setMasterPassword = createAsyncThunk(
    "masterPassword/set",
    async ({userId, callback}, {dispatch, rejectWithValue }) => {
      try {
        const response = await Client({
          method: "POST",
          path: `${API_SET_MASTER_PASSWORD}${userId}`,
        });
        toast.success("Master Password Set Successfully");
        callback()
        dispatch(getAllUser());

        dispatch(getUserDetail({id : userId}));
        return response?.data;
      } catch (error) {
   
        const msg = proccessErrorMessage(error);
        toast.error(msg);
        return rejectWithValue(msg);
      }
    }
  );
  