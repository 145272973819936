import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_GET_APPLICATION } from "../../../config/api";

export const getApplication = createAsyncThunk(
  "application/get",
  async (id) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_GET_APPLICATION}${id}`,
      });

      return data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
    }
  }
);
