import React, { useEffect, useState } from "react";
import Dots from "../../Icons/Dots";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useDispatch, useSelector } from "react-redux";
import Usermenu from "../../menus/Usermenu";
import { getWaitList } from "../../../store/actions/userManagement/getWaitList";
import Loader from "../../SharedLayouts/Loader";
import WaitListMenu from "../../menus/WaitListMenu";

const WaitingUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [activeFilter, setActiveFilter] = useState("all");
  const [filteredRows, setFilteredRows] = useState([]);
  const [masterPassword, setMasterPassword] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showFundWalletModal, setShowFundWalletModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showResetPinModal, setShowResetPinModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showRemoveMasterPasswordModal, setRemoveMasterPasswordModal] =
    useState(false);
  const [showSetMasterPasswordModal, setSetMasterPasswordModal] =
    useState(false);

  const { waitingUsers, allUsersPending } = useSelector((state) => state?.user);

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    setSelectedUserId(Number(event.currentTarget.getAttribute("data-id")));

    setMasterPassword(row?.masterPassword);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleAdminUserClick = () => {
    setShowAdminModal(true);
    handleClose();
  };
  const handleResetPin = () => {
    setShowResetPinModal(true);
    handleClose();
  };

  const handleRemoveMasterPassword = () => {
    setRemoveMasterPasswordModal(true);
    handleClose();
  };
  const handleSetMasterPassword = () => {
    setSetMasterPasswordModal(true);
    handleClose();
  };

  const handleApproveUserClick = () => {
    setShowApproveModal(true);
    handleClose();
  };

  const handleSubscriptionUserClick = () => {
    setShowSubscriptionModal(true);
    handleClose();
  };
  const handleFundWalletClick = () => {
    setShowFundWalletModal(true);
    handleClose();
  };

  const handleDeleteUserClick = () => {
    setShowDeleteModal(true);
    handleClose();
  };

  const columns = [
    {
      field: "id",
      headerName: "User ID",
    },
    { field: "first_name", headerName: "First Name", width: 150 },
    { field: "last_name", headerName: "Last Name", width: 150 },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "bvnVerified",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return "";
      },
    },

    {
      renderCell: (params) => (
        <>
          <div
            style={{
              cursor: "pointer",
              position: "relative",
            }}
            onClick={(e) => {
              e.target.setAttribute("data-is-icon", "true");
              handleContextMenu(e, params?.row);
            }}
          >
            <Dots />
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (waitingUsers)
      setFilteredRows(
        activeFilter === "all"
          ? waitingUsers
          : waitingUsers
              ?.map((row) => ({
                ...row,
                id: row?.userId,
              }))
              ?.filter((row) => {
                const membershipStatus = row?.selectedPlan || "";
                return membershipStatus?.toLowerCase() === activeFilter;
              })
      );
  }, [activeFilter, waitingUsers]);

  useEffect(() => {
    return () => {
      setAnchorEl(null);
    };
  }, [anchorEl]);

  useEffect(() => {
    dispatch(getWaitList());
  }, []);

  return (
    <div className="mt-10 mb-8">
      <div className="bg-white py-6 rounded-lg ">
        {!allUsersPending || true ? (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              rows={filteredRows}
              columns={columns}
              // searchPlaceholder={"Search for users"}
              getRowId={(row) => row.id}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              // handleRowClick={handleRowClick}
            />
          </Box>
        ) : (
          <div className="h-10">
            <Loader />
          </div>
        )}
      </div>
      <WaitListMenu
        handleView={() => {}}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        handleClose={handleClose}
        handleSubscriptionUserClick={handleSubscriptionUserClick}
        handleFundWalletClick={handleFundWalletClick}
        handleAdminUserClick={handleAdminUserClick}
        handleApproveUserClick={handleApproveUserClick}
        handleRemoveMasterPassword={handleRemoveMasterPassword}
        handleSetMasterPassword={handleSetMasterPassword}
        handleResetPin={handleResetPin}
        handleDeleteUserClick={handleDeleteUserClick}
        masterPassword={masterPassword}
        // onMenuSelect={(e, row) => handleContextMenu(e,row)}

        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default WaitingUsers;
