import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import CustomButton from "../../Buttons/Button";
import Templates, { htmlToDelta } from "./Templates";
import {
  AttachFileOutlined,
  DeleteOutline,
  ExpandMore,
} from "@mui/icons-material";
import {
  deleteTemplate,
  getAllTemplate,
  sendEmail,
} from "../../../store/actions/userManagement/sendEmail";
import CustomInput from "../../inputs/CustomInput";
import { useFormik } from "formik";
import { ClearIcon } from "@mui/x-date-pickers";
import { getAllUser } from "../../../store/actions/userManagement/management";
import TemplateDraftModal from "../../modal/TemplateDraftModal";
import { getAllTemplates } from "../../../store/actions/userManagement/getAllTemplates";
import Loader from "../../SharedLayouts/Loader";

const Adminmail = () => {
  const [content, setContent] = useState(`
<p>Dear [name],</p>
<p><br></p>
<p>I hope this email finds you well. We wanted to inform you about the latest updates and exciting news at our company:</p>
<p><br></p><ul><li>Introduction of a new product/service</li><li>Upcoming events and webinars</li><li>Recent achievements and milestones</li>
</ul>
<p><br></p><p>We value your feedback and would love to hear your thoughts on these developments.
 Feel free to reach out if you have any questions or suggestions.</p><p><br></p><p>Thank you for being a valued member of our community.
 </p>
 <p><br></p>
 <p><br></p><p><br></p><p>Best regards,</p><p>[Your Name]</p><p>[Your Position]</p><p>[Your Company]</p>
`);
  const loading = useSelector((state) => state?.user?.pending);
  const getTemplateLoading = useSelector(
    (state) => state?.user?.getTemplatepending
  );
  const sendMailLoading = useSelector((state) => state?.user?.sendMailPending);
  const saveTemplateLoading = useSelector(
    (state) => state?.user?.saveTemplatePending
  );
  const allUser = useSelector((state) => state?.user?.allUsers) || [];

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [IsTemplateOpen, setOpenTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateContent, setTemplateContent] = useState("");
  const [selectedUserFilter, setSelectedUserFilter] = useState("all");
  const [openModal, setOpenModal] = useState(false);
  const templates = useSelector((state) => state?.user?.templates);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedUserIds, setSelectedUserIds] = useState(
    allUser?.map((user) => user.id)
  );

  const handleInsertTemplate = (content) => {
    setContent(content);
    setOpenTemplate(false); // Close the template dialog
  };
  const editorRef = useRef();
  const dispatch = useDispatch();

  const premiumUserIds = allUser
    ?.filter((user) => user.selectedPlan === "Platinum")
    ?.map((user) => user.id);

  const freeUserIds = allUser
    ?.filter((user) => user.selectedPlan === "free")
    ?.map((user) => user.id);

  const incompleteUserIds = allUser
    ?.filter((user) => !user?.hasProfileCompleted)
    .map((user) => user.id);
  const allUserIds = allUser?.map((user) => user.id);

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setSelectedUserFilter(selectedFilter);

    if (selectedFilter === "basic") {
      setSelectedUserIds(freeUserIds);
    } else if (selectedFilter === "premium") {
      setSelectedUserIds(premiumUserIds);
    } else if (selectedFilter === "incompelete") {
      setSelectedUserIds(incompleteUserIds);
    } else {
      setSelectedUserIds(allUser?.map((user) => user.id));
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("email_template", content);
    formData.append("subject", form.values?.emailSubject);

    selectedUserIds.forEach((user, index) => {
      formData.append(`user_ids[]`, user);
    });
    selectedFiles.forEach((file, index) => {
      formData.append(`attachments[]`, file);
    });

    dispatch(sendEmail(formData));
  };
  const handleDelete = (id) => {
    dispatch(deleteTemplate(id));
  };
  const form = useFormik({
    initialValues: {
      emailSubject: "",
    },
    onSubmit: handleSubmit,
  });

  const deleteFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  useEffect(() => {
    dispatch(getAllUser());
    dispatch(getAllTemplates());
  }, [dispatch]);

  const delta = htmlToDelta(content);
  return (
    <div className="pb-[200px]">
      <div className="flex gap-5">
        <div className="flex flex-col ">
          <div className="mb-5">
            <label>Email Subject</label>
            <CustomInput
              width="100%"
              height="16px"
              id="emailSubject"
              form={form}
              placeholder={"Enter Email Subject "}
              borderColor="#D0D5DD"
              borderRadius={"5px"}
              pr="14px"
            />
          </div>

          <div className="border-solid border border-gray-300 rounded-md overflow-hidden flex-[1]">
            <EditorToolbar
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              callback={() => setOpenModal(true)}
            />

            <ReactQuill
              value={content}
              onChange={setContent}
              theme="snow"
              ref={editorRef}
              placeholder="Type your email here..."
              modules={modules} // Customize modules for additional features
              formats={formats} // Customize formats for supported formatting options
              className="h-96" // Adjust the height as needed
            />
          </div>

          <div className="mb-5 mt-8 flex flex-col gap-3">
            <label className="block font-semibold text-[#475467] ">
              Attachments
            </label>

            {selectedFiles?.length > 0 && (
              <div>
                <ul className="grid grid-cols-2 gap-2 mt-2 max-h-[100%] max-w-[400px] overflow-y-auto">
                  {selectedFiles?.map((file, index) => (
                    <div className="flex flex-col gap-1">
                      <div className="px-8 py-2 bg-[#80808030] flex justify-center items-center relative">
                        <p className="!text-[12px] ">
                          {file?.name?.substring(0, 15)}...
                        </p>
                        <ClearIcon
                          onClick={deleteFile}
                          className="absolute top-2 right-2 !text-[15px]"
                        />
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="w-[400px] mt-[60px] mb-2 ">
            <label className="block font-semibold text-[#475467] ">
              Templates ({templates?.length})
            </label>
            <div className=" border mb-3 px-2  mt-2 rounded-[4px] min-h-[200px]  p-3">
              {getTemplateLoading ? (
                <Loader />
              ) : (
                templates?.map((temp, index) => (
                  <li
                    className="relative flex hover:bg-[#e4f9fc] hover:text-121212 items-center"
                    key={index}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <h1
                      className="cursor-pointer  p-2 text-[14px] rounded-[5px]"
                      onClick={() => {
                        setSelectedTemplate(index);
                        setOpenTemplate(true);
                      }}
                    >
                      {temp?.template_title}
                    </h1>
                    {hoveredIndex === index && (
                      // <button
                      //

                      // >
                      <DeleteOutline
                        className=" ml-auto !fill-red-500 text-white  rounded cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents onClick of paragraph from firing
                          handleDelete(temp?.id); // You would define this function to handle deletion
                        }}
                      />
                      // </button>
                    )}
                  </li>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-end gap-3 mt-4">
        <div>
          <label
            htmlFor="userFilter"
            className="block font-semibold flex items-center text-[#475467] mb-2"
          >
            Choose Recipients:
            <p className="text-[12px]">({selectedUserIds?.length} users)</p>
          </label>
          <Select
            id="userFilter"
            value={selectedUserFilter}
            onChange={handleFilterChange}
            className="p-0 rounded-md w-[200px]"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="incomplete">Incomplete</MenuItem>
            <MenuItem value="basic">Basic</MenuItem>
            <MenuItem value="premium">Premium</MenuItem>
          </Select>
        </div>
        <div>
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Send Mail"
            loading={sendMailLoading}
            width="160px"
            height="50px"
            padding="10px"
            onClick={form.handleSubmit}
          />
        </div>

        <div>
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Save as Template"
            loading={saveTemplateLoading}
            width="160px"
            height="50px"
            padding="10px"
            onClick={() => setOpenModal(true)}
          />
        </div>
      </div>

      {IsTemplateOpen && (
        <Dialog
          open={IsTemplateOpen}
          onClose={() => setOpenTemplate(false)}
          sx={{
            p: 0,
            "& .css-ypiqx9-MuiDialogContent-root": {
              pl: "20px",
              pr: 0,
            },
            "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
              minWidth: "1050px",
              minHeight: "600px",
            },
          }}
        >
          <DialogContent>
            <Templates
              handleInsertTemplate={handleInsertTemplate}
              selectedTemplate={selectedTemplate}
              setOpenTemplate={setOpenTemplate}
              templates={templates}
            />
          </DialogContent>
        </Dialog>
      )}

      <TemplateDraftModal
        open={openModal}
        content={content}
        onClose={() => setOpenModal(false)}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default Adminmail;
