import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";
import Loader from "../SharedLayouts/Loader";
import GridTable from "./gridTable";
import useCurrency from "../../hooks/useCurrency";
import { getAllSettlementData } from "../../store/actions/settlement/getAllSettlementData";
import { getAllUser } from "../../store/actions/userManagement/management";

function ProvidusTable({ allSettlement, loading }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeFilter] = useState("all");
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [_currency, getAmount] = useCurrency();
  const allUser = useSelector((state) => state?.user?.allUsers) || [];

  useEffect(() => {
    dispatch(getAllSettlementData());
    dispatch(getAllUser());
  }, [dispatch]);

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";
    setSelectedUserId(params?.row?.settlement_id);
    if (!isIconClick) {
      setSelectedUserId(params?.row?.settlement_id);
      navigate(`/providus-report/${params?.row?.settlement_id}`);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    const rows = allSettlement?.map((settlement, index) => ({
      id: settlement?.id,
      userId: settlement?.user_id,
      firstName: settlement?.user?.name?.split(" ")[0],
      lastName: settlement?.user?.name?.split(" ")[1],
      user_name: settlement?.user?.name,
      currency: settlement?.currency,
      sessionId: settlement?.session_id,
      settlement_id: settlement?.settlement_id,
      accountNumber: settlement?.account_number,
      accountName: settlement?.source_account_name,
      remarks: settlement?.tran_remarks,
      date: new Date(settlement?.tran_date_time).toDateString(),
      amount: `${getCurrencySymbol(settlement?.currency)} ${getAmount(
        settlement?.transaction_amount
      )}`,

      rowIndex: index,
    }));

    const filtered =
      searchTerm === ""
        ? rows
        : rows.filter(
            (row) =>
              row?.firstName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              row?.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              row?.Email?.toLowerCase().includes(searchTerm.toLowerCase())
          );

    setFilteredRows(filtered);
  }, [allSettlement, searchTerm]);

  const columns = [
    {
      field: "userId",
      headerName: "User ID",
      width: 100,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 100,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 100,
    },

    {
      field: "accountNumber",
      headerName: "Account Number",
      width: 150,
    },
    {
      field: "sessionId",
      headerName: "Session ID",
      width: 300,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },

    {
      field: "date",
      headerName: "Transaction Date",
      width: 300,
    },
  ];

  return (
    <div className="mt-10 mb-8">
      <div className="bg-white">
        {!loading ? (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              type={"providus"}
              rows={filteredRows}
              columns={columns}
              getRowId={(row) => row.id}
              handleRowClick={handleRowClick}
            />
          </Box>
        ) : (
          <div className="h-10">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProvidusTable;
