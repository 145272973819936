import React, { useEffect, useState } from "react";
import Dots from "../../Icons/Dots";
import { useNavigate } from "react-router-dom";
import GridTable from "../../table/gridTable";
import ApproveModal from "../../modal/ApproveModal";
import SubscriptionModal from "../../modal/SubscriptionModal";
import AdminModal from "../../modal/AdminModal";
import ConfirmDeleteModal from "../../modal/ConfirmDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import Usermenu from "../../menus/Usermenu";
import { deleteUser } from "../../../store/actions/userManagement/deleteUser";
import FundWalletModal from "../../modal/FundWalletModal";
import ResetTransactionPinModal from "../../modal/ResetTransactionPinModal";
import SetMasterPasswordModal from "../../modal/SetMasterPasswordModal";
import RemovetMasterPasswordModal from "../../modal/RemoveMasterPasswordModal";
import { getAllUser } from "../../../store/actions/userManagement/management";
import Loader from "../../SharedLayouts/Loader";
import { setUserFilter } from "../../../store/reducers/dashboardSlice";
import { userFilterOptions } from "../../../config/data";

const UserTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState();

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showFundWalletModal, setShowFundWalletModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showResetPinModal, setShowResetPinModal] = useState(false);
  const [showPopper, setShowPopper] = useState(false);
  const [masterPassword, setMasterPassword] = useState(false);

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { allUsers, allUsersInfo, allUsersPending } = useSelector(
    (state) => state?.user
  );
  const { userFilter } = useSelector((state) => state?.dashboard);
  const [showRemoveMasterPasswordModal, setRemoveMasterPasswordModal] =
    useState(false);
  const [showSetMasterPasswordModal, setSetMasterPasswordModal] =
    useState(false);

  const columns = [
    {
      field: "userId",
      headerName: "User ID",
      renderHeader: ({ colDef, field }) => {
        return (
          <h3 className="text-xs  text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        );
      },
      renderCell: (params) => (
        <div className="flex items-center">
          <h3 className="text-sm  text-[#101828] font-[400] leading-5">
            {params.row?.id}
          </h3>
        </div>
      ),
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      renderHeader: ({ colDef, field }) => {
        return (
          <h3 className="text-xs  text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        );
      },
      renderCell: (params) => (
        <div className="flex items-center">
          <h3 className="text-sm  text-[#101828] font-[400] leading-5">
            {params.row?.name?.split(" ")[0]}
          </h3>
        </div>
      ),
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      renderHeader: ({ colDef, field }) => {
        return (
          <h3 className="text-xs  text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        );
      },
      renderCell: (params) => (
        <div className="flex items-center">
          <h3 className="text-sm  text-[#101828] font-[400] leading-5">
            {params.row?.name?.split(" ")[1]}
          </h3>
        </div>
      ),
    },
    {
      field: "Email",
      headerName: "Email",
      width: 200,
      renderHeader: ({ colDef, field }) => {
        return (
          <h3 className="text-xs  text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        );
      },
      renderCell: (params) => (
        <div className="flex items-center">
          <h3 className="text-sm  text-[#101828] font-[400] leading-5">
            {params.row?.email}
          </h3>
        </div>
      ),
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      width: 150,
      renderHeader: ({ colDef, field }) => {
        return (
          <h3 className="text-xs  text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        );
      },
      renderCell: (params) => (
        <div className="flex items-center">
          <h3 className="text-sm  text-[#101828] font-[400] leading-5">
            {params.row?.profile?.phone}
          </h3>
        </div>
      ),
    },
    {
      field: "bvnVerified",
      headerName: "BVN Verified",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className="px-3 py-0.5"
            style={{
              borderRadius: "20px",
              marginRight: "10px",
              textAlign: "center",
              color: !params.row?.profile?.BVN ? "#B42318" : "#067647",

              backgroundColor: params.row?.profile?.BVN
                ? "#ECFDF3"
                : !params.row?.profile?.BVN
                ? "#f5f0f0"
                : "#FECDCA",
            }}
          >
            {params.row?.profile?.BVN ? "YES" : "NO"}
          </div>
        );
      },
    },
    {
      field: "DateJoined",
      headerName: "Date Joined",
      width: 200,
      renderHeader: ({ colDef, field }) => {
        return (
          <h3 className="text-xs  text-[#475467] font-[500] leading-[18px]">
            {colDef.headerName}
          </h3>
        );
      },
      renderCell: (params) => (
        <div className="flex items-center">
          <h3 className="text-sm  text-[#101828] font-[400] leading-5">
            {new Date(params.row?.account_creation_date).toDateString()}
          </h3>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Membership Status",
      width: 307,
      renderCell: (params) => {
        const membershipStatus =
          params.row?.selectedPlan === "free"
            ? "Non-member"
            : params.row?.selectedPlan === null ||
              params.row?.investorProfile === null ||
              params.row?.hasProfileCompleted === false
            ? "Incomplete"
            : "Member";

        const subscriptionStatus =
          params.row?.free_approved_plan === "approved" &&
          params.row?.selectedPlan === "free"
            ? "Approved"
            : params.row?.free_approved_plan !== "approved"
            ? "Pending"
            : params.row?.selectedPlan !== "free" &&
              params.row?.selectedPlan !== null
            ? "Subscribed"
            : null;
        return (
          <div className="flex">
            <div
              className="px-3 py-0.5"
              style={{
                borderRadius: "20px",
                marginRight: "10px",

                color:
                  membershipStatus === "Non-member" ? "#B42318" : "#067647",

                backgroundColor:
                  membershipStatus === "Member"
                    ? "#ECFDF3"
                    : membershipStatus === "Incomplete"
                    ? "#f5f0f0"
                    : "#FECDCA",
              }}
            >
              {membershipStatus}
            </div>
            <div
              className="px-3 py-0.5"
              style={{
                borderRadius: "20px",
                textAlign: "center",

                color:
                  membershipStatus === "Non-member" ? "#344054" : "##175CD3",
                backgroundColor:
                  membershipStatus === "Non-member" ? "#EAECF0" : "#EFF8FF",
              }}
            >
              {subscriptionStatus}
            </div>
          </div>
        );
      },
    },
    {
      renderCell: (params) => (
        <>
          <div
            style={{
              cursor: "pointer",
              position: "relative",
            }}
            onClick={(e) => {
              e.target.setAttribute("data-is-icon", "true");
              handleContextMenu(e, params?.row);
            }}
          >
            <Dots />
          </div>
        </>
      ),
    },
  ];

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedUserId(Number(event.currentTarget.getAttribute("data-id")));
    setSelectedRow(row);
    setMasterPassword(row?.masterPassword);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDelete = () => {
    dispatch(
      deleteUser({
        payload: selectedUserId,
        callback: () => setShowDeleteModal(false),
      })
    );
  };

  const handleAdminUserClick = () => {
    setShowAdminModal(true);
    handleClose();
  };
  const handleResetPin = () => {
    setShowResetPinModal(true);
    handleClose();
  };

  const handleRemoveMasterPassword = () => {
    setRemoveMasterPasswordModal(true);
    handleClose();
  };
  const handleSetMasterPassword = () => {
    setSetMasterPasswordModal(true);
    handleClose();
  };

  const handleApproveUserClick = () => {
    setShowApproveModal(true);
    handleClose();
  };

  const handleSubscriptionUserClick = () => {
    setShowSubscriptionModal(true);
    handleClose();
  };
  const handleFundWalletClick = () => {
    setShowFundWalletModal(true);
    handleClose();
  };

  const handleDeleteUserClick = () => {
    setShowDeleteModal(true);
    handleClose();
  };

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";

    setSelectedUserId(params?.row?.id);

    setSelectedRow(params?.row);

    if (!isIconClick) {
      navigate(`/user-management/${params?.row?.id}`);
    }
  };

  const handleFilterClick = (id) => {
    dispatch(setUserFilter(id));
  };

  useEffect(() => {
    if (anchorEl) {
      setShowPopper(true);
    }

    return () => {
      setAnchorEl(null);
    };
  }, [anchorEl]);

  useEffect(() => {
    dispatch(getAllUser());
  }, []);

  return (
    <div className="mt-10 mb-8">
      {allUsersPending && (!allUsers || allUsers?.length < 1) ? (
        <div className="h-10">
          <Loader />
        </div>
      ) : (
        <>
          <div className="w-full overflow-x-auto mb-4">
            <div className=" flex cursor-pointer w-[max-content]">
              {userFilterOptions?.map((item) => {
                return (
                  <span
                    key={item.id}
                    className={`${
                      userFilter === item.id
                        ? "border-b-2 border-[#128591] text-[#128591] font-[600]"
                        : "text-gray-700"
                    } mr-3 text-base`}
                    onClick={() => handleFilterClick(item.id)}
                  >
                    {item.name}
                  </span>
                );
              })}
            </div>
          </div>

          <GridTable
            type={"users"}
            rows={allUsers || []}
            searchPlaceholder={"Search for users"}
            pageInfo={allUsersInfo}
            columns={columns}
            getRowId={(row) => row.id}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: "context-menu" },
              },
            }}
            handleRowClick={handleRowClick}
          />

          <Usermenu
            selected={selectedRow}
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            handleClose={handleClose}
            handleSubscriptionUserClick={handleSubscriptionUserClick}
            handleFundWalletClick={handleFundWalletClick}
            handleAdminUserClick={handleAdminUserClick}
            handleApproveUserClick={handleApproveUserClick}
            handleRemoveMasterPassword={handleRemoveMasterPassword}
            handleSetMasterPassword={handleSetMasterPassword}
            handleResetPin={handleResetPin}
            handleDeleteUserClick={handleDeleteUserClick}
            masterPassword={masterPassword}
            onMenuSelect={(e, row) => handleContextMenu(e, row)}
            componentsProps={{
              root: {
                onContextMenu: (e) => {
                  e.preventDefault();
                  handleClose();
                },
              },
            }}
            anchorEl={anchorEl}
          />
        </>
      )}

      {showApproveModal && (
        <ApproveModal
          open={showApproveModal}
          userId={selectedUserId}
          onClose={() => setShowApproveModal(false)}
          setShowApproveModal={setShowApproveModal}
        />
      )}
      {showSubscriptionModal && (
        <SubscriptionModal
          userId={selectedUserId}
          open={showSubscriptionModal}
          onClose={() => setShowSubscriptionModal(false)}
          setShowSubscriptionModal={setShowSubscriptionModal}
        />
      )}
      {showFundWalletModal && (
        <FundWalletModal
          open={showFundWalletModal}
          userId={selectedUserId}
          onClose={() => setShowFundWalletModal(false)}
          setShowFundWalletModal={setShowFundWalletModal}
        />
      )}

      {showAdminModal && (
        <AdminModal
          open={showAdminModal}
          user={selectedRow}
          onClose={() => setShowAdminModal(false)}
          setShowAdminModal={setShowAdminModal}
        />
      )}
      {showResetPinModal && (
        <ResetTransactionPinModal
          open={showResetPinModal}
          userId={selectedUserId}
          onClose={() => setShowResetPinModal(false)}
          setShowResetPinModal={setShowResetPinModal}
        />
      )}

      {showDeleteModal && (
        <ConfirmDeleteModal
          open={showDeleteModal}
          handleDelete={handleDelete}
          onClose={() => setShowDeleteModal(false)}
        />
      )}

      {showSetMasterPasswordModal && (
        <SetMasterPasswordModal
          open={showSetMasterPasswordModal}
          userId={selectedUserId}
          onClose={() => setSetMasterPasswordModal(false)}
          setSetMasterPasswordModal={setSetMasterPasswordModal}
        />
      )}
      {showRemoveMasterPasswordModal && (
        <RemovetMasterPasswordModal
          open={showRemoveMasterPasswordModal}
          userId={selectedUserId}
          onClose={() => setRemoveMasterPasswordModal(false)}
          setRemoveMasterPasswordModal={setRemoveMasterPasswordModal}
        />
      )}
    </div>
  );
};

export default UserTable;
