import { Menu, Popover } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { moveInvestmentUp } from "../../store/actions/investment/moveInvestmentUp";

const BusinessUserMenu = ({
  handleClose,
  handleOpenUserDetails,
  anchorEl,
  selectedRow,
  ...props
}) => {
  const dispatch = useDispatch();

  return (
    <Menu
      {...props}
      // anchorPosition={{
      //   vertical: "top",
      //   horizontal: "right",
      // }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "158px",
          padding: 5,

          cursor: "pointer",
        }}
      >
        <span
          className=" font-[400] hover:bg-[rgba(43,47,50,0.3)] rounded-md text-[#2B2F32] cursor-pointer text-[14px] p-3  w-full"
          onClick={handleOpenUserDetails}
        >
          View
        </span>
        {/* <span
          className=" font-[400] text-[#2B2F32]  text-[14px] p-3  w-full"
          onClick={handleOpenUserDetails}
        >
         Edit
        </span> */}
        <span
          className=" font-[400] hover:bg-[rgba(255,85,85,0.3)] rounded-md text-[#FF5555] cursor-not-allowed text-[14px] p-3  w-full"
          // onClick={handleMoveDown}
        >
          Close Account
        </span>
      </div>
    </Menu>
  );
};

export default BusinessUserMenu;
