import { createSlice } from "@reduxjs/toolkit";
import { getSettlementById } from "../actions/settlement/getSettlementbyId";
import { getAllSettlementData } from "../actions/settlement/getAllSettlementData";

export const settlementSlice = createSlice({
  name: "settlement",
  initialState: {
    error: null,
    allSettlement: [],
    settlement: [],
    settlementDetails: null,
    loading: false,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllSettlementData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllSettlementData.fulfilled, (state, action) => {
      state.loading = false;
      state.allSettlement = action.payload;
    });
    builder.addCase(getAllSettlementData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getSettlementById.pending, (state, action) => {
      state.loading = true;
      state.settlement = [];
    });
    builder.addCase(getSettlementById.fulfilled, (state, action) => {
      state.loading = false;
      state.settlement = action.payload?.data;
      state.settlementDetails = action.payload;
    });
    builder.addCase(getSettlementById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default settlementSlice.reducer;
