import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_USER_ROLES } from "../../../config/api";

export const getRoles = createAsyncThunk("roles/get", async ({ cb }) => {
  try {
    const { data } = await Client({
      method: "GET",
      path: API_USER_ROLES,
    });

    cb();
    return data?.data;
  } catch (error) {
    const msg = proccessErrorMessage(error);
    toast.error(msg);
    cb();
  }
});
