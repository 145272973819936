import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllUser,
  getUserDetail,
} from "../../store/actions/userManagement/management";
import { toast } from "react-toastify";
import { getRoles } from "../../store/actions/roles/getRoles";
import SelectField from "../inputs/SelectField";
import { useFormik } from "formik";
import { makeAdmin } from "../../store/actions/roles/makeAdmin";
import { toggleInternal } from "../../store/actions/roles/toggleInternal";
function InternalUserModal({ open, onClose, user, setShowAdminModal }) {
  const dispatch = useDispatch();
  const alreadyAnAdmin = user?.roles?.find((role) =>
    role?.name.includes("Admin")
  );

  const [getingRoles, setGetingRoles] = useState(true);
  const loading = useSelector((state) => state?.user?.pending) || false;
  const adminLoading = useSelector((state) => state?.user?.loading) || false;

  const { roles } = useSelector((state) => state?.dashboard);

  const userId = user?.id;

  const handleSubmit = ({ role }, { setSubmitting }) => {
    const data = {
      role: role?.name,
    };
    dispatch(
      toggleInternal({
        id: user?.id,
        setSubmitting,
        cb: () => {
          dispatch(getUserDetail({ id: user?.id }));
          setShowAdminModal(false);
        },
      })
    );
  };

  const form = useFormik({
    initialValues: {
      role: "",
    },

    onSubmit: handleSubmit,
  });

  useEffect(() => {
    dispatch(
      getRoles({
        cb: () => {
          setGetingRoles(false);
        },
      })
    );
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 540,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-5">
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div>
            <p className="text-sm mb-4">
              Are you sure you want to continue this action?
            </p>

            <div className="flex mt-6 w-full items-center">
              <CustomButton
                variant="outlined"
                customColor="#fff"
                children="Cancel"
                width="100%"
                height="44px"
                borderColor="#D0D5DD"
                color="#344054"
                margin="0px 25px 0px 0px"
                iconWidth="22px"
                onClick={onClose}
              />

              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children={"Yes"}
                width="100%"
                height="44px"
                padding="10px"
                color={"#fff"}
                loading={form?.isSubmitting || adminLoading}
                onClick={form?.handleSubmit}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default InternalUserModal;
