import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import GridTable from "../table/gridTable";
import useCurrency from "../../hooks/useCurrency";
import { submitPayout } from "../../store/actions/disbursement/submitPayout";

const SelectMember = ({ open, onClose, setButtonText, percentage, form }) => {
  const loading = useSelector((state) => state?.disbursement?.pending);
  const investment = useSelector((state) => state?.investment?.investment);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [_currency, getAmount] = useCurrency();
  const dispatch = useDispatch();
  const columns = [
    // {
    //   field: "name",
    //   headerName: "Name",
    //   width: 200,
    //   renderCell: (params) => params.row.user.name,
    // },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      renderCell: (params) => params.row.user.name?.split(" ")[0],
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      renderCell: (params) => params.row.user.name?.split(" ")[1],
    },

    {
      field: "Email",
      headerName: "%",
      width: 80,
      renderCell: (params) => percentage,
    },
    {
      field: "amount",
      headerName: "Payout Amount",
      width: 120,
      renderCell: (params) =>
        _currency + getAmount((percentage / 100) * params.value, 2),
    },
  ];

  const rows = investment.investments;

  const handlePayout = () => {
    const selectedIDs = new Set(rowSelectionModel);
    const selectedRowData = rows
      ?.filter((row, index) => selectedIDs.has(index + 1))
      .map((item) => item.user_id);
    let beneficiaries = selectedRowData;

    beneficiaries = investment?.investments?.map((item) => item?.user_id);

    dispatch(
      submitPayout({
        ...form?.values,
        beneficiaries,
        expected_date_id: form?.values?.paymentSchedules?.value,
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 3,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full ">
          <img src="/Assets/approveIcon.png" alt="" className="w-12 h-12 " />
          <div>
            <h1 className="font-semibold text-2xl mt-4 mb-4">Select Users</h1>
            <p className="text-sm mb-4 w-[432px]">
              Are you sure you want to make this payout
            </p>
            <div className="h-[270px] overflow-scroll w-full items-center mr-4">
              <GridTable
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick={false}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                handleRowClick={(params, event) => {}}
              />
            </div>
            <div className="flex mt-4 w-full justify-center items-center">
              <CustomButton
                variant="outlined"
                customColor="#fff"
                children="Cancel"
                width="100%"
                onClick={onClose}
                height="50px"
                borderColor="#D0D5DD"
                margin="0px 20px 0px 0px"
                color="#159AA8"
                iconWidth="22px"
              />

              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children="Payout Users"
                width="100%"
                loading={loading}
                height="50px"
                padding="10px"
                onClick={handlePayout}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SelectMember;
