import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import onboarding from "./reducers/onboardingSlice";
import message from "./reducers/messageSlice";
import dashboard from "./reducers/dashboardSlice";
import portfolio from "./reducers/portfolioSlice";
import allBanks from "./reducers/bankSlice";
import userProfile from "./reducers/profileSlice";
import subscribe from "./reducers/subscribeSlice";
import investment from "./reducers/investmentSlice";
import user from "./reducers/managementSlice";
import disbursement from "./reducers/disbursementSlice";
import transactions from "./reducers/transactionSlice";
import settlement from "./reducers/settlementSlice";
import withdrawalRequests from "./reducers/withdrawerSlice";

const reducers = combineReducers({
  allBanks,
  onboarding,
  disbursement,
  dashboard,
  portfolio,
  message,
  userProfile,
  subscribe,
  user,
  investment,
  transactions,
  withdrawalRequests,
  settlement
});

const rootReducer = (state, action) => {
  if (action.type === "users/logout/fulfilled") {
    // This will reset the state back to its initial state on logout
    state = undefined;
  }
  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["onboarding", "profile", "ui"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export const persist = persistStore(store);
