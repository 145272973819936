import React, { useState } from "react";
import UserTransactionTable from "../table/UserTransactionTable";
import CustomButton from "../Buttons/Button";
import PreviousRecordsModal from "../modal/PreviousRecordsModal";
import { useParams } from "react-router-dom";

function UserTransaction() {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className="flex flex-wrap flex-row items-center justify-end mt-6 mb-4">
        <CustomButton
          variant="contained"
          customColor="#159AA8"
          children="Add Previous Record"
          width="full md:w-103px"
          height="40px"
          onClick={() => setOpenModal(true)}
          borderColor="#159AA8"
          padding="10px"
          fz={{ sm: "10px", md: "14px" }}
        />
      </div>

      <UserTransactionTable />
      <PreviousRecordsModal
        open={openModal}
        invID={id}
        onClose={handleCloseModal}
        setOpenModal={setOpenModal}
      />
    </div>
  );
}

export default UserTransaction;
