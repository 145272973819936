import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_GET_PAYOUT_HISTORY } from "../../../config/api";

export const getPayoutHistory = createAsyncThunk(
  "payout/getHistory",
  async ({id, callback}, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_PAYOUT_HISTORY + "/" + id,
      });
      callback(response?.data?.data)
      return response?.data?.data;
    } catch (error) {
      callback(error?.response?.data?.status)

      const msg = proccessErrorMessage(error);
      //   toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
