import { Navigate, createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Login from "../pages/onboarding/Login";
import UserManagement from "../pages/user";
import Investment from "../pages/investment";
import InvestmentDetail from "../pages/investment/InvestmentDetail";
import Transaction from "../pages/transaction";
import TransactionDetail from "../pages/transaction/TransactionDetail";
import AllTransaction from "../pages/transaction/AllTransaction";
import CreateInvestment from "../pages/investment/CreateInvestment";
import EditInvestment from "../pages/investment/EditInvestment";
import PreviousRecords from "../pages/previousRecords";
import WithdrawalRequest from "../pages/withdrawal";
import UserManagementDetail from "../pages/user/UserManagementDetail";
import AccountSummary from "../pages/accountSummary'";
import AccountSummaryDetail from "../pages/accountSummary'/AccountSummaryDetail";
import DraftEmail from "../pages/AdminEmail/Index";
import ChartOfAccount from "../pages/ChartOfAccount/ChartOfAccount";
import AdminEmail from "../pages/AdminEmail/Index";
import ProvidusReport from "../pages/providusReport";
import ProvidusReportDetail from "../components/dashboard/providusReport/ProvidusReportDetail";
import WaitList from "../pages/waitlist";
import BusinessUserManagementDetail from "../pages/user/BusinessUserManagementDetail";
import Application from "../pages/Application";
import ApplicationDetails from "../pages/Application/ApplicationDetails";
import ApplicationSummary from "../pages/Application/ApplicationSummary";
import BIPUsers from "../pages/bip";

export const publicRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

export const protectedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/user-management",
    element: <UserManagement />,
  },
  {
    path: "/waitlist",
    element: <WaitList />,
  },
  {
    path: "/bip",
    element: <BIPUsers />,
  },
  {
    path: "/withdrawal-request",
    element: <WithdrawalRequest />,
  },
  {
    path: "/account-summary",
    element: <AccountSummary />,
  },
  {
    path: "/account-summary/:id",
    element: <AccountSummaryDetail />,
  },
  {
    path: "/user-management/:id",
    element: <UserManagementDetail />,
  },
  {
    path: "/user-management-business/:id",
    element: <BusinessUserManagementDetail />,
  },
  {
    path: "/investment",
    element: <Investment />,
  },
  {
    path: "/investment/create",
    element: <CreateInvestment />,
  },
  {
    path: "/edit-investment/:id",
    element: <EditInvestment />,
  },
  {
    path: "/investment/:id",
    element: <InvestmentDetail />,
  },
  {
    path: "/user-management/:id",
    element: <UserManagementDetail />,
  },
  {
    path: "/transaction",
    element: <Transaction />,
  },
  {
    path: "/applications",
    element: <Application />,
  },
  {
    path: "/application-details/:id",
    element: <ApplicationDetails />,
  },
  {
    path: "/application-summary/:id",
    element: <ApplicationSummary />,
  },
  {
    path: "/previous-records",
    element: <PreviousRecords />,
  },
  {
    path: "/transaction/all",
    element: <AllTransaction />,
  },
  {
    path: "/transaction/:id",
    element: <TransactionDetail />,
  },
  {
    path: "/admin-email",
    element: <AdminEmail />,
  },
  {
    path: "/providus-report",
    element: <ProvidusReport />,
  },
  {
    path: "/providus-report/:id",
    element: <ProvidusReportDetail />,
  },

  {
    path: "/register",
    element: <Navigate to={"/"} />,
  },
  {
    path: "/Chart",
    element: <ChartOfAccount />,
  },

  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
