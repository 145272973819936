import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import AdminUserIcon from "../../components/Icons/AdminUserIcon";
import UserIcon from "../../components/Icons/UserIcon";
import Profile from "../../components/UserManagementDetails/Profile";
import CustomButton from "../../components/Buttons/Button";
import { Box } from "@mui/system";
import { Tab, Tabs } from "@mui/material";
import ApproveModal from "../../components/modal/ApproveModal";
import InvestorProfile from "../../components/UserManagementDetails/InvestorProfile";
import SubscriptionHistory from "../../components/UserManagementDetails/SubscriptionHistory";
import UserTransaction from "../../components/UserManagementDetails/UserTransaction";
import SubscriptionModal from "../../components/modal/SubscriptionModal";
import {
  getAllUser,
  getUserDetail,
} from "../../store/actions/userManagement/management";
import { Link, useParams } from "react-router-dom";
import Usermenu from "../../components/menus/Usermenu";
import AdminModal from "../../components/modal/AdminModal";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";
import FundWalletModal from "../../components/modal/FundWalletModal";
import { deleteUser } from "../../store/actions/userManagement/deleteUser";
import ResetTransactionPinModal from "../../components/modal/ResetTransactionPinModal";
import SettlementTable from "../../components/dashboard/user/SettlementTable";
import SetMasterPasswordModal from "../../components/modal/SetMasterPasswordModal";
import RemovetMasterPasswordModal from "../../components/modal/RemoveMasterPasswordModal";
import Loader from "../../components/SharedLayouts/Loader";
import BackArrow2 from "../../components/BackArrow2";
import InternalUserModal from "../../components/modal/InternalUserModal";

function UserManagementDetail() {
  const dispatch = useDispatch();
  // const allUser = useSelector((state) => state?.user?.allUsers) || [];
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showFundWalletModal, setShowFundWalletModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showInternalUSerModal, setShowInternalUSerModal] = useState(false);
  const [masterPassword, setMasterPassword] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");
  const [showResetPinModal, setShowResetPinModal] = useState(false);
  const [showRemoveMasterPasswordModal, setRemoveMasterPasswordModal] =
    useState(false);
  const [showSetMasterPasswordModal, setSetMasterPasswordModal] =
    useState(false);

  const user = useSelector((state) => state.onboarding.user);

  const { getUserPending, user: currentUser } = useSelector(
    (state) => state?.user
  );
  const userDetail = currentUser?.data;

  useEffect(() => {
    if (userDetail?.masterPassword) {
      setMasterPassword(true);
    } else {
      setMasterPassword(false);
    }
  }, [userDetail]);

  const { id } = useParams();

  const handleAdminUserClick = () => {
    setShowAdminModal(true);
    handleClose();
  };
  const handleInternalUserClick = () => {
    setShowInternalUSerModal(true);
    handleClose();
  };
  const handleRemoveMasterPassword = () => {
    setRemoveMasterPasswordModal(true);
    handleClose();
  };
  const handleSetMasterPassword = () => {
    setSetMasterPasswordModal(true);
    handleClose();
  };

  const handleResetPin = () => {
    setShowResetPinModal(true);
    handleClose();
  };

  const handleApproveUserClick = () => {
    setShowApproveModal(true);
    handleClose();
  };

  const handleFundWalletClick = () => {
    setShowFundWalletModal(true);
    handleClose();
  };

  const handleSubscriptionUserClick = () => {
    setShowSubscriptionModal(true);
    handleClose();
  };

  // const handleDeleteUserClick = () => {
  //   setShowDeleteModal(true);
  //   handleClose();
  // };

  const handleDelete = () => {
    dispatch(
      deleteUser({
        payload: userDetail?.id,
        callback: () => setShowDeleteModal(false),
      })
    );
  };

  const handleDeleteUserClick = () => {
    setShowDeleteModal(true);
    handleClose();
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabStyle = {
    backgroundColor: "#fff",
    width: "fit-content",
    // h: "32px",
    margin: "4px",

    textAlign: "center",
    // margin: "30px px",
    borderBottomColor: "transparent",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    textTransform: "capitalize",
    fontWeight: 600,
  };

  const generalStyle = {
    textTransform: "capitalize",
    fontSize: "14px",
    color: "#667085",
  };

  useEffect(() => {
    dispatch(getUserDetail({ id }));
  }, []);

  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <div className="flex items-center mb-8 flex-row md:items-center">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <Link
            to="/user-management"
            className="font-[500] text-[#475467] text-base hidden sm:flex"
          >
            User Management
          </Link>
          <Link to="/user-management">
            <img src="/Assets/userDot.png" alt="" className="w-8 h-8 md:ml-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 md:mr-2" />
          <span className="font-semibold text-[#475467] text-base bg-[#F9FAFB] p-1 rounded-md md:w-[100px]">
            User Detail
          </span>
        </div>

        <BackArrow2 mb={10} ml={0} />

        {/* <h1 className="mb-10 font-semibold text-2xl">User Details</h1> */}
        {getUserPending ? (
          <Loader />
        ) : (
          <>
            <div className="mb-4 md:mb-5">
              <img
                src="/Assets/image-wrap.png"
                alt=""
                className="w-full md:w-auto h-[50px] sm:h-auto"
              />
            </div>

            <div className="flex flex-col  relative">
              <div className="flex flex-col md:flex-row items-start md:items-center">
                <img src="/Assets/avatarr.png" alt="" className="w-28 " />

                <div className="flex flex-1  flex-wrap justify-between items-center ">
                  <div className="">
                    <div className="flex flex-wrap">
                      <span className=" text-lg font-[600]">
                        {userDetail?.name}
                      </span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="font-[400]">
                        UserID:{" "}
                        <span className="font-[500]">{userDetail?.id}</span>
                      </p>
                      <span className="bg-[#FFFAEB] text-[#B54708] rounded-2xl px-3 p-1 text-sm  ">
                        {userDetail?.selectedPlan === "free" ||
                        !userDetail?.selectedPlan
                          ? "Non Member"
                          : "Member"}
                      </span>

                      <span className="bg-[#EAECF0] text-[#344054] rounded-2xl px-3 p-1 text-sm ">
                        {userDetail?.free_approved_plan === "approved"
                          ? "Approved"
                          : "Pending Approval"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-3 sm:gap-5 items-center mt-4 md:mt-0 ">
                    {/* <div className=" md:mt-0 md:mr-3"> */}
                    {user?.roles &&
                      !user?.roles?.some(
                        (role) => role.name === "Admin_Tier_2"
                      ) && (
                        <CustomButton
                          variant="outlined"
                          customColor="#fff"
                          children={"Approve User"}
                          width="140px"
                          height="40px"
                          color="#159AA8"
                          borderColor="#d0d5dd"
                          padding="10px"
                          onClick={handleApproveUserClick}
                        />
                      )}

                    {user?.roles &&
                      !user?.roles?.some(
                        (role) =>
                          role.name === "Admin_Tier_1" ||
                          role.name === "Admin_Tier_2"
                      ) && (
                        <CustomButton
                          variant="outlined"
                          customColor="#fff"
                          children="Reset Pin"
                          width="100px"
                          height="40px"
                          color="#159AA8"
                          borderColor="#d0d5dd"
                          padding="10px"
                          onClick={handleResetPin}
                        />
                      )}

                    {user?.roles &&
                      !user?.roles?.some(
                        (role) => role.name === "Admin_Tier_2"
                      ) && (
                        <CustomButton
                          variant="contained"
                          customColor="#159AA8"
                          children="Edit Profile"
                          disabled={true}
                          width="110px"
                          height="40px"
                          borderColor="#159AA8"
                          padding="10px"
                          fz="14px"
                        />
                      )}
                    {/* </div> */}
                    <div
                      onClick={(e) => {
                        e.target.setAttribute("data-is-icon", "true");
                        handleContextMenu(e);
                      }}
                      className="bg-[#fff] w-10 h-10 cursor-pointer flex items-center justify-center rounded-md border border-[#D0D5DD]"
                    >
                      <img src="/Assets/more.png" alt="" className="w-4 h-4" />
                    </div>
                    <Usermenu
                      type={"main"}
                      selected={userDetail}
                      open={contextMenu !== null}
                      onClose={handleClose}
                      anchorReference="anchorPosition"
                      masterPassword={masterPassword}
                      anchorPosition={
                        contextMenu !== null
                          ? {
                              top: contextMenu.mouseY,
                              left: contextMenu.mouseX,
                            }
                          : undefined
                      }
                      handleClose={handleClose}
                      handleSubscriptionUserClick={handleSubscriptionUserClick}
                      handleAdminUserClick={handleAdminUserClick}
                      handleInternalUserClick={handleInternalUserClick}
                      handleSetMasterPassword={handleSetMasterPassword}
                      handleRemoveMasterPassword={handleRemoveMasterPassword}
                      handleFundWalletClick={handleFundWalletClick}
                      handleApproveUserClick={handleApproveUserClick}
                      handleDeleteUserClick={handleDeleteUserClick}
                      handleResetPin={handleResetPin}
                      componentsProps={{
                        root: {
                          onContextMenu: (e) => {
                            e.preventDefault();
                            handleClose();
                          },
                        },
                      }}
                      anchorEl={anchorEl}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Box
              sx={{
                backgroundColor: "#F9FAFB",
                mt: ["80px", "60px"],
                height: "54px",
                alignItems: "center",
                justifyContent: "center",
                width: "full",
              }}
              className="overflow-x-auto"
            >
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Profile"
                  value="profile"
                  sx={
                    activeTab === "profile"
                      ? { ...tabStyle, color: "#000" }
                      : generalStyle
                  }
                />
                <Tab
                  label="Investor's Profile"
                  value="investor"
                  sx={activeTab === "investor" ? tabStyle : generalStyle}
                />
                <Tab
                  label="Subscription History"
                  value="subscription"
                  sx={activeTab === "subscription" ? tabStyle : generalStyle}
                />
                <Tab
                  label="User Transaction History"
                  value="transactions"
                  sx={activeTab === "transactions" ? tabStyle : generalStyle}
                />
                <Tab
                  label="Settlements"
                  value="settlement"
                  sx={activeTab === "Settlement" ? tabStyle : generalStyle}
                />
              </Tabs>
            </Box>

            <div className="mt-2">
              {" "}
              {activeTab === "profile" && <Profile />}
              {activeTab === "investor" && <InvestorProfile />}
              {activeTab === "subscription" && <SubscriptionHistory />}
              {activeTab === "transactions" && <UserTransaction />}
              {activeTab === "settlement" && <SettlementTable />}
            </div>

            {showApproveModal && (
              <ApproveModal
                open={showApproveModal}
                userId={id}
                onClose={() => setShowApproveModal(false)}
                setShowApproveModal={setShowApproveModal}
              />
            )}
            {showSubscriptionModal && (
              <SubscriptionModal
                userId={id}
                open={showSubscriptionModal}
                onClose={() => setShowSubscriptionModal(false)}
                setShowSubscriptionModal={setShowSubscriptionModal}
              />
            )}
            {showFundWalletModal && (
              <FundWalletModal
                open={showFundWalletModal}
                userId={id}
                onClose={() => setShowFundWalletModal(false)}
                setShowFundWalletModal={setShowFundWalletModal}
              />
            )}

            {showAdminModal && (
              <AdminModal
                open={showAdminModal}
                user={userDetail}
                onClose={() => setShowAdminModal(false)}
                setShowAdminModal={setShowAdminModal}
              />
            )}
            {showInternalUSerModal && (
              <InternalUserModal
                open={showInternalUSerModal}
                user={userDetail}
                onClose={() => setShowInternalUSerModal(false)}
                setShowAdminModal={setShowInternalUSerModal}
              />
            )}
            {showDeleteModal && (
              <ConfirmDeleteModal
                open={showDeleteModal}
                handleDelete={handleDelete}
                onClose={() => setShowDeleteModal(false)}
              />
            )}

            {showResetPinModal && (
              <ResetTransactionPinModal
                open={showResetPinModal}
                userId={id}
                onClose={() => setShowResetPinModal(false)}
                setShowResetPinModal={setShowResetPinModal}
              />
            )}
            {showSetMasterPasswordModal && (
              <SetMasterPasswordModal
                open={showSetMasterPasswordModal}
                userId={id}
                onClose={() => setSetMasterPasswordModal(false)}
                setSetMasterPasswordModal={setSetMasterPasswordModal}
              />
            )}
            {showRemoveMasterPasswordModal && (
              <RemovetMasterPasswordModal
                open={showRemoveMasterPasswordModal}
                userId={id}
                onClose={() => setRemoveMasterPasswordModal(false)}
                setRemoveMasterPasswordModal={setRemoveMasterPasswordModal}
              />
            )}
          </>
        )}
      </div>
    </DashboardLayout>
  );
}

export default UserManagementDetail;
