import { createSlice } from "@reduxjs/toolkit";
import { getPortfolio } from "../actions/portfolio/getportfolio";
import { login } from "../actions/onboarding/login";

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState: {
    error: null,
    pending: false,
    success: false,
    portfolio: [],
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getPortfolio.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(getPortfolio.fulfilled, (state, action) => {
        state.pending = false;
        state.portfolio = action.payload;
      })
      .addCase(getPortfolio.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.pending = false;
        state.portfolio = action.payload.userInvestments;
      });
  },
});

export default portfolioSlice.reducer;
