import React, { useEffect } from "react";
import CustomTable from "./customTable";
import Loader from "../SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptionHistory } from "../../store/actions/userManagement/getSubscriptionHistory";
import { useParams } from "react-router-dom";
import ExportExcel from "../Export/ExportExcel";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";
import useCurrency from "../../hooks/useCurrency";
import GridTable from "./gridTable";

function SubscriptionTable() {
  const [_, getAmount] = useCurrency();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { subscriptionHistory, subscriptionHistoryDetails } = useSelector(
    (state) => state?.user
  );

  const loading = useSelector((state) => state?.user?.loading) || false;

  useEffect(() => {
    dispatch(fetchSubscriptionHistory(id));
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Subscription ID",
      width: 150,
      renderCell: (params) => params.row?.transaction_id,
    },
    {
      field: "plan",
      headerName: "Plan",
      width: 200,
      renderCell: (params) => params.row?.plan,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      renderCell: (params) =>
        getCurrencySymbol(params.row?.currency) + getAmount(params.row?.amount),
    },
    {
      field: "joiningDate",
      headerName: "Date Subscribed",
      width: 200,
      renderCell: (params) =>
        new Date(params.row?.joiningDate).toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "nextDateRenew",
      headerName: "Next Date To Renew",
      width: 200,
      renderCell: (params) =>
        new Date(params.row?.nextDateRenew).toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => params.row?.status,
    },
  ];

  return (
    <div style={{ height: "330px" }}>
      {loading ? (
        <Loader />
      ) : (
        <GridTable
          type={"subscription"}
          rows={subscriptionHistory}
          pageInfo={subscriptionHistoryDetails}
          columns={columns}
        />
      )}
    </div>
  );
}

export default SubscriptionTable;
