import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import CustomInput from "../inputs/CustomInput";
import { useState } from "react";
import { useFormik } from "formik";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ControlForm = ({ id, multiple, options, form, label }) => {
  return (
    <FormControl component="fieldset">
      <h4 className="font-[600] mb-2 text-[#101828] text-base">{label}</h4>
      <RadioGroup
        aria-label={`private-fund-${id}`}
        name={`private-fund-${id}`}
        id={id}
        value={form.values[id]}
        style={{ fontWeight: 500, fontSize: 14 }}
        onChange={(e) => {
          form.setFieldValue(id, e.target.value);
        }}
      >
        {options?.map((item, index) => (
          <FormControlLabel
            key={index}
            disabled
            value={item}
            control={
              <Radio
                sx={{
                  color: "#159AA8",
                  "&.Mui-checked": {
                    color: "#159AA8",
                  },
                }}
              />
            }
            label={item}
          />
        ))}
      </RadioGroup>
      {form?.errors[id] && (
        <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
          {form?.errors[id]}
        </div>
      )}
    </FormControl>
  );
};
const ControlCheckForm = ({ id, multiple, options, form, label }) => {
  return (
    <FormControl component="fieldset">
      <h4 className="font-[600] mb-2 text-[#101828] text-base">{label}</h4>
      <FormGroup
        aria-label={`private-fund-${id}`}
        name={`private-fund-${id}`}
        id={id}
        style={{ fontWeight: 500, fontSize: 14 }}
      >
        {options?.map((item, index) => {
          const itemName = item?.replaceAll(" ", "");

          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  disabled
                  sx={{
                    color: "#1C1C1C",
                    "&.Mui-checked": {
                      color: "#1C1C1C",
                    },
                  }}
                  checked={form.values[id]?.includes(item)}
                  name={itemName}
                />
              }
              label={item}
            />
          );
        })}
      </FormGroup>
      {!form?.values?.SMEFinancing &&
        !form?.values?.RealEstateInvesting &&
        !form?.values?.StartupInvesting && (
          <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
            Required
          </div>
        )}
    </FormControl>
  );
};

const InvestorProfile = () => {
  const userDetail = useSelector((state) => state?.user?.user?.data) || null;
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [selectedOption3, setSelectedOption3] = useState("");
  const [selectedOption4, setSelectedOption4] = useState("");
  const [textareaValue, setTextareaValue] = useState("");

  const navigate = useNavigate();

  const incomeSources = ["Salary", "Business Income", "Investment Income"];
  const incomeRanges = [
    "$50,000 and above",
    "$20,000  - $50,000",
    "$5,000 - $20,000",
    "Less than $5,000",
  ];
  const categories = [
    "SME Financing",
    "Real Estate Investing",
    "Startup Investing",
  ];
  const goals = [
    "Capital preservation",
    "Income generation",
    "Capital growth",
    "Others (please specify)",
  ];
  const ristTolerance = [
    "Very Conservative",
    "Conservative",
    "Moderately Conservative",
    "Balanced",
    "Moderately Aggressive",
    "Aggressive",
    "Very Aggressive",
    "I'm unsure",
  ];
  const hasPastInvestments = ["Yes", "No"];

  const proceedToMembership = () => {
    navigate("/onboarding/membership");
  };

  const form = useFormik({
    initialValues: {
      sourceOfIncome: "",
      incomeRange: "",
      yearly_invest: "",
      category_invest: "",
      investmentGoals: "",
      other_goals: "",
      pastInvestment: "",
      riskTolerance: "",
    },
    onSubmit: proceedToMembership,
  });

  useEffect(() => {
    if (userDetail?.profile?.investorProfile) {
      const investorProfile = userDetail?.profile?.investorProfile;
      form.setValues({
        sourceOfIncome: investorProfile.source_of_income,
        incomeRange: incomeRanges.find(
          (item, ind) => ind + 1 == investorProfile?.annual_income_range
        ),
        yearly_invest: Number(investorProfile?.capital_to_invest),
        category_invest: investorProfile?.investment_categories,
        SMEFinancing:
          investorProfile?.investment_categories?.includes("SME Financing"),
        RealEstateInvesting: investorProfile?.investment_categories?.includes(
          "Real Estate Investing"
        ),
        StartupInvesting:
          investorProfile?.investment_categories?.includes("Startup Investing"),
        investmentGoals: investorProfile?.investment_goals || "",
        other_goals: investorProfile?.other_investment_goals || "",
        pastInvestment: hasPastInvestments?.find(
          (item, ind) => ind == investorProfile?.previously_invested_ethical
        ),
        riskTolerance: ristTolerance.find(
          (item, ind) => ind + 1 == investorProfile?.risk_tolerance
        ),
      });
    }
  }, [userDetail?.profile?.investorProfile]);

  return (
    <div className="max-w-screen-lg p-4">
      <div className="pb-5 border-b border-b-[#EAECF0]">
        <ControlForm
          options={incomeSources}
          id={"sourceOfIncome"}
          form={form}
          label={" What is your main source of income?"}
        />
      </div>
      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          label={"What is your annual income range (equivalent of naira value)"}
          form={form}
          id={"incomeRange"}
          options={incomeRanges}
        />
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <h4 className="font-[600] mb-2 text-[#101828] text-base">
          How much capital can you comfortably invest in a year (equivalent of
          naira value)?
        </h4>
        <div className="w-full   ">
          <CustomInput
            fullWidth
            height="12px"
            disabled
            form={form}
            // icon="₦"
            prefix="$"
            customType="amount"
            // iconPosition={"left"}
            id="yearly_invest"
            placeholder={"Enter amount"}
            borderColor="#D0D5DD"
          />
        </div>
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlCheckForm
          label={
            "Select the category of investment (s) that you are mostly interested in?"
          }
          multiple
          form={form}
          id={"category_invest"}
          options={categories}
        />
      </div>
      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          label={"What are your primary investment goals?"}
          form={form}
          id={"investmentGoals"}
          options={goals}
        />

        {form.values?.investmentGoals === "Others (please specify)" ? (
          <div className="w-full   ">
            <CustomInput
              fullWidth
              height="10px"
              form={form}
              id="other_goals"
              placeholder={"Other goals"}
              borderColor="#D0D5DD"
            />
          </div>
        ) : null}
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          label={"Have you previously invested in ethical investments?"}
          form={form}
          id={"pastInvestment"}
          options={hasPastInvestments}
        />
      </div>

      <div className="py-5 border-b border-b-[#EAECF0]">
        <ControlForm
          label={"How would you describe your risk tolerance?"}
          form={form}
          id={"riskTolerance"}
          options={ristTolerance}
        />
      </div>

      <div className="py-5 flex justify-end items-center gap-3"></div>
    </div>
  );
};

export default InvestorProfile;
