import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import CustomInput from "../inputs/CustomInput";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../inputs/SelectField";
import { subscriptionOptions } from "../../config/data";
import HalDatePicker from "../inputs/HalDatePicker";
import { changeSubscription } from "../../store/actions/userManagement/manageSubscription";
import { useFormik } from "formik";
import { makeMemberSchema } from "../../Helper/validationSchemas";

function SubscriptionModal({ open, onClose, userId, setShowSubscriptionModal }) {
  const loading = useSelector((state) => state?.user?.loading);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      id: userId,
      subscription_type: values?.subscription_type?.value,
      payment_date: new Date(values.payment_date)
        .toISOString()
        .substring(0, 10),
    };
    
    dispatch(changeSubscription({ data, callback: () => setShowSubscriptionModal(false)}))
  };
  const form = useFormik({
    initialValues: {
      id: userId,
      subscription_type: "",
      amount: "",
      payment_date: null,
    },
    onSubmit: handleSubmit,
    validationSchema: makeMemberSchema,
  });
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-5" style={{ padding: "10px 20px" }}>
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div>
            <h1 className="font-semibold text-2xl mb-4">Manage Subscription</h1>

            <div className=" w-full items-center mr-4">
              <div className="mb-2">
                <label>Subscription Type</label>
                <div className="mb-4">
                  <SelectField
                    id="subscription_type"
                    form={form}
                    options={subscriptionOptions}
                    sx={{
                      width: "432px",
                      height: "48px",
                      borderRadius: "7px",
                      borderColor: "#D0D5DD",
                      color: "#101828",
                    }}
                    fw={400}
                    fz={"16px"}
                  />
                </div>
              </div>
              <div className="mt-8">
                <label>Amount paid</label>
                <CustomInput
                  width="432px"
                  height="16px"
                  type={'currency'}
                  id="amount"
                  form={form}
                  placeholder={"Enter amount paid here"}
                  borderColor="#D0D5DD"
                  borderRadius={"5px"}
                  pr="14px"
                />
              </div>

              <div className="flex mt-4">
                <div className="flex flex-col mr-4">
                  <label className="mb-2">Payment Date</label>

                  <div
                    style={{
                      border: "1px solid #D0D5DD",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "7px",
                      width: "100%",
                      maxWidth: "135px",
                      height: "50px",
                      borderRadius: "10px",
                    }}
                  >
                    <HalDatePicker
                      label="Date of Payment"
                      form={form}
                      id={"payment_date"}
                      rightImage
                    />
                  </div>
                </div>
              </div>

              <div className="flex mt-6 w-full items-center">
                <CustomButton
                  variant="outlined"
                  customColor="#fff"
                  children="Cancel"
                  width="100%"
                  onClick={onClose}
                  height="50px"
                  borderColor="#D0D5DD"
                  margin="0px 25px 0px 0px"
                  color="#159AA8"
                  iconWidth="22px"
                />

                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  loading={loading}
                  children="Save changes"
                  width="100%"
                  height="50px"
                  padding="10px"
                  onClick={form.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default SubscriptionModal;
