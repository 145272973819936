import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
 API_CHANGE_MEMBERSHIP_PLAN,
 
} from "../../../config/api";
import { getAllUser } from "./management";

export const changeSubscription = createAsyncThunk(
    "subscription/Change",
    async ({data, callback}, {dispatch, rejectWithValue }) => {
      try {
        const response = await Client({
          method: "POST",
          path: `${API_CHANGE_MEMBERSHIP_PLAN}${data.id}`,
          data
        });
        toast.success("User subscription changed successfully");
        callback()
        dispatch(getAllUser())
        return response?.data;
      } catch (error) {
   
        const msg = proccessErrorMessage(error);
        toast.error(msg);
        return rejectWithValue(msg);
      }
    }
  );
  