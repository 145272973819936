import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API_UPDATE_BVN, API_UPDATE_USER_PROFILE } from "../../../config/api";
import { closeModal, showSuccessModal } from "../../reducers/messageSlice";

export const updateUserProfile = createAsyncThunk(
  "userProfile/update",
  async (payload, { dispatch }) => {
    
    try {
      const response = await Client({
        method: "POST",
        path: API_UPDATE_USER_PROFILE,
        data: payload.data,
        contentType: "multipart/form-data",
        serializerConfig: { indexes: null },
      });
      dispatch(showSuccessModal("Profile updated"));
      // toast.success("Profile updated");
      dispatch(fetchUserProfile());
      payload.navigate("/onboarding/investor-profile");

      setTimeout(() => {
        dispatch(closeModal());
      }, 3000);

      return response;
    } catch (error) {
      toast.error(error?.response?.data?.errors?.BVN);
      payload.setSubmitting(false);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  "userProfile/get",
  async (payload) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_UPDATE_USER_PROFILE,
      });

     
      return response?.data;
    } catch (error) {
       toast.error(error?.response?.data?.errors?.BVN);
      payload.setSubmitting(false);
    }
  }
);
export const updateUserBvn = createAsyncThunk(
  "userBvn/update",
  async (payload, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_UPDATE_BVN,
        data: payload.data,
      });
      //  toast.success("BVN updated successfully");
      dispatch(showSuccessModal("BVN updated successfully"));

      setTimeout(() => {
        dispatch(closeModal());
      }, 3000);

      return response?.data;
    } catch (error) {
 
      toast.error(error?.response?.data?.errors?.BVN);
      payload.setSubmitting(false);
    }
  }
);
