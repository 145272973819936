import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import HomeIcon from "../Icons/HomeIcon";
import PortfolioIcon from "../Icons/PortfolioIcon";
import OpportunitiesIcon from "../Icons/OpportunitiesIcon";
import WalletIcon, { Account, Transactions } from "../Icons/WalletIcon";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/user/logout";
import PaymentIcon from "../Icons/PaymentIcon";
import ChartOFAccountIcon from "../Icons/ChartOFAccountIcon";
import { AnalyticsOutlined, EmailOutlined } from "@mui/icons-material";

const Sidebar = ({ handleCloseSideBar }) => {
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const user = useSelector((state) => state.onboarding.user);

  const sidebarItem = user?.roles?.some((role) => role.name === "Admin_Tier_2")
    ? [
        {
          id: 1,
          title: "Dashboard",
          link: "/",
          icon: <HomeIcon stroke={pathname === "/" ? "#159AA8" : "#344054"} />,
        },
        {
          id: 2,
          title: "Users",
          link: "/user-management",
          icon: (
            <PortfolioIcon
              stroke={
                pathname.startsWith("/user-management") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 10,
          title: "Waitlist",
          link: "/waitlist",
          icon: (
            <PortfolioIcon
              stroke={pathname.startsWith("/waitlist") ? "#159AA8" : "#344054"}
            />
          ),
        },
        {
          id: 3,
          title: "Investments",
          link: "/investment",
          icon: (
            <OpportunitiesIcon
              stroke={
                pathname.startsWith("/investment") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 4,
          title: "Previous Records",
          link: "/previous-records",
          icon: (
            <PaymentIcon
              stroke={
                pathname.startsWith("/previous-records") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 5,
          title: "Transactions",
          link: "/transaction",
          icon: (
            <Transactions
              stroke={
                pathname.startsWith("/transaction") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 5,
          title: "Applications",
          link: "/applications",
          icon: (
            <Transactions
              stroke={
                pathname.startsWith("/applications") ? "#159AA8" : "#344054"
              }
            />
          ),
        },

        {
          id: 8,
          title: "Admin Email",
          link: "/admin-email",
          icon: (
            <EmailOutlined
              className={
                pathname.startsWith("/admin-email")
                  ? "!fill-[#159AA8]"
                  : "!fill-[#344054]"
              }
            />
          ),
        },

        {
          id: 10,
          title: "Chart of Account",
          link: "/chart",
          icon: (
            <ChartOFAccountIcon
              className={
                pathname.startsWith("/providus-report")
                  ? "!fill-[#159AA8]"
                  : "!fill-[#344054]"
              }
            />
          ),
        },
      ]
    : [
        {
          id: 1,
          title: "Dashboard",
          link: "/",
          icon: <HomeIcon stroke={pathname === "/" ? "#159AA8" : "#344054"} />,
        },
        {
          id: 2,
          title: "Users",
          link: "/user-management",
          icon: (
            <PortfolioIcon
              stroke={
                pathname.startsWith("/user-management") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 10,
          title: "Waitlist",
          link: "/waitlist",
          icon: (
            <PortfolioIcon
              stroke={pathname.startsWith("/waitlist") ? "#159AA8" : "#344054"}
            />
          ),
        },
        {
          id: 11,
          title: "BIP",
          link: "/bip",
          icon: (
            <PortfolioIcon
              stroke={pathname.startsWith("/waitlist") ? "#159AA8" : "#344054"}
            />
          ),
        },
        {
          id: 3,
          title: "Investments",
          link: "/investment",
          icon: (
            <OpportunitiesIcon
              stroke={
                pathname.startsWith("/investment") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 4,
          title: "Previous Records",
          link: "/previous-records",
          icon: (
            <PaymentIcon
              stroke={
                pathname.startsWith("/previous-records") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 5,
          title: "Transactions",
          link: "/transaction",
          icon: (
            <Transactions
              stroke={
                pathname.startsWith("/transaction") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 5,
          title: "Applications",
          link: "/applications",
          icon: (
            <Transactions
              stroke={
                pathname.startsWith("/applications") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 6,
          title: "Withdrawal Requests",
          link: "/withdrawal-request",
          icon: (
            <WalletIcon
              stroke={
                pathname.startsWith("/withdrawal-request")
                  ? "#159AA8"
                  : "#344054"
              }
            />
          ),
        },
        {
          id: 7,
          title: "Account Summary",
          link: "/account-summary",
          icon: (
            <Account
              stroke={
                pathname.startsWith("/account-summary") ? "#159AA8" : "#344054"
              }
            />
          ),
        },
        {
          id: 8,
          title: "Admin Email",
          link: "/admin-email",
          icon: (
            <EmailOutlined
              className={
                pathname.startsWith("/admin-email")
                  ? "!fill-[#159AA8]"
                  : "!fill-[#344054]"
              }
            />
          ),
        },
        {
          id: 9,
          title: "Providus Report",
          link: "/providus-report",
          icon: (
            <AnalyticsOutlined
              className={
                pathname.startsWith("/providus-report")
                  ? "!fill-[#159AA8]"
                  : "!fill-[#344054]"
              }
            />
          ),
        },
        {
          id: 10,
          title: "Chart of Account",
          link: "/chart",
          icon: (
            <ChartOFAccountIcon
              className={
                pathname.startsWith("/providus-report")
                  ? "!fill-[#159AA8]"
                  : "!fill-[#344054]"
              }
            />
          ),
        },

        // // {
        //   id: 5,
        //   title: "Notifications",
        //   link: "/support",
        //   icon: (
        //     <NotificationIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />
        //   ),
        // },
        // {
        //   id: 6,
        //   title: "Payments",
        //   link: "/settings/profile",
        //   icon: <PaymentIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
        // },
        // {
        //   id: 7,
        //   title: "Support",
        //   link: "/settings/profile",
        //   icon: <SupportIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
        // },
        // {
        //   id: 8,
        //   title: "Analytics",
        //   link: "/settings/profile",
        //   icon: <AnalyticsIcon fillColor={pathname === "/" ? "#159AA8" : "#fff"} />,
        // },
      ];

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="main-sidebar z-10 relative h-full w-full">
      <div>
        <div
          className="flex lg:ml-8 justify-between  mt-8 mb-10 px-4"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/Assets/logo.png"
            alt="logo"
            width="133px"
            height="37px"
            className="max-w-max"
          />

          <AiOutlineClose
            onClick={handleCloseSideBar}
            className="cursor-pointer text-[#159AA8] lg:hidden"
            size={26}
          />
        </div>
        <div className="flex h-full pb-8 lg:pb-0 flex-col w-full">
          <ul className="main-sidebar__nav flex-1 list-type-[none]">
            {sidebarItem?.map(({ title, id, icon, link }) => (
              <NavLink key={id} to={link}>
                <li
                  className={`main-sidebar__item mb-2 ${
                    id !== 1 &&
                    pathname.startsWith(link) &&
                    "main-sidebar__active"
                  } ${
                    id === 1 && pathname === link && "main-sidebar__active"
                  }  hover:bg-#E4F9FC`}
                >
                  <div className="flex items-center">
                    <div>{icon}</div>
                    <span className="list-text ">{title}</span>
                  </div>
                </li>
              </NavLink>
            ))}
            <li
              onClick={handleLogout}
              className={`main-sidebar__item hover:!bg-[#FEF3F2]`}
            >
              <div className="flex items-center">
                <span className="list-text !text-[#B42318]">Logout</span>
              </div>
            </li>
          </ul>
        </div>

        <hr className="border-t-2 border-gray-200 m-5" />
        <div className="flex items-center px-4 relative justify-between w-full">
          <div className="p-2 bg-[#F2F4F7] rounded-full flex items-center justify-center text-#667085 mr-2">
            AR
          </div>

          <div className="text-sm  mr-3">
            <h4>{user?.name}</h4>
            <h6 className="!text-[12px] break-all">{user?.email}</h6>
          </div>

          <img src="/Assets/logout.png" alt="" className="w-4 h-4 mr-4 " />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
