import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useCurrency from "../../hooks/useCurrency";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";
const InvestDetailCard = ({
  title,
  currency,
  cardValue,
  width,
  height,
  iconColor,
  cardContent,
}) => {
  const iconStyle = {
    width: "48px",
    height: "48px",
    backgroundColor: iconColor,
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    marginBottom: "40px",
  };
  const [_currency, getAmount] = useCurrency();
  return (
    <Card
      sx={{
        width: width,
        height: height,
        borderRadius: "12px",
        border: "0.8px solid #D0D5DD",
      }}
      // className="shadow"
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <span>{title}</span>

            {cardValue !== undefined && cardValue !== null && (
              <Typography
                sx={{ mb: 1.5 }}
                color="text.secondary"
                fontWeight={600}
                fontSize={18}
              >
                {`${getCurrencySymbol(currency)}${getAmount(cardValue, 2)}`}
              </Typography>
            )}
            {cardContent && (
              <Typography color="text.secondary" fontWeight={600} fontSize={18}>
                {cardContent}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InvestDetailCard;
