import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../../Helper/getCurrencySymbol";
import useCurrency from "../../../hooks/useCurrency";
import Loader from "../../SharedLayouts/Loader";

function InvestmentInterestTable({ allUser }) {
  const [activeFilter, setActiveFilter] = useState("all");
  const investment = useSelector((state) => state?.investment?.investment);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showPopper, setShowPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [, getAmount] = useCurrency();
  const pending = useSelector((state) => state?.investment?.pending);

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";
    setSelectedUserId(params?.row?.id);

    if (!isIconClick) {
      setSelectedUserId(params?.row?.id);
      navigate(`/investment/${params?.row?.id}`);
    }
  };

  const handleClosePopper = () => {
    setShowPopper(false);
  };

  const navigate = useNavigate();

  const handleClosePopover = () => {
    setShowOptions(false);
  };

  const rows =
    allUser?.map((user) => ({
      id: user?.id,
      firstName: user?.name?.split(" ")[0],
      lastName: user?.name?.split(" ")[1],
      email: user?.email,
      amount:
        getCurrencySymbol(investment?.currency) + " " + getAmount(user.amount),
      status: user?.status,
    })) || [];

  const columns = [
    {
      field: "id",
      headerName: "Member ID",
      width: 150,
    },

    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
  ];

  const filteredRows =
    activeFilter === "all"
      ? rows
      : rows
          ?.map((row) => ({
            ...row,
            id: row?.id,
          }))
          .filter((row) => row?.status?.toLowerCase() === activeFilter);

  return (
    <div className="mt-10 mb-8">
      <div className="flex justify-between mb-8">
        {/* <div
          className="border p-2 rounded-lg shadow-sm bg-white w-12"
          style={{ width: "97px", cursor: "pointer" }}
          onClick={clearTable}
        >
          <div className="flex items-center ">
            <img
              src="/Assets/filter-lines.png"
              alt=""
              className="w-4 h-6 mr-2"
            />
            <span className="font-[600]">Filters</span>
          </div>
        </div> */}
      </div>
      <div className="bg-white ">
        <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
          {pending ? (
            <Loader />
          ) : filteredRows?.length < 1 ? (
            <div className="  p-5 text-center">No data</div>
          ) : (
            <GridTable
              type={"usersIntrested"}
              rows={filteredRows}
              columns={columns}
              handleRowClick={(params, event) => handleRowClick(params, event)}
            />
          )}
        </Box>
      </div>
    </div>
  );
}

export default InvestmentInterestTable;
