import React from "react";
import WithdrawalTable from "../../components/dashboard/withdrawal/WithdrawalTable";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import Loader from "../../components/SharedLayouts/Loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const WithdrawalRequest = () => {
  const loading = useSelector((state) => state.portfolio.pending) || false;
  const user = useSelector((state) => state.onboarding.user);
  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <div className="flex items-center mb-8 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <span className="font-semibold text-[#475467]text-sm sm:text-base bg-[#F9FAFB] p-1 rounded-md  w-[max-content]">
            Withdrawal
          </span>
        </div>
        <h1 className="mb-6 font-[500] text-xl md:text-2xl lg:text-3xl">
          Withdrawal Requests
        </h1>
        {loading && <Loader />}

        <div className="mt-6">
          {user?.roles &&
            !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
              <WithdrawalTable />
            )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WithdrawalRequest;
