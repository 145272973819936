import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../SharedLayouts/Loader";
import WaitListMenu from "../../menus/WaitListMenu";
import { getBIPUsers } from "../../../store/actions/userManagement/getBIPUsers";

const BIPUsersList = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [masterPassword, setMasterPassword] = useState(false);

  const { BIPUsers, allUsersPending } = useSelector((state) => state?.user);

  const handleContextMenu = (event, row) => {
    event.preventDefault();

    setMasterPassword(row?.masterPassword);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "User ID",
    },

    {
      field: "email",
      headerName: "Email",
      width: 650,
    },
  ];

  useEffect(() => {
    return () => {
      setAnchorEl(null);
    };
  }, [anchorEl]);

  useEffect(() => {
    dispatch(getBIPUsers());
  }, []);

  return (
    <div className="mt-10 mb-8">
      <div className="bg-white py-6 rounded-lg ">
        {!allUsersPending || true ? (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              rows={BIPUsers}
              columns={columns}
              type={"bip"}
              // searchPlaceholder={"Search for users"}
              getRowId={(row) => row.id}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              // handleRowClick={handleRowClick}
            />
          </Box>
        ) : (
          <div className="h-10">
            <Loader />
          </div>
        )}
      </div>
      <WaitListMenu
        handleView={() => {}}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        handleClose={handleClose}
        masterPassword={masterPassword}
        // onMenuSelect={(e, row) => handleContextMenu(e,row)}

        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default BIPUsersList;
