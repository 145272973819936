import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
 API_FETCH_USER_TRANSACTIONS, API_GET_ALL_TEMPLATES,
 
} from "../../../config/api";

export const getAllTemplates = createAsyncThunk(
    "template/getAll",
    async (data, { rejectWithValue }) => {
      try {
        const response = await Client({
          method: "GET",
          path: `${API_GET_ALL_TEMPLATES}`,
         });
          return response?.data?.data;
      } catch (error) {
   
        const msg = proccessErrorMessage(error);
        toast.error(msg);
        return rejectWithValue(msg);
      }
    }
  );
  