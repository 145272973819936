import React from "react";

function NotificationIcon() {
  return (
    <div>
      <img
        src={"/Assets/notification.png"}
        alt="Notification Icon"
        style={{ width: "22px", height: "22px" }}
        className={`w-4 h-4
         }`}
      />
    </div>
  );
}

export default NotificationIcon;
