import React from "react";

function ActiveIcon() {
  return (
    <div>
      <img
        src={"/Assets/active.png"}
        alt="Active Icon"
        style={{ width: "24px", height: "24-px" }}
      />
    </div>
  );
}

export default ActiveIcon;
