import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { fetchAllWithdrawalReqs } from "./fetchAllWithdrawalReqs";
import { API_CANCEL_WITHDRAWAL_REQUEST } from "../../../config/api";

export const cancelWithdrawalReqs = createAsyncThunk(
  "cancelWithdrawalRequest",
  async (payload, {dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_CANCEL_WITHDRAWAL_REQUEST}${payload?.withdrawal_request_id}`,
       
      });
      toast.success("Withdrawal Request Cancelled");
      payload?.callback()
      dispatch(fetchAllWithdrawalReqs());

      return response?.data?.data;
    } catch (error) {
      payload?.callback()
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(msg);
    }
  }
);
