import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFetchedData,
  setIsLoading,
  setPaginationModel,
  setRows,
  setSearchRows,
  setSearchWord,
} from "../../store/reducers/dashboardSlice";
import { Client } from "../../client";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../components/SharedLayouts/Loader";

const RecordGrid = ({
  type,
  pageInfo,
  rows = [],
  newrows = [],

  columns,
  noReport,
  searchPlaceholder,
  handleRowClick,
  activeFilter,
  noSearch,
  disableRowSelectionOnClick = true,
  ...props
}) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const dispatch = useDispatch();
  const investment = useSelector((state) => state?.investment?.investment);
  const {
    isLoading,
    fetchedData,
    paginationModel,
    _rows,
    searchWord,
    searchRows,
  } = useSelector((state) => state?.dashboard);

  console.log(_rows, "_row");

  const { userFilter, investmentFilter } = useSelector(
    (state) => state?.dashboard
  );
  let timeout = useRef();

  const serverSearching = async ({ url }) => {
    try {
      console.log("old");
      dispatch(setIsLoading(true));
      const response = await Client({
        method: "GET",
        path: url,
        type: "full-path",
      });

      dispatch(setIsLoading(false));
      if (response?.data && response?.data?.length > 0) {
        return response?.data;
      } else {
        return null;
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      toast.error("Error Fetching Data");
    }
  };
  const loadNewData = async ({ url }) => {
    try {
      console.log("new");

      dispatch(setIsLoading(true));
      const response = await Client({
        method: "GET",
        path: url,
        type: "full-path",
      });

      if (response?.data?.data && response?.data?.data?.length > 0) {
        dispatch(setFetchedData([...fetchedData, ...response?.data?.data]));
      }

      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      toast.error("Error Fetching Data");
    }
  };

  useEffect(() => {
    if (pageInfo?.total) {
      dispatch(
        setFetchedData(fetchedData?.length > 0 ? fetchedData : pageInfo?.data)
      );

      // setRows(pageInfo?.data);
    }
  }, [pageInfo, fetchedData]);

  useEffect(() => {
    let filterRow = [];
    if (pageInfo?.total && fetchedData) {
      const newRows = [...fetchedData]?.slice(
        paginationModel?.page
          ? paginationModel?.page * paginationModel?.pageSize
          : 0,
        paginationModel?.page
          ? paginationModel?.page * paginationModel?.pageSize +
              paginationModel?.pageSize
          : paginationModel?.pageSize
      );

      if (
        newRows?.length < paginationModel?.pageSize &&
        fetchedData.length < pageInfo?.total
      ) {
        console.log(
          newRows?.length < paginationModel?.pageSize,
          fetchedData.length < pageInfo?.total
        );

        loadNewData({ url: pageInfo?.links[paginationModel?.page + 1]?.url });

        return;
      } else {
        console.log("case 1");

        filterRow = newRows;
      }
    } else {
      console.log("case 2");
      filterRow = [...rows]?.slice(
        paginationModel?.page
          ? paginationModel?.page * paginationModel?.pageSize
          : 0,
        paginationModel?.page
          ? paginationModel?.page * paginationModel?.pageSize +
              paginationModel?.pageSize
          : paginationModel?.pageSize
      );
      // setRows(
      //   [...rows]?.slice(
      //     paginationModel?.page
      //       ? paginationModel?.page * paginationModel?.pageSize
      //       : 0,
      //     paginationModel?.page
      //       ? paginationModel?.page * paginationModel?.pageSize +
      //           paginationModel?.pageSize
      //       : paginationModel?.pageSize
      //   )
      // );
    }

    console.log("case 3", filterRow);
    dispatch(setRows([...filterRow]));
  }, [
    rows,
    paginationModel,
    pageInfo,
    fetchedData,
    userFilter,
    investmentFilter,
  ]);

  const getDisbursedAmount = (amount) => {
    const payment_type = investment?.paymentSchedules?.payment_type;
    const roi = Number(investment?.roi);
    const duration = investment?.duration;
    const profit = (roi / 100) * amount;
    if (payment_type === "PACM") {
      const repayment = 0;
    }

    return 0;
  };
  const processRowUpdate = (newRow) => {
    const disbursed_amount = getDisbursedAmount(newRow.amount);
    const updatedRow = {
      ...newRow,
      isNew: false,
      disbursed_amount,
    };
    setAllUsers(_rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const rowCountRef = React.useRef(pageInfo?.totalRowCount || 0);

  const onRowsSelectionHandler = (ids) => {
    setAllUsers(
      _rows?.map((row) =>
        ids.includes(row?.id)
          ? { ...row, selected: true }
          : { ...row, selected: false }
      )
    );
  };

  const searching = async (value) => {
    clearTimeout(timeout.current);
    dispatch(setSearchWord(value));

    let searchResponse = null;

    if (
      value?.length > 1 &&
      !_rows?.some((row) => {
        return (
          row?.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
          row?.name?.toLowerCase()?.includes(value?.toLowerCase())
        );
      })
    ) {
      timeout.current = setTimeout(async () => {
        searchResponse = await serverSearching({
          url: `https://api.halvestco.com/api/v1/Management/user/search?searchTerm=${value}`,
        });
        console.log(searchResponse, "searchResponse");

        dispatch(setSearchRows(searchResponse));
      }, 1500);
    } else {
      clearTimeout(timeout.current);

      dispatch(setSearchRows(null));
    }
  };

  console.log(_rows, "jhghjk");

  const rowCount = React.useMemo(() => {
    if (pageInfo?.total !== undefined) {
      rowCountRef.current = pageInfo.total;
    }
    return rowCountRef.current || rows?.length || 0;
  }, [pageInfo?.total, rows?.length]);

  const [allUsers, setAllUsers] = useState(_rows);
  useEffect(() => {
    return clearTimeout(timeout.current);
  }, [timeout]);

  console.log(columns, "columns");

  const isUserLoading =
    useSelector((state) => state?.user?.allUsersPending) || false;

  return (
    <>
      <div style={{ height: 555 }}>
        <label>Select Users</label>

        <input
          height="16px"
          id="user_id"
          type={"text"}
          placeholder={"Search"}
          onChange={(e) => searching(e.target.value)}
          className="rounded-[5px] border border-[#D0D5DD]  w-full px-[10px] py-[10px] mb-5"
        />

        <div className="flex overflow-x-auto w-full h-[555px]">
          <div className="flex overflow-x-auto w-full h-[555px]">
            {!isUserLoading ? (
              <div>
                <DataGrid
                  pageSize={10}
                  pageSizeOptions={[50]}
                  hideFooterPagination={isLoading || searchRows}
                  rowCount={rowCount}
                  loading={isLoading}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={(value) =>
                    dispatch(setPaginationModel(value))
                  }
                  rows={
                    searchRows && searchWord?.length > 1
                      ? searchRows?.map((item) => ({
                          id: item?.id,
                          user_id: item?.id,
                          name: item?.name,
                          amount: 0,
                          email: item?.email,
                          disbursed_amount: 0,
                          selected: false,
                          payment_date: new Date(),
                        }))
                      : _rows
                          ?.filter((user) =>
                            user?.name
                              ?.toLowerCase()
                              ?.includes(searchWord?.toLowerCase())
                          )
                          ?.map((item) => ({
                            id: item?.id,
                            user_id: item?.id,
                            name: item?.name,
                            amount: 0,
                            email: item?.email,
                            disbursed_amount: 0,
                            selected: false,
                            payment_date: new Date(),
                          })) || []
                  }
                  columns={columns || []}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 50,
                      },
                    },
                  }}
                  editMode="row"
                  keepNonExistentRowsSelected
                  processRowUpdate={processRowUpdate}
                  rowSelectionModel={selectionModel}
                  onRowSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                    onRowsSelectionHandler(ids);
                  }}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </div>
            ) : (
              <p className="w-full m-auto mt-5">
                <Loader />
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordGrid;
