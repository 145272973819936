import React from "react";
import { colors } from "../../config/constant";

const Button = ({
  title,
  bgColor = colors.secondaryColor,
  color = colors.primaryColor,
  icon,
  width,
  disabled,
  type,
  children,
  loading,
  fs = "16px",
  pt = "17px",
  pb = "17px",
  pl = "16px",
  pr = "16px",
  style,
  rightIcon,
  ...props
}) => {
  const ButtonStyle = {
    backgroundColor:
      type === "outline"
        ? "transparent"
        : disabled
        ? colors.halLightGray
        : bgColor,
    color: type === "outline" ? bgColor : disabled ? colors.halGray : color,

    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: type === "outline" ? bgColor : "transparent",
    paddingTop: pt,
    paddingBottom: pb,
    paddingLeft: pl,
    paddingRight: pr,
    fontSize: fs,
    cursor: disabled ? "not-allowed" : "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: width !== undefined ? width : "",
    borderRadius: 8,
  };

  return (
    <button
      {...props}
      disabled={disabled}
      style={{ ...ButtonStyle, ...style }}
      type={type}
    >
      {icon && (
        <img
          width="24px"
          style={{ marginRight: 10 }}
          height="24px"
          alt="Button Icon"
          src={icon}
        />
      )}
      {loading ? (
        <img
          width="24px"
          height="24px"
          alt="Button Icon"
          src={"/Assets/loading.gif"}
        />
      ) : (
        <>
          {title}
          {rightIcon && rightIcon}
        </>
      )}
    </button>

    // <MuiButton variant="contained" disableElevation>
    //   Disable elevation
    // </MuiButton>
  );
};
export default Button;
