import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import { Link } from "react-router-dom";
import Adminmail from "../../components/dashboard/AdminEmail/AdminEmail";
import { useDispatch } from "react-redux";
import { getAllTemplate } from "../../store/actions/userManagement/sendEmail";
import { getAllTemplates } from "../../store/actions/userManagement/getAllTemplates";

const AdminEmail = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <div className="flex items-center mb-8">
        <Link to={"/dashboard"}>
          <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
        </Link>
        <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
        <Link
          // to={"/investment"}
          className="font=[500] text-[#475467] text-base hidden sm:flex"
        >
          Draft Email
        </Link>
      </div>

      <Adminmail />
    </DashboardLayout>
  );
};

// const modules = {
//     toolbar: {
//         container: [
//             ['bold', 'italic', 'underline', 'strike'],
//             [{ 'color': [] }, { 'background': [] }],
//             [{ 'align': [] }],
//             [{ 'list': 'ordered' }, { 'list': 'bullet' }],
//             [{ 'indent': '-1' }, { 'indent': '+1' }],
//             ['blockquote', 'code-block'],
//             ['link', 'image', 'video', 'attachment'],
//             ['clean'],
//             ['html'],
//             [{ 'undo': true }, { 'redo': true }]

//         ],

//     },
//     counter: {
//         container: '#counter',
//         unit: 'character'
//     },

//     clipboard: {
//         matchVisual: false,
//     },
//     keyboard: {
//         bindings: {
//             // Example of custom keyboard shortcut (Ctrl + S for save)
//             save: {
//                 key: 'S',
//                 ctrlKey: true,
//                 handler: function () {
//                     // Implement save functionality here

//                     return false; // Return false to prevent default behavior
//                 }
//             }
//         }
//     },

// };

// const formats = [
//     'header', 'font', 'size',
//     'bold', 'italic', 'underline', 'strike', 'blockquote',
//     'list', 'bullet', 'indent',
//     'link', 'image', 'video'
// ];

export default AdminEmail;
