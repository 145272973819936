import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const UserCard = ({
  icon,
  loading,
  title,
  cardValue,
  width,
  height,
  iconColor,
}) => {
  const iconStyle = {
    width: "48px",
    height: "48px",
    backgroundColor: iconColor,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "12px",
    marginBottom: "10px",
  };

  return (
    <Card
      sx={{
        width: width,
        height: height,
        border: "0.8px solid #D0D5DD",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        paddingLeft: "8px",
      }}
    >
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <div style={iconStyle}>{icon}</div>
              <Typography
                variant="h5"
                component="div"
                fontSize={12}
                width={"max-content"}
              >
                {title}
              </Typography>
            </Box>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: 1.5 }}
                color="text.secondary"
                fontWeight={600}
                fontSize={20}
              >
                {loading ? "- -" : cardValue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserCard;
