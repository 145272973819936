import React from "react";
import SubscriptionTable from "../table/SubscriptionTable";

function SubscriptionHistory() {
  return (
    <div className="mt-6">
      <SubscriptionTable />
    </div>
  );
}

export default SubscriptionHistory;
