import React, { useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { protectedRoutes, publicRoutes } from "./config/routes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./muiTheme";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./components/modal/MessageModal";
import MessageModal from "./components/modal/MessageModal";
import { logout } from "./store/actions/user/logout";
import { logoutUser } from "./store/reducers/onboardingSlice";
import { Cookies } from "react-cookie";

function App() {
  const user = useSelector((state) => state.onboarding.user);

  const cookies = new Cookies();
  const isAccessTokenAvailable = cookies.get("accessToken");

  const isModalOpened = useSelector((state) => state?.message?.isModalOpened);
  const dispatch = useDispatch();

  const modalText = useSelector((state) => state?.message?.modalText);
  const success = useSelector((state) => state?.message?.success);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider
        router={
          isAccessTokenAvailable && user?.user_id
            ? protectedRoutes
            : publicRoutes
        }
      />
      {isModalOpened && <MessageModal />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        ltr={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        hideProgressBar={true}
      />
    </ThemeProvider>
  );
}

export default App;
