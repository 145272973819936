import React from "react";

import Button from "../inputs/Button";
import TextInput from "../inputs/TextInput";
import { formHandler } from "../../Helper/formHandler";
import FormInput from "../Formik/FormInput";
import FormSelect from "../Formik/FormSelect";
import { _countries, formatArray, stateOptions } from "../../config/data";
import { useSelector } from "react-redux";

const AddressFormDetail = ({ form }) => {
  const { pending } = useSelector((state) => state.user);
  return (
    <div className="w-[650px] lg:w-[800px] mt-10">
      <div className="w-full border-b-[1px] mb-8 pb-2 border-b-[#F0F0F0] ">
        <h3 className=" text-base font-[500] text-[#333333] ">
          Residential Details
        </h3>
      </div>
      <div className="flex mt-6 gap-4 flex-col">
        <div className="flex gap-4 flex-wrap">
          <div style={{ flex: 1 }}>
            <TextInput
              label="Resident Number"
              type="text"
              disabled={true}
              {...formHandler("residentialNumber", form)}
              noMargin
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextInput
              label="Street Name"
              type="text"
              disabled={true}
              {...formHandler("streetName", form)}
              noMargin
            />
          </div>
        </div>

        <div className="flex gap-4 flex-wrap">
          <div className="w-full sm:w-auto sm:flex-1">
            <TextInput
              label="City"
              type="text"
              disabled={true}
              {...formHandler("city", form)}
              noMargin
            />
          </div>
          {/* <div className="w-full sm:w-auto sm:flex-1">
            <FormSelect
              form={form}
              id={"state"}
              label="State"
              options={stateOptions}
            />
          </div> */}
        </div>

        <div className="flex gap-4 flex-wrap">
          <div className="w-full sm:w-auto sm:flex-1">
            <FormSelect
              disabled={true}
              form={form}
              id={"country"}
              label="Country"
              options={_countries}
            />
          </div>
          <div style={{ flex: 1 }}>
            <FormInput
              label="ZIP Code"
              type="text"
              {...formHandler("zipCode", form)}
              noMargin
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <Button
          type={"button"}
          onClick={""}
          className="mt-3"
          title={"Save changes"}
          loading={""}
          disabled={true}
          pt="12px"
          pb="12px"
          pl="16px"
          pr="16px"
          width={"162px"}
          style={{ backgroundColor: "#F0F0F0", color: "#000" }}
        />
      </div>
    </div>
  );
};

export default AddressFormDetail;
