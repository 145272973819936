import React, { useState } from "react";

const TextInput = ({
  label,

  placeholder,
  type,
  id,
  leftImage,
  rightImage,
  disabled,
  value,
  form,
  blackBorder,
  noMargin,
  classNameProp,
  handleChangeBySelf,
  autoFucus,
}) => {
  return (
    <div
      className={`input-container py-[5px] ${
        blackBorder || value ? "input-border" : ""
      }
       ${leftImage || rightImage ? "items-center" : ""} 
       ${noMargin && "file-margin"} ${classNameProp} h-[58px]`}
    >
      {leftImage && (
        <img src={leftImage} alt="lock" width="24px" height="24px" />
      )}
      <div style={{ margin: leftImage && "0 16px" }}>
        <label htmlFor={id}>{label}</label>

        <input
          type={type}
          name={id}
          autoFocus={autoFucus}
          value={value || form?.values[id]}
          id={id}
          placeholder={placeholder}
          className={`text-field bg-white  ${
            (leftImage || rightImage) && "w-[98%]"
          }`}
          disabled={disabled}
          onBlur={form?.onBlur}
          onChange={form?.handleChange ? form?.handleChange : null}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default TextInput;
