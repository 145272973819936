import { ChevronRight } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import CustomButton from "../../Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAllTemplate } from "../../../store/actions/userManagement/sendEmail";
// import './Index.css'

const Templates = ({
  selectedTemplate,
  handleInsertTemplate,
  setOpenTemplate,
  templates,
}) => {
  const [selectedIndex, setselectedIndex] = useState(selectedTemplate);
  const [content, setContent] = useState(
    templates[selectedIndex]?.template_content
  );
  const dispatch = useDispatch();

  const handleChange = (value) => {
    setContent(value);
  };

  const delta = htmlToDelta(content);

  return (
    <>
      <div className="flex flex-row gap-10 ">
        <div
          className="flex-[1] pr-[20px] min-w-[400px] w-[max-content]"
          style={{
            borderBottom: "1.5px solid #E7E7E7",
            borderLeft: "1.5px solid #E7E7E7",
            p: "10px 23px !important",
          }}
        >
          <h4 className="text-[24px] mt-5 ">
            {templates[selectedIndex]?.template_title}
          </h4>

          <div className="py-[20px] px-[50px] flex flex-col gap-[21px] text-[#121212] ">
            <ReactQuill
              value={delta}
              className="template-editor "
              onChange={handleChange}
              modules={{
                clipboard: {
                  matchVisual: false, // Disable visual matching to prevent sanitization
                },
                toolbar: [
                  // [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                  // [{ size: [] }],
                  // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  // [{ 'list': 'ordered' }, { 'list': 'bullet' },
                  // { 'indent': '-1' }, { 'indent': '+1' }],
                  ["link", "image", "video"],
                  // ['clean']
                ],
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
            />
            <div>
              {/* <textarea name='templateContent' value={
                                    templateContent
                                } onChange={handleInsertTemplate} className='w-[100%] h-[320px] text-[12px] text-start' /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end w-[100%] my-[20px] gap-[20px] mr-[20px] pr-[20px]">
        {/* <Button type={'button'} text={'Cancel'} className={' border border-[grey]  w-[max-content] bg-transparent text-[grey]'} onClick={() => handleInsertTemplate()} /> */}

        <CustomButton
          variant="outlined"
          customColor={"transparent"}
          color="#159AA8"
          children="Cancel"
          width="160px"
          height="50px"
          padding="10px"
          borderColor={"#159AA8"}
          className={"!border !shadow-none "}
          onClick={() => setOpenTemplate(false)}
        />

        <CustomButton
          variant="contained"
          customColor="#159AA8"
          children="Insert Template"
          width="160px"
          height="50px"
          padding="10px"
          onClick={() => handleInsertTemplate(content)}
        />
      </div>
    </>
  );
};

export default Templates;

// export function htmlToDelta(html) {
//     const div = document.createElement('div');
//     div.innerHTML = `<div id="quillEditor" style="display:none">${html}</div>`;
//     document.body.appendChild(div);

//     // Initialize Quill editor
//     const quill = new Quill('#quillEditor', {
//         theme: 'snow',
//     });

//     // Find all images in the HTML content
//     const images = div.querySelectorAll('img');
//     const deltaOps = [];

//     images.forEach(img => {
//         const src = img.getAttribute('src');
//         const alt = img.getAttribute('alt');

//         deltaOps.push({ insert: { image: src } });

//         if (alt) {
//             deltaOps.push({ insert: alt });
//         }

//         deltaOps.push({ insert: '\n' });
//     });

//     // Get Delta ops from Quill editor
//     const delta = quill.getContents();

//     // Merge Delta ops from Quill with the ops from the images
//     delta.ops = deltaOps.concat(delta.ops);

//     return delta;
// }

export function htmlToDelta(html) {
  // Create a new Quill instance
  const quill = new Quill(document.createElement("div"));

  quill.root.innerHTML = html;
  const delta = quill.getContents();

  // Return the Delta ops
  return html;
}
