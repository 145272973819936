import { Button, Divider } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/Buttons/Button";
import { approveDocument } from "../../store/actions/profile/approveDocument";

export const UserInfoCard = ({ title, details }) => (
  <div className="w-full h-[476px] bg-white shadow-sm rounded-md border border-solid border-[#EAECF0] p-2 md:p-0">
    <div className="flex justify-between border-b border-gray-300 p-6"></div>

    {details?.map((detail, index) => (
      <div
        key={detail.label}
        className={`flex justify-between mr-4 ml-4 ${
          index === details?.length - 1 ? "border-b-none" : "border-bottom"
        } border-b py-3 sm:p-6`}
      >
        <h1 className="text-[#475467] font-light text-base leading-6 mr-5">
          {detail.label}
        </h1>
        <h1 className="text-[#101828] text-md font-medium leading-7 break-all">
          {detail.value}
        </h1>
      </div>
    ))}
  </div>
);

function Profile() {
  const userDetail = useSelector((state) => state?.user?.user?.data) || null;
  const approveDocLoading =
    useSelector((state) => state?.user?.approveDocLoading) || false;
  const newName = userDetail?.name?.split(" ");
  const dispatch = useDispatch();
  const firstName = newName && newName[0];
  const lastName = newName && newName[1];

  const personalInfo = [
    { label: "First Name", value: firstName },
    { label: "Last Name", value: lastName },
    { label: "Email", value: userDetail?.email },
    { label: "Phone Number", value: userDetail?.profile?.phone },
    { label: "Profession", value: userDetail?.profile?.profession },
  ];

  const addressInfo = [
    { label: "Address", value: userDetail?.profile?.address },
    {
      label: "Linkedln Profile",
      value: userDetail?.profile?.linked_in_profile || "--",
    },
    { label: "Gender", value: userDetail?.profile?.gender },
    { label: "UserId", value: userDetail?.profile?.id },
    { label: "Referral ID", value: userDetail?.profile?.referral },
  ];

  const handleApproveDocument = () => {
    const data = {
      document_verified: true,
    };
    dispatch(approveDocument({ data, userId: userDetail?.id }));
  };

  return (
    <div>
      <div className="grid mt-4 sm:grid-cols-2 gap-6">
        <div className="mb-4  md:mb-0 ">
          {" "}
          <UserInfoCard title="Personal Information" details={personalInfo} />
        </div>
        <div className="md:w-[466px] mb-4">
          <UserInfoCard title="Address Information" details={addressInfo} />
        </div>
      </div>

      <div className="grid mt-4 sm:grid-cols-2 gap-6 pb-8">
        <div className="py-3">
          <h1 className="text-[25px] font-[500] py-2 ">Identification</h1>
          <Divider />

          <div className="sm:w-[500px] md:max-w-[500px]">
            <div className="mt-5 flex gap-10 w-full justify-between">
              <strong className="text-lg">Email Verification :</strong>
              <p
                className={`flex items-center gap-1 ${
                  userDetail?.email_verified === false
                    ? "bg-[#ff000021]"
                    : "bg-[#0080001c]"
                } ${
                  userDetail?.email_verified === false
                    ? "text-[red]"
                    : "text-[green]"
                }  px-3 py-1 rounded-[20px]`}
              >
                {userDetail?.email_verified === true && (
                  <img
                    src="/Assets/markIcon.png"
                    alt=""
                    className="w-3 h-3 mr-2"
                  />
                )}{" "}
                {userDetail?.email_verified === false ? "Pending" : "Verified"}{" "}
              </p>
            </div>
            <div className="mt-5 flex gap-10 w-full justify-between">
              <strong className="text-lg">BVN Verification :</strong>
              <p
                className={`flex items-center ${
                  userDetail?.profile?.BVN === null
                    ? "bg-[#ff000021]"
                    : "bg-[#0080001c]"
                } ${
                  userDetail?.profile?.BVN === null
                    ? "text-[red]"
                    : "text-[green]"
                }  px-3 py-1 rounded-[20px]`}
              >
                {userDetail?.profile?.BVN !== null && (
                  <img
                    src="/Assets/markIcon.png"
                    alt=""
                    className="w-3 h-3 mr-2"
                  />
                )}
                {userDetail?.profile?.BVN === null ? "Pending" : "Verified"}{" "}
              </p>
            </div>
            <div className="mt-5 flex gap-10 w-full justify-between">
              <strong className="text-lg">Document Verification :</strong>
              <p
                className={` flex items-center ${
                  userDetail?.document_verified === false
                    ? "bg-[#ff000021]"
                    : "bg-[#0080001c]"
                } ${
                  userDetail?.document_verified === false
                    ? "text-[red]"
                    : "text-[green]"
                }  px-3 py-1 rounded-[20px]`}
              >
                {userDetail?.document_verified === true && (
                  <img
                    src="/Assets/markIcon.png"
                    alt=""
                    className="w-3 h-3 mr-2"
                  />
                )}
                {userDetail?.document_verified === false
                  ? "Pending"
                  : "Verified"}{" "}
              </p>
            </div>
            <div className="mt-5 flex gap-10 w-full justify-between">
              <strong className="text-lg">Membership Verification :</strong>
              <p
                className={`flex items-center ${
                  userDetail?.free_approved_plan === null
                    ? "bg-[#ff000021]"
                    : "bg-[#0080001c]"
                } ${
                  userDetail?.free_approved_plan === null
                    ? "text-[red]"
                    : "text-[green]"
                }  px-3 py-1 rounded-[20px]`}
              >
                {userDetail?.free_approved_plan !== null && (
                  <img
                    src="/Assets/markIcon.png"
                    alt=""
                    className="w-3 h-3 mr-2"
                  />
                )}
                {userDetail?.free_approved_plan === null
                  ? "Pending"
                  : "Verified"}{" "}
              </p>
            </div>
          </div>
        </div>
        {userDetail?.profile?.id_photo && userDetail?.profile?.id_number && (
          <div className="w-[466px]   mt-8 bg-white shadow-sm rounded-md border border-solid border-[#EAECF0] mr-6">
            <div className="border-b border-gray-300 pt-4 p-4 text-[#128591]">
              Attached ID Document{" "}
            </div>
            <div className="flex flex-col pt-4 pb-4 text-[#128591]  pl-4 mt-0">
              <div className="flex mb-2">
                <span>Document Type: </span>
                <span className="ml-3 font-[600]">
                  {userDetail?.profile?.id_type
                    ?.toUpperCase()
                    .replace(/_/g, " ")}
                </span>
              </div>

              <div className="flex mb-5">
                <span>Document ID: </span>
                <span className="ml-3 font-[600]">
                  {userDetail?.profile?.id_number}
                </span>
              </div>

              <div>
                <h4 className="text-[#101828] text-base font-semibold">
                  <a
                    href={userDetail?.profile?.id_photo}
                    className="text-[blue]"
                    target="_blank"
                  >
                    Click to view file
                  </a>
                </h4>
              </div>
              <div className="mt-5">
                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  children="Approve Document"
                  width="full md:w-103px"
                  onClick={handleApproveDocument}
                  height="40px"
                  loading={approveDocLoading}
                  borderColor="#159AA8"
                  padding="10px"
                  fz="14px"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
