import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_CREATE_SUBSCRIPTION,
  API_SUBSCRIPTION_PACKAGE,
} from "../../../config/api";

export const addSubscription = createAsyncThunk(
  "subscription/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CREATE_SUBSCRIPTION,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);
export const getSubscriptionByCurrency = createAsyncThunk(
  "subscription/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_CREATE_SUBSCRIPTION,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
