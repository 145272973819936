import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_APPROVE_WITHDRAWAL_REQUESTS } from "../../../config/api";
import { fetchAllWithdrawalReqs } from "./fetchAllWithdrawalReqs";

export const approveWithdrawalReqs = createAsyncThunk(
  "approveWithdrawalRequest",
  async (payload, {dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_APPROVE_WITHDRAWAL_REQUESTS}${payload?.currency}`,
        data: {
          amount: payload?.amount,
          withdrawal_request_id: payload?.withdrawal_request_id
        }
      });
      toast.success("Withdrawal Request Successfully Approved");
      payload?.callback()
      dispatch(fetchAllWithdrawalReqs());

      return response?.data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(msg);
    }
  }
);
