import React from "react";
import AdminInvestorProfile from "../Onboarding/InvestorProfile";

function InvestorProfile() {
  return (
    <div>
      <AdminInvestorProfile />
    </div>
  );
}

export default InvestorProfile;
