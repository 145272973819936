import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_RECORD_JOURNAL } from "../../../config/api";

export const createRecordJournal = createAsyncThunk(
  "recordJournal/create",
  async ({ category, amount, cb }, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_RECORD_JOURNAL}${category}`,
        data: {
          amount,
        },
        serializerConfig: { indexes: null },
      });
      toast.success("Record journal  has been successfully created");
      //   payload.navigate("investment");
      cb();
      return response?.data;
    } catch (error) {
      cb();

      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
