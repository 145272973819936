import React from "react";

function ReturnIcon() {
  return (
    <div>
      <img
        src={"/Assets/return.png"}
        alt="Return Icon"
        style={{ width: "24px", height: "24px" }}
      />
    </div>
  );
}

export default ReturnIcon;
