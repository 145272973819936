import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const DashboardLayout = ({ title, children }) => {
  const [show, setShow] = useState(false);
  const handleCloseSideBar = () => {
    setShow(false);
  };
  const handleOpenSideBar = () => {
    setShow(true);
  };
  return (
    <div className="flex h-screen  w-screen  relative bg-[#fff]">
      <div
        className={`h-screen sm:w-7/12 md:w-4/12 lg:w-[19.444%]  bg-[#FFFFFF]  z-40 fixed left-0 top-0 ${
          show ? "block" : "hidden"
        } lg:block `}
      >
        <div
          onClick={handleCloseSideBar}
          className="bg-[#ffffff] block lg:hidden z-2 absolute top-0 left-0 w-screen h-screen"
        ></div>
        <Sidebar handleCloseSideBar={handleCloseSideBar} />
      </div>

      <main className="flex-1  overflow-x-hidden  lg:ml-[19.444%] ">
        <Box>
          <Collapse>
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => {
                  //   setOpen(false);
                  // }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            ></Alert>
          </Collapse>
        </Box>

        <Header title={title} handleOpenSideBar={handleOpenSideBar} />
        <div className="mt-[10px]  lg:m-[10px]">{children}</div>
      </main>
    </div>
  );
};

export default DashboardLayout;
