import React from "react";
import TextInput from "../inputs/TextInput";
import TextArea from "../inputs/TextArea";
import { returnNumberValues } from "../../Helper/returnNumberValues";

const FormInput = ({
  label,
  max,
  id,
  subType,
  disabled,
  placeholder,
  type = "text", // Default type set to "text" to ensure there's always a type if not provided
  form,
  setActiveInput,
  classNameProp,
  handleChangeBySelf,
  value,
  ...props
}) => {
  const handleTextChanged = (e) => {
    if (subType === "number") {
      const value = returnNumberValues(e.target.value, id, max);
      form?.setFieldValue(id, value);
    } else {
      form?.handleChange(e);
    }
  };

  const commonProps = {
    id,
    label,
    name: id,
    form,
    disabled,
    onChange: (e) => {
      form.handleChange(e);
      handleTextChanged(e);
    },
    onBlur: form?.onBlur,
    placeholder,
    value: value || form?.values[id],
    handleChangeBySelf,
    ...props,
  };

  return (
    <div className={`mb-4 ${classNameProp}`}>
      {type === "textArea" ? (
        <TextArea {...commonProps} />
      ) : (
        <TextInput {...commonProps} type={type} noMargin />
      )}
      {form?.errors[id] && (
        <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
          {form?.errors[id]}
        </div>
      )}
    </div>
  );
};

export default FormInput;
