import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ExportExcel from "../../Export/ExportExcel";
import useCurrency from "../../../hooks/useCurrency";
import {
  getAllUser,
  getUserDetail,
} from "../../../store/actions/userManagement/management";
import { Link, useParams } from "react-router-dom";
import AdminUserIcon from "../../Icons/AdminUserIcon";
import { fetchTransactions } from "../../../store/actions/userManagement/getTransactionHistory";
import { getCurrencySymbol } from "../../../Helper/getCurrencySymbol";
import { getAllSettlementData } from "../../../store/actions/settlement/getAllSettlementData";
import DashboardLayout from "../../SharedLayouts/DashboardLayout";
import { UserInfoCard } from "../../UserManagementDetails/Profile";
import BackArrow2 from "../../BackArrow2";

function ProvidusReportDetail() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredRows, setFilteredRows] = useState([]);
  const [, setShowPopper] = useState(false);
  const [_currency, getAmount] = useCurrency();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const loading = useSelector((state) => state?.user?.loading);
  const allSettlement =
    useSelector((state) => state?.settlement?.allSettlement) || [];
  const { id } = useParams();

  const settlementDetail = allSettlement?.filter(
    (settlement, index) => settlement?.settlement_id === id
  );

  useEffect(() => {
    dispatch(getAllSettlementData());
  }, [dispatch]);

  const personalInfo = [
    { label: "Full Name", value: settlementDetail[0]?.user?.name },
    { label: "Email", value: settlementDetail[0]?.user?.email },
    { label: "User ID", value: settlementDetail[0]?.user_id },
    {
      label: "Source Account Name",
      value: settlementDetail[0]?.source_account_name,
    },
    {
      label: "Source Account Number",
      value: settlementDetail[0]?.source_account_number,
    },
    { label: "Source Bank", value: settlementDetail[0]?.source_bank_name },
    {
      label: "Settled Amount",
      value: `${getCurrencySymbol(settlementDetail?.currency)} ${getAmount(
        settlementDetail[0]?.settled_amount,
        2
      )}`,
    },
    {
      label: "Fee Amount",
      value: `${getCurrencySymbol(settlementDetail?.currency)} ${getAmount(
        settlementDetail[0]?.fee_amount,
        2
      )}`,
    },

    {
      label: "Vat Amount",
      value: `${getCurrencySymbol(settlementDetail?.currency)} ${getAmount(
        settlementDetail[0]?.vat_amount,
        2
      )}`,
    },
    {
      label: "Date",
      value: new Date(settlementDetail[0]?.tran_date_time).toLocaleDateString(
        "en-US",
        {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
        }
      ),
    },
    { label: "Session ID", value: settlementDetail[0]?.session_id },
    { label: "Settlement ID", value: settlementDetail[0]?.settlement_id },
    { label: "Remarks ID", value: settlementDetail[0]?.tran_remarks },
  ];

  return (
    <DashboardLayout>
      <div className="flex items-center mb-8">
        <Link
          to="/dashboard"
          className="flex items-center space-x-2 text-gray-600"
        >
          <img src="/Assets/bread.png" alt="Bread" className="w-8 h-8" />
          <span>Dashboard</span>
        </Link>
        <span className="mx-2 text-gray-600">/</span>
        <span className="font-semibold text-gray-800">
          Providus Report Detail
        </span>
      </div>
      <BackArrow2 mb={10} ml={20} />
      <div className="max-w-lg mx-auto">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Settlement Information
          </h2>
          <div className="space-y-4">
            {personalInfo?.map((info, index) => (
              <div key={index} className="flex justify-between gap-10">
                <span className="font-medium text-gray-600">{info?.label}</span>
                <span className="text-gray-800">{info.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ProvidusReportDetail;
