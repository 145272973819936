import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_GET_TRANSACTION_METRIC_BY_CURRENCY } from "../../../config/api";

export const getTransactionMetrics = createAsyncThunk(
  "investment/getTransactionMetrics",
  async (_, { rejectWithValue, getState }) => {
    const currency = getState().dashboard.currency;
    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_GET_TRANSACTION_METRIC_BY_CURRENCY}${currency}`,
      });

      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
