import React from "react";

function Dots() {
  return (
    <div>
      <img
        src={"/Assets/dots.png"}
        alt="User Icon"
        style={{ width: "20px", height: "20px" }}
      />
    </div>
  );
}

export default Dots;
