import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_FETCH_TRANSACTIONS } from "../../../config/api";

export const fetchAllTransactions = createAsyncThunk(
  "transaction/getTotal",
  async (currency, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_FETCH_TRANSACTIONS}${currency}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(msg);
    }
  }
);
