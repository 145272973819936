import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_ADD_PREVIOUS_RECORDS } from "../../../config/api";

export const addPreviousRecords = createAsyncThunk(
  "investment/addPreviousRecords",
  async ({ data, id, callback }, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: API_ADD_PREVIOUS_RECORDS + id,
        data,
      });
      toast.success("User Record added Successfully");
      callback()
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(msg);
    }
  }
);
