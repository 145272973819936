import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import UserCard from "../../components/cards/UserCard";
import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "../../store/actions/userManagement/management";
import AdminUserIcon from "../../components/Icons/AdminUserIcon";
import useCurrency from "../../hooks/useCurrency";
import TransactionTable from "../../components/dashboard/transaction/TransactionTable";
import { getAllPayout } from "../../store/actions/transactions/getAllPayout";
import CustomButton from "../../components/Buttons/GroupButtons";
import HalcreditTransactionTable from "../../components/dashboard/transaction/HalcreditTransactionTable";
import { fetchAllTransactions } from "../../store/actions/transactions/fetchAllTransactions";
import { getTransactionMetrics } from "../../store/actions/transactions/getTransactionMetrics";
import { Link } from "react-router-dom";
import BusinessTransactionTable from "../../components/dashboard/transaction/BusinessTransactionTable";
import { setTransactionGroup } from "../../store/reducers/dashboardSlice";

function Transaction() {
  const dispatch = useDispatch();
  const [_currency, getAmount] = useCurrency();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const loading = useSelector((state) => state.transactions.pending) || false;

  const { transactionGroupFilter } = useSelector((state) => state?.dashboard);
  const { transactionMetrics } = useSelector((state) => state?.transactions);

  const investmentDetails = [
    {
      id: 1,
      icon: <AdminUserIcon />,
      title: "Total Investments Amount",
      cardValue: `${_currency}${getAmount(
        transactionMetrics?.total_investments,
        2
      )}`,
    },

    {
      id: 2,
      icon: <AdminUserIcon />,
      title: "Total Payout Amount",
      cardValue: `${_currency}${getAmount(
        transactionMetrics?.total_payout_amount,
        2
      )}`,
    },

    {
      id: 4,
      icon: <AdminUserIcon />,
      title: "Total Subscription Amount",
      cardValue: `${_currency}${getAmount(
        transactionMetrics?.total_subscription,
        2
      )}`,
    },
    {
      id: 5,
      icon: <AdminUserIcon />,
      title: "Total Subscribers",
      cardValue: transactionMetrics?.total_subscribers,
    },
    {
      id: 6,
      icon: <AdminUserIcon />,
      title: "Total admin fee Amount",
      cardValue: `${_currency} ${getAmount(
        transactionMetrics?.total_admin_fee,
        2
      )}`,
    },
  ];

  useEffect(() => {
    dispatch(getAllUser());
    dispatch(fetchAllTransactions(currency));
    dispatch(getAllPayout());
  }, [currency]);

  useEffect(() => {
    dispatch(getTransactionMetrics());
  }, [currency]);

  return (
    <DashboardLayout>
      <div className="px-4 lg:px-8">
        <div className="flex items-center mb-6 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <div className=" font-semibold text-[#475467] text-sm sm:text-base bg-[#F9FAFB] p-1 px-3 rounded-md  ">
            Transaction Management
          </div>
        </div>

        <div className="">
          {/* <div className="flex justify-between items-center flex-wrap mb-5 lg:mb-10">
            <h1 className="mb-2 lg:mb-0 font-[500] text-2xl lg:text-3xl">
              Transaction Management
            </h1>
          </div> */}
          <div className="flex flex-wrap gap-y-2 mb-6">
            <CustomButton
              variant="outlined"
              customColor={
                transactionGroupFilter === "halvest" ? "#159AA8" : "#fff"
              }
              children="HNI"
              width="250px"
              height="40px"
              padding="10px"
              className={`!border  lg:border-none lg:!rounded-tr-none lg:!rounded-br-none !rounded-lg`}
              borderColor={"#D0D5DD"}
              onClick={() => dispatch(setTransactionGroup("halvest"))}
              color={transactionGroupFilter === "halvest" ? "#fff" : "#000"}
            />
            <CustomButton
              variant="outlined"
              children="Business Clients"
              width="250px"
              height="40px"
              padding="10px"
              className={`!border  lg:!border-l-0 lg:border-none  lg:!rounded-none `}
              color={transactionGroupFilter === "business" ? "#fff" : "#000"}
              borderColor={"#D0D5DD"}
              customColor={
                transactionGroupFilter === "business" ? "#159AA8" : "#fff"
              }
              onClick={() => dispatch(setTransactionGroup("business"))}
            />
            <CustomButton
              variant="outlined"
              customColor={
                transactionGroupFilter === "halcredit" ? "#159AA8" : "#fff"
              }
              children="Teal"
              width="250px"
              height="40px"
              padding="10px"
              className={
                "!border  lg:!rounded-tl-none lg:!border-l-0 lg:border-none lg:!rounded-bl-none !rounded-lg"
              }
              onClick={() => dispatch(setTransactionGroup("halcredit"))}
              color={transactionGroupFilter === "halcredit" ? "#fff" : "#000"}
              borderColor={"#D0D5DD"}
            />
          </div>
          {!loading ? (
            <>
              {transactionGroupFilter === "halvest" && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {investmentDetails.map((detail) => (
                    <div key={detail.id} className="mb-4 sm:mb-0">
                      <UserCard
                        currency="NG"
                        currencyCode="NGN"
                        width={"100%"}
                        height={188}
                        icon={detail.icon}
                        title={detail.title}
                        cardValue={detail.cardValue}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="mt-10">
                {transactionGroupFilter === "halvest" ? (
                  <TransactionTable />
                ) : transactionGroupFilter === "business" ? (
                  <BusinessTransactionTable />
                ) : (
                  <HalcreditTransactionTable />
                )}
              </div>
            </>
          ) : loading ? (
            <Loader />
          ) : (
            <></>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Transaction;
