import { createSlice } from "@reduxjs/toolkit";
import { getPackages, subscribe } from "../actions/subscription/membership";
import { addSubscription } from "../actions/subscription/subscription";

export const subscribeSlice = createSlice({
  name: "subscribe",
  initialState: {
    error: null,
    packages: [],
    paymentURL: {},
    pending: false,
    success: false,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getPackages.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.pending = false;
      state.packages = action.payload;
    });
    builder.addCase(getPackages.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(subscribe.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(subscribe.fulfilled, (state, action) => {
      state.pending = false;
      state.paymentURL = action.payload;
    });
    builder.addCase(subscribe.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(addSubscription.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(addSubscription.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(addSubscription.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
  },
});

export default subscribeSlice.reducer;
