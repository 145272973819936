import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "../Buttons/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { PDFViewer } from "@react-pdf/renderer";
import PdfViewerr from "../PDFViewer";

function UploadDocumentModal({ open, onClose, selectedFiles, deleteFile, selectedUser, handleFileChange, saveDocuments }) {
    const navigate = useNavigate();
    const clearIconStyle = {
        position: "absolute",
        top: "0px",
        right: "10px",
        background: "#159AA8",
        borderRadius: "50%",
        padding: "4px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        cursor: "pointer",
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        width: 480,
                        height: 580,
                        bgcolor: "background.paper",
                        borderRadius: "10px",
                        boxShadow: 24,
                        lineHeight: 1.9,
                        p: 3,
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <h2 className="my-3 text-[20px] ">Manage Documents for {selectedUser?.name}</h2>
                    <input type="file" onChange={handleFileChange} disabled={selectedFiles?.length === 4} />
                    {/* Display selected files */}
                    {selectedFiles?.length > 0 && (
                        <div>
                            <ul className="grid grid-cols-2 gap-8 mt-4 max-h-[100%] overflow-y-auto">
                                {selectedFiles?.map((file, index) => (
                                    <div className="flex flex-col gap-1">
                                                                           <p className="!text-[12px] ">{file?.document_name}</p>
 
                                    <div
                                        className=" w-[200px] mt-3 h-[100px] relative"
                                        style={{
                                            // height: "80px",
                                            border: "1px solid #EAECF0",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "10px",
                                            textAlign: "center",
                                            lineHeight: 1.8,
                                            marginTop: "10px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <IconButton onClick={deleteFile} style={clearIconStyle} className="absolute -top-5">
                                            <ClearIcon />
                                        </IconButton> 

                                            <iframe src={URL.createObjectURL(file?.document_file)} width='100%' height='100%' />

                                    

                                    </div>
                                    </div>

                                ))}
                            </ul>
                        </div>
                    )}
                    <div className=" ml-auto absolute bottom-0">
                        <CustomButton
                            variant="contained"
                            customColor="#159AA8"
                            children="Save Documents"
                            width="200px"
                            height="45px"
                            padding="10px"
                            margin="15px 0"
                            color="#fff"
                            onClick={saveDocuments}
                        />
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default UploadDocumentModal;
