import React, { useState, useRef, useEffect } from "react";
import "react-phone-number-input/style.css";
import Button from "../inputs/Button";
import HalDatePicker from "../inputs/HalDatePicker";
import { useFormik } from "formik";
import { Avatar } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import AddressFormDetail from "./AddressFormDetail";
import FormInput from "../Formik/FormInput";
import Identification from "./Identification";
import CloseAccount from "./CloseAccount";
import PhoneInputField from "../inputs/PhoneInputField";
import { formHandler } from "../../Helper/formHandler";
import { useSelector } from "react-redux";
import TextInput from "../inputs/TextInput";

const UserProfileForm = ({ user }) => {
  const { pending, businessUserDetails } = useSelector((state) => state?.user);

  const [myImage, setMyImage] = useState(null);
  const [myImagePreview, setMyImagePreview] = useState(null);

  const handleImageChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);
    fileReader.addEventListener("load", (result) => {
      setMyImagePreview(result.target.result);
    });

    setMyImage(convertedFiles);
    // const formData = new FormData();
    // dispatch(updateProfile({ data: formData, cb: () => {} }));

    // if ((file.size / 1024 / 1024).toFixed(4) > 4){};
  };

  const form = useFormik({
    initialValues: {
      firstName: businessUserDetails?.user?.name?.split(" ")[0],
      lastName: businessUserDetails?.user?.name?.split(" ")[1],
      dob: dayjs(user?.dateOfBirth),
      phone: businessUserDetails?.user?.phone,
      email: businessUserDetails?.user?.email,
      phoneCode: "234",
      profilePicture: null,
      residentialNumber: businessUserDetails?.user?.residentialNumber,
      streetName: businessUserDetails?.user?.streetName,
      city: businessUserDetails?.user?.city,
      state: {
        value: businessUserDetails?.user?.state,
        label: businessUserDetails?.user?.state,
      },
      country: {
        value: businessUserDetails?.user?.country,
        label: businessUserDetails?.user?.country,
      },
      zipCode: businessUserDetails?.user?.zipCode,
    },
  });

  useEffect(() => {
    form.setValues({
      firstName: businessUserDetails?.user?.name?.split(" ")[0],
      lastName: businessUserDetails?.user?.name?.split(" ")[1],
      dob: dayjs(user?.dateOfBirth),
      phone: businessUserDetails?.user?.phone || "+234",
      email: businessUserDetails?.user?.email,
      phoneCode: "+234",
      profilePicture: null,
      residentialNumber: businessUserDetails?.user?.residentialNumber || "-",
      streetName: businessUserDetails?.user?.streetName || "-",
      city: businessUserDetails?.user?.city || "-",
      state: {
        value: businessUserDetails?.user?.state || "-",
        label: businessUserDetails?.user?.state || "-",
      },
      country: {
        value: businessUserDetails?.user?.country || "-",
        label: businessUserDetails?.user?.country || "-",
      },
      zipCode: businessUserDetails?.user?.zipCode || "-",
    });
  }, [businessUserDetails]);

  const fileRef = useRef();

  const handleImage = () => {
    fileRef?.current?.click();
  };

  const handleRemoveImage = () => {
    setMyImagePreview(null);
    setMyImage(null);
  };

  return (
    <div className="pb-8">
      <div className="w-[650px] lg:w-[800px] border-b-[1px] mb-8 pb-2 border-b-[#F0F0F0] ">
        <h3 className=" text-base font-[500] text-[#333333] ">
          Personal Details
        </h3>
      </div>
      <div>
        <div className="flex items-center mb-10">
          <Avatar sx={{ width: "80px", height: "80px" }}>
            <div
              onClick={() => fileRef?.current?.click()}
              className="profile-wrapper cursor-pointer"
            >
              {(myImagePreview || user?.profilePicture) && (
                <img
                  src={myImagePreview ? myImagePreview : user?.profilePicture}
                  alt=""
                />
              )}
            </div>
          </Avatar>
          <div
            onClick={handleImage}
            className="border ml-8 cursor-pointer border-[#000000] flex justify-center items-center w-[139px] h-10 mr-4 text-[#000000] text-[14px]  font-[500] rounded-lg"
          >
            <h3>Change Image</h3>
          </div>
          <button
            disabled={!myImagePreview}
            onClick={handleRemoveImage}
            className="w-24 mr-8 bg-[#F0F0F0] text-[#878787] text-[14px] font-[400] h-10 rounded-lg"
          >
            <h3>Remove</h3>
          </button>
        </div>

        <div className="w-[650px] lg:w-[800px] ">
          <div className="grid  gap-x-6 sm:grid-cols-1  md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
            <div className="max-w-[50%] lg:max-w-full">
              <FormInput
                label="First Name"
                placeholder="Your First Name"
                type="text"
                {...formHandler("firstName", form)}
                disabled={true}
              />
            </div>
            <div className="max-w-[50%] lg:max-w-full">
              <FormInput
                label="Last Name"
                {...formHandler("lastName", form)}
                placeholder="Your Last Name"
                disabled={true}
                type="text"
              />
            </div>

            <div className="flex-1  ">
              <HalDatePicker
                disabled={true}
                label="Date of Birth"
                {...formHandler("dob", form)}
                rightImage
              />
            </div>

            <div style={{ flex: 1, maxWidth: "100%" }}>
              <PhoneInputField
                // fullWidth
                disabled={true}
                inputStyle={{ height: "58px" }}
                style={{ width: "100%" }}
                name={"phone"}
                form={form}
                id={"phone"}
              />
            </div>
          </div>
          <div className="max-w-[51%] lg:max-w-full">
            <TextInput
              label="Email"
              placeholder="Your Email Address"
              type="email"
              disabled={true}
              {...formHandler("email", form)}
            />
            <p
              style={{
                fontSize: "14px",
                color: "#ABAFB1",

                paddingLeft: "2px",
              }}
            >
              Changing your email requires verification{" "}
            </p>
          </div>
          <div className=" flex justify-end">
            <Button
              type={"button"}
              onClick={null}
              disabled={true}
              className="mt-3"
              title={"Save changes"}
              loading={""}
              pt="12px"
              pb="12px"
              pl="16px"
              pr="16px"
              width={"162px"}
              style={{ backgroundColor: "#F0F0F0", color: "#000" }}
            />
          </div>
        </div>
        <AddressFormDetail form={form} />
        <Identification />
        <CloseAccount />
      </div>
    </div>
  );
};

export default UserProfileForm;
