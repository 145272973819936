import { createSlice } from "@reduxjs/toolkit";
import { addFunds, getBalance } from "../actions/dashboard/manageFunds";
import { fetchAllTransactions } from "../actions/transactions/fetchAllTransactions";
import { getAllPayout } from "../actions/transactions/getAllPayout";
import { fetchTransactionsByCurrency } from "../actions/transactions/fetchTransactionByCurrency";
import { getTransactionMetrics } from "../actions/transactions/getTransactionMetrics";
import { getBusinessTransactions } from "../actions/transactions/getBusinessTransactions";
import { getApplications } from "../actions/applications/getApplications";
import { getApplication } from "../actions/applications/getApplication";
import { approveApplication } from "../actions/applications/approveApplication";
import { rejectApplication } from "../actions/applications/rejectApplication";

export const dashboardSlice = createSlice({
  name: "transactions",
  initialState: {
    error: null,
    pending: false,
    businessPending: false,
    success: false,
    transactions: [],
    transactionsPageInfo: null,
    businessTransactionsPageInfo: null,
    transactionMetrics: null,
    allPayout: [],
    businessTransactions: [],
    applicationsPending: false,
    applicationPending: false,
    rejectingApplication: false,
    approvingApplication: false,
    applications: [],
    application: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getApplication.pending, (state, action) => {
      state.applicationPending = true;
      state.application = null;
    });
    builder.addCase(getApplication.fulfilled, (state, action) => {
      state.applicationPending = false;
      state.application = action.payload;
    });
    builder.addCase(getApplication.rejected, (state, action) => {
      state.applicationPending = false;
    });
    builder.addCase(getApplications.pending, (state, action) => {
      state.applicationsPending = true;
    });
    builder.addCase(getApplications.fulfilled, (state, action) => {
      state.applicationsPending = false;
      state.applications = action.payload;
    });
    builder.addCase(getApplications.rejected, (state, action) => {
      state.applicationsPending = false;
    });
    builder.addCase(approveApplication.pending, (state, action) => {
      state.approvingApplication = true;
    });
    builder.addCase(approveApplication.fulfilled, (state, action) => {
      state.approvingApplication = false;
      state.applications = action.payload;
    });
    builder.addCase(approveApplication.rejected, (state, action) => {
      state.approvingApplication = false;
    });
    builder.addCase(rejectApplication.pending, (state, action) => {
      state.rejectingApplication = true;
    });
    builder.addCase(rejectApplication.fulfilled, (state, action) => {
      state.rejectingApplication = false;
      state.applications = action.payload;
    });
    builder.addCase(rejectApplication.rejected, (state, action) => {
      state.rejectingApplication = false;
    });
    builder.addCase(getBusinessTransactions.pending, (state, action) => {
      state.businessPending = true;
    });
    builder.addCase(getBusinessTransactions.fulfilled, (state, action) => {
      state.businessPending = false;
      state.businessTransactions = action.payload;
      // state.businessTransactionsPageInfo = action.payload;
    });
    builder.addCase(getBusinessTransactions.rejected, (state, action) => {
      state.businessPending = false;
    });
    builder.addCase(fetchAllTransactions.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(fetchAllTransactions.fulfilled, (state, action) => {
      state.pending = false;
      state.transactions = action.payload;
      state.transactionsPageInfo = action.payload;
    });
    builder.addCase(fetchAllTransactions.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(fetchTransactionsByCurrency.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(fetchTransactionsByCurrency.fulfilled, (state, action) => {
      state.pending = false;
      state.transactions = action.payload?.data;
      state.transactionsPageInfo = action.payload;
    });
    builder.addCase(fetchTransactionsByCurrency.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getAllPayout.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getAllPayout.fulfilled, (state, action) => {
      state.pending = false;
      state.allPayout = action.payload;
    });
    builder.addCase(getAllPayout.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getTransactionMetrics.pending, (state, action) => {
      state.transactionMetrics = null;
    });
    builder.addCase(getTransactionMetrics.fulfilled, (state, action) => {
      state.transactionMetrics = action.payload;
    });
  },
});

export const { updateCreditCards, addCreditCards, switchCurrency } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
