import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "../../hooks/useCurrency";
import { cancelWithdrawalReqs } from "../../store/actions/withdrawal/CancelWithdrawal";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";


function CancelWithdrawal({ open, onClose, selectedRow, setCancelWithdrawal }) {
  const [_currency, getAmount] = useCurrency();
  const loading = useSelector(
    (state) => state?.withdrawalRequests?.cancelRequestPending
  );
  const dispatch = useDispatch();
  const handleCancel = async () => {
    dispatch(
      cancelWithdrawalReqs({
        withdrawal_request_id: selectedRow?.id,
        callback: () => setCancelWithdrawal(false)
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 540,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-3">
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div>
            <h1 className="font-semibold text-2xl mb-4">
              Confirm Cancellation
            </h1>
         
          
            <p className="text-sm mb-10">
              Are you sure you want to cancel this withdrawal request?
            </p>

            <div className="flex mt-6 w-full items-center">
              <CustomButton
                variant="outlined"
                customColor="#fff"
                children="Cancel"
                width="100%"
                height="44px"
                borderColor="#D0D5DD"
                color="#344054"
                iconWidth="22px"
                margin="0px 25px 0px 0px"
                onClick={onClose}
              />

              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children={"Cancel Request"}
                width="100%"
                height="44px"
                loading={loading}
                padding="10px"
                color={"#fff"}
                onClick={handleCancel}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default CancelWithdrawal;
