import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import UserCard from "../../components/cards/UserCard";
import { useDispatch, useSelector } from "react-redux";
import InvestmentTable from "../../components/dashboard/investment/InvestmentTable";
import CustomButton from "../../components/Buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";
import { getAllPayout } from "../../store/actions/transactions/getAllPayout";
import useCurrency from "../../hooks/useCurrency";
import { getInvestmentMetrics } from "../../store/actions/transactions/getInvestmentMetrics";

function Investment() {
  const dispatch = useDispatch();
  const { investmentMetrics } = useSelector((state) => state?.investment);

  const currency = useSelector((state) => state.dashboard.currency) || "NGR";

  const [_currency, getAmount] = useCurrency();

  const user = useSelector((state) => state.onboarding.user);

  useEffect(() => {
    dispatch(getAllProductInvestment());
    dispatch(getAllPayout());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInvestmentMetrics());
  }, [currency]);

  const navigate = useNavigate();

  const investmentDetails = [
    {
      id: 1,
      icon: (
        <img src={"/Assets/Icon-1.png"} alt="User Icon" className="w-12 h-12" />
      ),
      title: "Total Investments",
      cardValue: investmentMetrics?.total_investments || "--",
    },
    {
      id: 2,
      icon: (
        <img src={"/Assets/Icon-2.png"} alt="User Icon" className="w-12 h-12" />
      ),
      title: "Total Payout",
      cardValue: `${_currency}${
        investmentMetrics?.total_payout_amount
          ? getAmount(investmentMetrics?.total_payout_amount)
          : "--"
      }`,
    },

    {
      id: 4,
      icon: (
        <img src={"/Assets/Icon-3.png"} alt="User Icon" className="w-12 h-12" />
      ),
      title: "Total Active Investments",
      cardValue: investmentMetrics?.total_active_investments || "--",
    },
    {
      id: 5,
      icon: (
        <img src={"/Assets/Icon-4.png"} alt="User Icon" className="w-12 h-12" />
      ),
      title: "Total Closed Investment",
      cardValue: investmentMetrics?.total_closed_investments || "--",
    },
    {
      id: 6,
      icon: (
        <img src={"/Assets/Icon-2.png"} alt="User Icon" className="w-12 h-12" />
      ),
      title: "Total Number of User Invested",
      cardValue: investmentMetrics?.total_user_investments || "--",
    },
  ];

  return (
    <DashboardLayout>
      <div className="px-4 md:px-8">
        <div className="flex items-center mb-4 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-1" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-1" />

          <div className=" font-semibold text-[#475467] text-sm sm:text-base bg-[#F9FAFB] p-1 px-3 rounded-md  ">
            Investment Management
          </div>
        </div>

        <div className="">
          <div className="flex flex-col md:flex-row justify-between items-center border-b border-b-[EAECF0] md:items-center mb-10">
            <div></div>
            {/* <h1 className="mb-4 md:mb-0 font-[500] text-2xl md:text-3xl">
              Investment Management
            </h1> */}
            {user?.roles &&
              !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  children="Create a New Investment"
                  width="256px"
                  height="45px"
                  padding="10px"
                  margin="15px 0"
                  color="#fff"
                  onClick={() => navigate("/investment/create")}
                />
              )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {investmentDetails.map((detail) => (
              <div key={detail.id}>
                <UserCard
                  currency="NG"
                  currencyCode="NGN"
                  width="full md:100%"
                  height={188}
                  icon={detail.icon}
                  title={detail.title}
                  cardValue={detail.cardValue}
                />
              </div>
            ))}
          </div>

          <div className="mt-6">
            <InvestmentTable />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
export default Investment;
