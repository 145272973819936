import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_GET_APPLICATIONS,
  API_UPDATE_APPLICATION_STATUS,
} from "../../../config/api";

export const approveApplication = createAsyncThunk(
  "applications/approve",
  async ({ body, id }, { getState }) => {
    const { applications } = getState().transactions;
    try {
      const { data } = await Client({
        method: "POST",
        path: `${API_UPDATE_APPLICATION_STATUS}${id}`,
        data: body,
      });
      toast.success("Approved successfully");
      return applications?.map((application) =>
        application?.invoiceID === id
          ? { ...application, status: "approved" }
          : application
      );
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
    }
  }
);
