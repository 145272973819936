import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
    API_MARK_ADMIN,
} from "../../../config/api";
import { getAllUser } from "./management";

export const makeAdmin = createAsyncThunk(
    "user/makeAdmin",
    async ({data, callback}, { dispatch, rejectWithValue }) => {
      try {
        const response = await Client({
          method: "POST",
          path: API_MARK_ADMIN,
          data
        });
        toast.success("User Marked as Admin Successfully");
        callback()
        dispatch(getAllUser());
        return response?.data;
      } catch (error) {
   
        const msg = proccessErrorMessage(error);
        toast.error(msg);
        return rejectWithValue(msg);
      }
    }
  );