import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_REODER_INVESTMENT } from "../../../config/api";
import { getAllProductInvestment } from "./getInvestments";


export const reOrderInvestment = createAsyncThunk(
  "investment/reorder",
  async (payload, {dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_REODER_INVESTMENT + payload.data?.id,
        data: payload.data,
      });
      toast.success("Product reordered successfully");
      dispatch(getAllProductInvestment())
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
