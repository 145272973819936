import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { removeMasterPassword } from "../../store/actions/userManagement/removeMasterPassword";


function RemovetMasterPasswordModal({ open, onClose, userId, setRemoveMasterPasswordModal }) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state?.user?.removePending);

  const handleSubmit = () => {
  
   dispatch(removeMasterPassword({userId, callback: () => setRemoveMasterPasswordModal(false)}));

  };


  return (
    <Modal
      open={open} 
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 540,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-5">
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div>
            <h1 className="font-semibold text-2xl mb-4">Remove Master Password</h1>
            <p className="text-sm mb-4">
              Are you sure you want to Remove master password
            </p>

     
            <div className="flex mt-6 w-full items-center">
              <CustomButton
                variant="outlined"
                customColor="#fff"
                children="Cancel"
                width="100%"
                height="44px"
                borderColor="#D0D5DD"
                color="#344054"
                margin="0px 25px 0px 0px"
                iconWidth="22px"
                onClick={onClose}
              />

              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children={"Remove Password"}
                width="100%"
                height="44px"
                padding="10px"
                color={"#fff"}
                loading={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default RemovetMasterPasswordModal;
