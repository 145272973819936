import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_FETCH_TRANSACTION_BY_CURRENCY } from "../../../config/api";

export const fetchTransactionsByCurrency = createAsyncThunk(
  "transactions/currency",
  async (currency, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_FETCH_TRANSACTION_BY_CURRENCY}${currency}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      return rejectWithValue(msg);
    }
  }
);
