function PaymentIcon({stroke}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M15 13C15 13.8284 15.6716 14.5 16.5 14.5C17.3284 14.5 18 13.8284 18 13C18 12.1716 17.3284 11.5 16.5 11.5C15.6716 11.5 15 12.1716 15 13Z" stroke={stroke} stroke-width="1.5"/>
    <path d="M17.9 7C17.9656 6.67689 18 6.34247 18 6C18 3.23858 15.7614 1 13 1C10.2386 1 8 3.23858 8 6C8 6.34247 8.03443 6.67689 8.10002 7" stroke={stroke} stroke-width="1.5"/>
    <path d="M6 6.99324H15C17.8284 6.99324 19.2426 6.99324 20.1213 7.87234C21 8.75145 21 10.1663 21 12.9961V14.9971C21 17.8269 21 19.2418 20.1213 20.1209C19.2426 21 17.8284 21 15 21H9C5.22876 21 3.34315 21 2.17157 19.8279C1 18.6557 1 16.7692 1 12.9961V10.9952C1 7.22211 1 5.33558 2.17157 4.16344C3.11466 3.2199 4.52043 3.03589 7 3H9" stroke={stroke} stroke-width="1.5" stroke-linecap="round"/>
  </svg>
  );
}

export default PaymentIcon;
