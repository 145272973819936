import React, { useState } from "react";
import HalDatePicker from "../inputs/HalDatePicker";
import RevenueChartsComponent from "./RevenueChartsComponent";

function RevenueCharts() {
  const [revenueDuration, setRevenueDuration] = useState("12months");
  const durations = [
    { label: "12 months", value: "12months" },
    { label: "30 days", value: "30days" },
    { label: "7 days", value: "7days" },
  ];

  return (
    <div className="p-4 md:p-8 bg-white rounded-md shadow-md mt-8 max-w-screen-xl mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <h1 className="text-lg md:text-xl font-[600] mb-4 md:mb-0">
          Total Revenue
        </h1>

        <div className="flex justify-end items-center space-x-4">
          <div className="flex items-center border rounded-xl">
            {durations.map((duration, index) => (
              <div
                key={duration.value}
                style={{ cursor: "pointer" }}
                className={`${
                  revenueDuration === duration.value
                    ? "w-full md:w-28 h-12 bg-gray-100 rounded-lg flex items-center justify-center text-center"
                    : " w-full md:w-28 h-12 flex items-center justify-center text-center"
                }`}
                onClick={() => setRevenueDuration(duration.value)}
              >
                <p
                  style={{ color: "#667085", display: "flex" }}
                  className={`${
                    revenueDuration ? "text-black" : "text-[#667085]"
                  } font-semibold`}
                >
                  {duration.label}
                </p>
              </div>
            ))}
          </div>
          <div
            style={{
              border: "1px solid #D0D5DD",
              borderRadius: "7px",
              width: "100%",
              maxWidth: "130px", // Adjust max-width as needed
              height: "50px",
            }}
          >
            <HalDatePicker label="Date of Birth" id={"dob"} rightImage />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <RevenueChartsComponent />
      </div>
    </div>
  );
}

export default RevenueCharts;
