import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_UPDATE_PRODUCT_INVESTMENT } from "../../../config/api";
import { getInvestment } from "./getInvestment";

export const updateInvestment = createAsyncThunk(
  "investment/update",
  async (payload, {dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_UPDATE_PRODUCT_INVESTMENT + payload.id,
        data: payload.data,
        contentType: "multipart/form-data",
        serializerConfig: { indexes: null },
      });
      toast.success("The investment has been successfully updated");
      dispatch(getInvestment(payload?.id))
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
