import React from "react";

function UserIcon() {
  return (
    <div>
      <img
        src={"/Assets/userIcon.png"}
        alt="User Icon"
        style={{ width: "48px", height: "48px" }}
      />
    </div>
  );
}

export default UserIcon;
