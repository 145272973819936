import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserProfile,
  updateUserBvn,
  updateUserProfile,
} from "../actions/profile/updateProfile";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    error: null,
    profileDetails: {},
    pending: false,
    success: false,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(updateUserProfile.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(fetchUserProfile.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.pending = false;
      state.profileDetails = action.payload;
    });
    builder.addCase(fetchUserProfile.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(updateUserBvn.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(updateUserBvn.fulfilled, (state, action) => {
      state.pending = false;
      state.success = true;
    });
    builder.addCase(updateUserBvn.rejected, (state, action) => {
      state.rejected = false;
    });
  },
});

export default profileSlice.reducer;
