import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_TOGGLE_INVESTMENT } from "../../../config/api";
import { getInvestment } from "./getInvestment";

export const toggleInvestment = createAsyncThunk(
  "investmentProduct/toggle",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: API_TOGGLE_INVESTMENT + payload.id,
        data: payload.data,
      });
      dispatch(getInvestment(payload.id));
      return response?.data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
