import React from "react";
import AccountSummaryDetailTable from "../../components/dashboard/accountSummary/AccountSummaryDetailTable";
import Loader from "../../components/SharedLayouts/Loader";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import BackArrow2 from "../../components/BackArrow2";

const AccountSummaryDetail = () => {
  const loading = useSelector((state) => state.portfolio.pending) || false;

  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <div className="flex items-center mb-8 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <span className="font-semibold text-[#475467]text-sm sm:text-base bg-[#F9FAFB] p-1 rounded-md  w-[max-content]">
            Account Summary
          </span>
        </div>
        <h1 className="mb-6 font-[500] text-xl md:text-2xl lg:text-3xl">
          Account Summary Detail
        </h1>
        <BackArrow2 mb={10} ml={0} />
        {loading && <Loader />}

        <div className="mt-6">
          <AccountSummaryDetailTable />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AccountSummaryDetail;
