import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_GET_ALL_PACKAGES,
  API_SUBSCRIBE_PACKAGE,
} from "../../../config/api";

export const getPackages = createAsyncThunk(
  "packages/getPackages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_ALL_PACKAGES,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);
export const subscribe = createAsyncThunk(
  "packages/postPackages",
  async (data, { rejectWithValue }) => {
 
    try {
      const response = await Client({
        method: "POST",
        path: `${API_SUBSCRIBE_PACKAGE}${data?.id}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);
