import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../SharedLayouts/Loader";
import { BsThreeDots } from "react-icons/bs";
import { getBusinessUsers } from "../../../store/actions/userManagement/getBusinessUsers";
import BusinessUserMenu from "../../menus/BusinessUserMenu";

const HalcreditUserTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [masterPassword, setMasterPassword] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const { allUsersPending, businessUsers } = useSelector(
    (state) => state?.user
  );

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedUserId(Number(event.currentTarget.getAttribute("data-id")));

    setMasterPassword(row?.masterPassword);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";
    setSelectedUserId(params?.row?.user?.id);
    if (!isIconClick) {
      setSelectedUserId(params?.row?.user?.id);
      navigate(`/user-management-business/${params?.row?.user?.id}`);
    }
  };

  // const handleRowClick = (params, event) => {
  //   const isIconClick = event.target.getAttribute("data-is-icon") === "true";
  //   setSelectedUserId(params?.row?.id);

  //   if (!isIconClick) {
  //     setSelectedUserId(params?.row?.id);
  //     navigate(`/investment/${params?.row?.id}`);
  //   }

  //   const selectedInvestment = allInvestments.find(
  //     (req) => req.id === params?.row?.id
  //   );
  //   setSelectedRow(selectedInvestment);
  // };

  const columns = [
    {
      field: "id",
      headerName: "User ID",
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5">
            {params.row?.user?.id}
          </h3>
        );
      },
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5">
            {params.row?.user?.name?.split(" ")[0]}
          </h3>
        );
      },
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5">
            {params.row?.user?.name?.split(" ")[1]}
          </h3>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5 ">
            {params.row?.user?.email}
          </h3>
        );
      },
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 150,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5">
            {params.row?.phone_number}
          </h3>
        );
      },
    },
    {
      field: "business_name",
      headerName: "Business Name",
      width: 150,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5">
            {params.row?.business_name}
          </h3>
        );
      },
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: 150,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <h3 className="text-sm text-[#1C1C1C] font-[400] leading-5 ">
            {params.row?.dob}
          </h3>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderHeader: ({ colDef, field }) => (
        <h3 className="text-sm text-[rgba(0,0,0,0.4)] font-[500] leading-5">
          {colDef.headerName}
        </h3>
      ),
      renderCell: (params) => {
        return (
          <div className="flex gap-6 items-center">
            {params?.row?.status || true ? (
              <div className="w-[63px] h-[22px] bg-[#ECFDF3] rounded-[12px] flex justify-between items-center pl-[8px] pr-[8px]">
                <div className="bg-[#14BA6D] p-[2px] w-[6px] h-[6px] rounded-full"></div>
                <h4 className="text-[#037847] text-[11px] font-[500]">
                  Active
                </h4>
              </div>
            ) : (
              <div className="w-[73px] h-[22px] bg-[#F2F4F7] rounded-[12px] flex justify-between items-center pl-[8px] pr-[8px]">
                <div className="bg-[#6C778B] p-[2px] w-[6px] h-[6px] rounded-full"></div>
                <h4 className="text-[#364254] text-[11px] font-[500]">
                  Inactive
                </h4>
              </div>
            )}
            <div
              onClick={(e) => {
                e.target.setAttribute("data-is-icon", "true");
                handleContextMenu(e);
              }}
              style={{
                cursor: "pointer",
                position: "relative",
              }}
              // className="group relative "
            >
              <BsThreeDots color="#1C1C1C" size={24} />

              {/* <div className="absolute border-[1px] border-black-10 w-[0px] h-[0px]  group-focus:w-[168px] group-focus:h-auto  z-[2] group-focus:left-[-8.7rem] left-[30rem] py-[.5rem] bg-[red] transition-all ease-in-out duration-300 ">
                <div className="w-full px-[.5rem] border-b-[1px] pb-[.5rem] border-b-black-10 text-[0.75rem] text-black-100 font-[500]">
                  <span>Sort</span>
                </div>
                <div className="w-full h-full py-[.5rem] gap-[.25rem] px-[.5rem] flex flex-col">
                  <div className="w-4 h-4 bg-[red]"></div>
                </div>
              </div> */}
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getBusinessUsers());
  }, []);
  const { userGroupFilter } = useSelector((state) => state?.dashboard);

  return (
    <div className="mt-10 mb-8">
      {/* <div className="w-full overflow-x-auto mb-0">
        <div className=" flex cursor-pointer w-[max-content]">
          <span
            className={`${
              activeFilter === "all"
                ? "border-b-2 border-[#128591] text-[#128591] font-[600]"
                : "text-gray-700"
            } mr-3 text-center text-base  w-24`}
            onClick={() => setActiveFilter("all")}
          >
            All users
          </span>

          <span
            className={`${
              activeFilter === "verified"
                ? "border-b-2 border-[#128591]  text-[#128591] font-[600]"
                : "text-gray-700"
            } mr-3 text-base  text-center w-24`}
            onClick={() => setActiveFilter("verified")}
          >
            Is Verified
          </span>
        </div>
      </div> */}

      <div className="bg-white py-6 rounded-lg ">
        {allUsersPending && businessUsers?.length < 1 ? (
          <div className="h-10">
            <Loader />
          </div>
        ) : (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              columns={columns}
              rows={userGroupFilter === "business" ? businessUsers : []}
              // searchPlaceholder={"Search for users"}
              getRowId={(row) => row?.user?.id}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              handleRowClick={(params, event) => handleRowClick(params, event)}
            />
          </Box>
        )}
      </div>
      <BusinessUserMenu
        open={contextMenu !== null}
        onClose={handleClose}
        handleOpenUserDetails={() => {
          navigate(`/user-management-business/${selectedUserId}`);
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        handleClose={handleClose}
        selectedRow={selectedUserId}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
        style={{ border: "1px solid #CFD3D4" }}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default HalcreditUserTable;
