import React from "react";

const Loader = () => {
  return (
    <div className="min-w-[300px] flex justify-center items-center  min-h-[300px]">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          width="44px"
          height="44px"
          alt="Button Icon"
          src={"/Assets/loading2.gif"}
        />
      </div>
    </div>
  );
};

export default Loader;
