import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_FUND_WALLET_CONFIRMATION } from "../../../config/api";

export const fundWalletConfirmation = createAsyncThunk(
  "fundWallet/confirm",
  async ({data, callback}, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_FUND_WALLET_CONFIRMATION}${data.id}&currency=${data.currency}`,
        data,
      });
      toast.success("User Credited Successfully");
      callback()
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(msg);
    }
  }
);
