import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import UserCard from "../../components/cards/UserCard";
import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useCurrency from "../../hooks/useCurrency";
import ProvidusTable from "../../components/table/ProvidusTable";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";
import { getAllSettlementData } from "../../store/actions/settlement/getAllSettlementData";
import AdminUserIcon from "../../components/Icons/AdminUserIcon";

function ProvidusReport() {
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state?.user?.allUsers);
  const allSettlement = useSelector(
    (state) => state?.settlement?.allSettlement || []
  );
  const loading = useSelector((state) => state?.settlement?.loading);
  const [_currency, getAmount] = useCurrency();
  const user = useSelector((state) => state.onboarding.user);
  useEffect(() => {
    dispatch(getAllSettlementData());
  }, [dispatch]);

  const amount = allSettlement?.filter((item) => item?.settled_amount !== null);
  const CardDetails = [
    {
      id: 1,
      icon: <AdminUserIcon />,
      title: "Total Settlement",
      cardValue: allSettlement?.length,
    },
    {
      id: 2,
      icon: <AdminUserIcon />,
      title: "Total Transactions Amount",
      cardValue:
        _currency === "₦"
          ? `₦${getAmount(
              amount?.reduce((sum, item) => +item?.settled_amount + sum, 0),
              2
            )}`
          : `$${getAmount(
              amount?.reduce((sum, item) => +item?.settled_amount + sum, 0),
              2
            )}`,
    },
  ];

  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <div className="flex items-center mb-8 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <span className="font-semibold text-[#475467]text-sm sm:text-base bg-[#F9FAFB] p-1 rounded-md  w-[max-content]">
            Providus Report
          </span>
        </div>

        <h1 className="mb-6 font-[500] text-xl md:text-2xl lg:text-3xl">
          Providus Report
        </h1>
        {loading ? (
          <Loader />
        ) : (
          user?.roles &&
          !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {CardDetails?.map((detail, index) => (
                <div key={index} className="mb-4">
                  <UserCard
                    currency="NG"
                    currencyCode="NGN"
                    width={"100%"}
                    height={188}
                    icon={detail.icon}
                    title={detail.title}
                    cardValue={detail.cardValue}
                  />
                </div>
              ))}
            </div>
          )
        )}

        <div className="mt-6">
          {user?.roles &&
            !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
              <ProvidusTable allSettlement={allSettlement} loading={loading} />
            )}
          {/* <AccountSummaryTable allSettlement={allUsers} loading={loading} /> */}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ProvidusReport;
