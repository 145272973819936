import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function CurrencySwitch({ label, checked, onChange }) {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#159AA8",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 12,
      backgroundColor: "#fff",
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "blue"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>USD</Typography>
        <AntSwitch
          checked={checked}
          onChange={onChange}
          defaultChecked
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography>Naira</Typography>
      </Stack>
    </FormGroup>
  );
}

export default CurrencySwitch;
