import React, { useEffect, useState } from "react";
import TextInput from "./TextInput";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Check from "@mui/icons-material/Check";
import Popover from "@mui/material/Popover";
import { useFormik } from "formik";

const SelectInput = ({
  label,
  placeholder,
  id,
  options,
  selectedOption,
  handleChange,
  defaultValue,
  icon,
  hideError,
  blackBorder,
  form,
  disabled,
  setActiveInput,
  minWidth,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [_localOptions, setLocalOptions] = useState(options);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowOptions(!showOptions);
  };

  const handleSearchTextChange = (e) => {
    const text = e.target.value;

    if (text && text?.length < 1) {
      return;
    }
    const filteredOptions = [...options].filter((item) =>
      item.label.toLowerCase().includes(text.toLowerCase())
    );
    setLocalOptions(filteredOptions);
  };

  const handleSelectChange = (option) => {
    setActiveInput && setActiveInput("others");
    handleChange(option);
    setShowOptions(false);
  };

  const SelectForm = useFormik({
    initialValues: {
      selectInputSearchText: "",
    },
  });

  useEffect(() => {
    const text = SelectForm?.values.selectInputSearchText;
    if (text?.length > 0) {
      const filteredOptions = [...options].filter((item) =>
        item.label.toLowerCase().includes(text.toLowerCase())
      );
      setLocalOptions(filteredOptions);
    } else {
      setLocalOptions(options);
    }
  }, [SelectForm?.values.selectInputSearchText]);

  return (
    <>
      <div
        className={`select-box relative  ${
          blackBorder || (selectedOption && "input-border")
        } min-h-[58px] flex-1`}
        // style={{ marginBottom: !showOptions && "24px" }}

        onClick={disabled ? null : handleClick}
      >
        <div className="">
          <label htmlFor={id}>{label}</label>
          <div>
            <div className="selected-option">
              <h6 className=" text-[#000]">
                {defaultValue
                  ? _localOptions.find((item) => item.value === defaultValue)
                      ?.value
                  : selectedOption
                  ? selectedOption.label
                  : placeholder}
              </h6>
            </div>
          </div>
        </div>
        {icon && <img src={icon} alt="icon" width="24px" height="24px" />}
      </div>
      {form?.errors[id] && !hideError && (
        <div className="text-red-500 font-Source-Sans-Pro text-xs -mt-[15px] ml-2">
          {form.errors[id]}
        </div>
      )}
      <Popover
        open={showOptions}
        anchorEl={anchorEl}
        onClose={() => setShowOptions(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ flex: 1, width: "100%" }}
      >
        {options && options?.length > 20 && (
          <div className="px-3 py-2 bg-white z-[5]  fixed min-w-[300px]">
            <TextInput
              form={SelectForm}
              name={"selectInputSearchText"}
              widthAuto
              autoFucus={true}
              leftImage="/Assets/Search.png"
              placeholder={"Search"}
            />
          </div>
        )}
        <MenuList>
          <div
            className={`options min-w-[300px] mx-auto ${
              options && options?.length > 20 ? "mt-[62px]" : ""
            }  `}
          >
            {_localOptions?.map((option) => (
              <MenuItem
                onClick={() => handleSelectChange(option)}
                key={option.value}
              >
                <ListItemText> {option.label}</ListItemText>
              </MenuItem>
            ))}
          </div>
        </MenuList>
      </Popover>
    </>
  );
};

export default SelectInput;
