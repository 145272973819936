import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../../Helper/getCurrencySymbol";
import useCurrency from "../../../hooks/useCurrency";
import PayoutMenu from "../../menus/PayoutMenu";
import Loader from "../../SharedLayouts/Loader";
import Dots from "../../Icons/Dots";
import DividendModal from "../../modal/DividendModal";
import PayoutModal from "../../modal/PayoutModal";

function InvestmenDetailTable({ allUser }) {
  const { id } = useParams();

  const [activeFilter, setActiveFilter] = useState("all");
  const investment = useSelector((state) => state?.investment?.investment);
  const pending = useSelector((state) => state?.investment?.pending);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showManualPayoutModal, setShowManualPayoutModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [_currency, getAmount] = useCurrency();
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState();

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    setSelectedUserId(Number(event.currentTarget.getAttribute("data-id")));
    setSelectedRow(row);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";
    setSelectedUserId(params?.row?.id);

    if (!isIconClick) {
      setSelectedUserId(params?.row?.id);
      navigate(`/user-management/${params?.row?.id}`);
    }
  };

  const navigate = useNavigate();

  const handleClosePopover = () => {
    setShowOptions(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openDividendModal, setOpenDividendModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseDividendModal = () => {
    setOpenDividendModal(false);
  };

  const handlePayout = () => {
    if (investment?.investment_category?.name === "Startup Investing") {
      setOpenDividendModal(true);
      handleClose();
    } else {
      setOpenModal(true);
      handleClose();
    }
  };

  const rows =
    allUser?.map((user) => ({
      id: user?.user?.id,
      firstName: user?.user?.name?.split(" ")[0],
      lastName: user?.user?.name?.split(" ")[1],
      email: user?.user?.email,
      amount:
        getCurrencySymbol(investment?.currency) + " " + getAmount(user.amount),
      selectedPlan: user?.user?.selectedPlan,
    })) || [];

  const columns = [
    {
      field: "id",
      headerName: "Member ID",
      width: 150,
    },

    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "selectedPlan",
      headerName: "Membership Type",
      width: 150,
    },
    {
      renderCell: (params) => (
        <>
          <div
            style={{
              cursor: "pointer",
              position: "relative",
            }}
            onClick={(e) => {
              e.target.setAttribute("data-is-icon", "true");
              handleContextMenu(e, params?.row);
            }}
          >
            <Dots />
          </div>
        </>
      ),
    },
  ];

  const filteredRows =
    activeFilter === "all"
      ? rows
      : rows
          ?.map((row) => ({
            ...row,
            id: row?.userId,
          }))
          .filter(
            (row) => row?.MembershipStatus?.toLowerCase() === activeFilter
          );

  return (
    <div className="mt-10 mb-8">
      <div className="flex justify-between mb-8">
        {/* <div
          className="border p-2 rounded-lg shadow-sm bg-white w-12"
          style={{ width: "97px", cursor: "pointer" }}
          onClick={clearTable}
        >
          <div className="flex items-center ">
            <img
              src="/Assets/filter-lines.png"
              alt=""
              className="w-4 h-6 mr-2"
            />
            <span className="font-[600]">Filters</span>
          </div>
        </div> */}
      </div>
      <div className="bg-white">
        <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
          {pending ? (
            <Loader />
          ) : (
            // <CustomTable rows={filteredRows} columns={columns} />

            <GridTable
              type={"usersInvested"}
              rows={filteredRows}
              columns={columns}
              handleRowClick={handleRowClick}
            />
          )}
        </Box>
      </div>

      <PayoutMenu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        handleClose={handleClose}
        handlePayout={handlePayout}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
        anchorEl={anchorEl}
      />

      {openModal ? (
        <PayoutModal
          open={openModal}
          selectedRow={selectedRow}
          invID={id}
          onClose={handleCloseModal}
          setOpenModal={setOpenModal}
        />
      ) : (
        <></>
      )}
      {openDividendModal ? (
        <DividendModal
          open={openDividendModal}
          selectedRow={selectedRow}
          invID={id}
          onClose={handleCloseDividendModal}
          setOpenModal={setOpenDividendModal}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default InvestmenDetailTable;
