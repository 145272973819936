import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import AdminUserIcon from "../../components/Icons/AdminUserIcon";
import { getAllUser } from "../../store/actions/userManagement/management";
import CustomButton from "../../components/Buttons/Button";
import { Box } from "@mui/system";
import { Tab, Tabs } from "@mui/material";
import AboutInvestment from "../../components/investment/AboutInvestment";
import UserInvested from "../../components/investment/UserInvested";
import PayoutHistory from "../../components/investment/PayoutHistory";
import PaymentSchedule from "../../components/investment/PaymentSchedule";
import useCurrency from "../../hooks/useCurrency";
import InvestDetailCard from "../../components/cards/InvestDetailCard";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getInvestment } from "../../store/actions/investment/getInvestment";
import StartInvestmentModal from "../../components/modal/StartInvestmentModal";
import { toggleInvestment } from "../../store/actions/investment/toggleInvestment";
import { getPayoutHistory } from "../../store/actions/investment/getPayoutHistory";
import { deleteInvestment } from "../../store/actions/investment/deleteInvestment";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";
import { toggleInvestmentStatus } from "../../store/actions/investment/toggleInvestmentStatus";
import UserInterested from "../../components/investment/UserInterested";
import YouTube from "react-youtube";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";
import BackArrow2 from "../../components/BackArrow2";

function InvestmentDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const allUser = useSelector((state) => state?.user?.allUsers?.data);
  const loading = useSelector((state) => state.portfolio.pending) || false;

  const investment = useSelector((state) => state?.investment?.investment);
  const pending = useSelector((state) => state?.investment?.pending);
  const togglePending =
    useSelector((state) => state?.investment?.togglePending) || false;
  const toggleStatusPending =
    useSelector((state) => state?.investment?.toggleStatusPending) || false;
  const payoutHistory =
    useSelector((state) => state?.investment?.payoutHistory) || [];

  const [activeTab, setActiveTab] = useState("about");
  const [_currency, getAmount] = useCurrency();
  const [openModal, setOpenModal] = useState(false);
  const [openStartModal, setOpenStartModal] = useState(false);
  const { id } = useParams();
  const user = useSelector((state) => state.onboarding.user);

  const handleCloseStartModal = () => {
    setOpenStartModal(false);
  };

  useEffect(() => {
    dispatch(getAllUser());
    dispatch(getInvestment(id));
    dispatch(getPayoutHistory(id));
  }, []);

  const handleDelete = () => {
    dispatch(deleteInvestment({ id, navigate }));
    setShowDeleteModal(false);
  };

  const handleEditInvestment = () => {
    navigate("/edit-investment/" + id);
  };

  const handleToggle = () => {
    const data = {
      investment_status: investment?.investment_status === "1" ? "0" : "1",
    };
    dispatch(toggleInvestment({ data, id }));
  };

  const handleStatusToggle = () => {
    const data = {
      status: investment?.status === "1" ? "0" : "1",
    };
    dispatch(toggleInvestmentStatus({ data, id }));
  };

  const investDetails = [
    {
      id: 1,
      icon: <AdminUserIcon />,
      title: "Investment Target:",
      cardValue: investment?.maximum_amount,
    },
    {
      id: 2,
      icon: <AdminUserIcon />,
      title: "Amount:",
      cardValue: investment?.paymentSchedules?.total_amount,
    },

    {
      id: 3,
      title: "Amount Paid Out:",
      cardValue: payoutHistory?.total_amount_disbursed,
    },
    {
      id: 4,
      title: "Number of Investors:",
      cardContent: investment?.investments?.length,
    },
    {
      id: 5,
      title: "Investment Category:",
      cardContent: investment?.investment_category?.name,
    },
    {
      id: 6,
      title: "Investment Sector:",
      cardContent: investment?.sector,
    },
    {
      id: 7,
      title: "Payment Frequency:",
      cardContent: investment?.payment_frequency,
    },
    {
      id: 8,
      title: "Expected ROI:",
      cardContent: investment?.roi,
    },
    {
      id: 9,
      title: "Investment Duration:",
      cardContent: investment?.duration + " " + investment?.duration_type,
    },
    {
      id: 10,
      title: "Campaign Start Date:",
      cardContent: new Date(investment?.campaign_start_date).toDateString(),
    },
    {
      id: 11,
      title: "Campaign End Date:",
      cardContent: new Date(investment?.campaign_end_date).toDateString(),
    },
  ];

  const tabStyle = {
    backgroundColor: "#fff",
    width: "fit-content",
    // h: "32px",
    margin: "4px",

    textAlign: "center",
    // margin: "30px px",
    borderBottomColor: "transparent",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    textTransform: "capitalize",
    fontWeight: 600,
  };

  const generalStyle = {
    textTransform: "capitalize",
    fontSize: "14px",
    color: "#667085",
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getYoutubeVideoId = (url) => {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    const match = url?.match(regExp);

    return match ? match[1] : null;
  };
  return (
    <DashboardLayout>
      <div className="px-8">
        <div className="flex items-center mb-8">
          <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <Link
            to={"/investment"}
            className="font=[500] text-[#475467] text-base hidden sm:flex"
          >
            Investment
          </Link>
          <Link to={"/investment"}>
            <img
              src="/Assets/userDot.png"
              alt=""
              className="w-8 h-8 flex sm:hidden"
            />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <span className="font-[500] text-[#475467] text-base bg-[#F9FAFB] p-1 rounded-md ">
            Investment Detail
          </span>
        </div>
        <BackArrow2 mb={10} ml={0} />

        <div className="">
          <div className="flex flex-col md:flex-row flex-wrap justify-between items-center mb-4 w-full md:gap-10">
            <h1 className="mb-4 md:mb-0 font-[600] text-2xl md:text-3xl min-w-[max-content] capitalize">
              {investment?.name}
            </h1>

            <div className="w-full overflow-x-auto mb-4 md:mb-0">
              <div className="flex items-center w-[max-content] xl:w-full justify-end gap-[24px] ">
                {user?.roles &&
                  !user?.roles?.some(
                    (role) => role.name === "Admin_Tier_1"
                  ) && (
                    <div
                      onClick={() => setShowDeleteModal(true)}
                      className="cursor-pointer text-sm font-[600] text-[#D92D20] md:mb-0 md:mr-2 !w-[max-content]"
                    >
                      Delete Investment
                    </div>
                  )}

                {!pending && (
                  <>
                    <div className="md:mt-0 md:mr-3 ">
                      <CustomButton
                        variant="contained"
                        customColor="#159AA8"
                        children={
                          investment?.investment_status === "1"
                            ? "Close Investment"
                            : "Re-open Investment"
                        }
                        width="250px"
                        height="40px"
                        loading={togglePending}
                        padding="10px"
                        margin="0px 0"
                        color="#fff"
                        onClick={handleToggle}
                      />
                    </div>
                    <div className=" md:mt-0 md:mr-1">
                      <CustomButton
                        variant="contained"
                        customColor="#159AA8"
                        children={
                          investment?.status === "1"
                            ? "Suspend Investment"
                            : "Activate Investment"
                        }
                        width="250px"
                        height="40px"
                        loading={toggleStatusPending}
                        padding="10px"
                        margin="0px 0"
                        color="#fff"
                        onClick={handleStatusToggle}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="flex items-center w-[max-content] xl:w-full justify-end gap-8 ">
                <div className=" md:mt-0 md:ml-2">
                  <CustomButton
                    variant="outlined"
                    customColor="#fff"
                    children="Start Investment"
                    width="250px"
                    height="40px"
                    padding="10px"
                    margin="15px 0"
                    color="#159AA8"
                    onClick={() => setOpenStartModal(true)}
                  />
                </div>
                <div className=" md:mt-0 md:ml-2">
                  <CustomButton
                    variant="contained"
                    customColor="#159AA8"
                    children="Edit Investment"
                    width="250px"
                    height="40px"
                    padding="10px"
                    margin="15px 0"
                    color="#fff"
                    onClick={handleEditInvestment}
                  />
                </div>
              </div>
            </div>
          </div>

          {pending ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              {investDetails.map((detail) => (
                <div key={detail.id}>
                  <InvestDetailCard
                    width={"100%"}
                    height={84}
                    currency={investment?.currency}
                    icon={detail.icon}
                    title={detail.title}
                    cardValue={detail.cardValue}
                    cardContent={detail.cardContent}
                  />
                </div>
              ))}
            </div>
          )}

          {investment?.video_link && (
            <div className="my-5">
              <p className="text-lg mb-3 font-[500]">
                {investment?.video_title}
              </p>
              <YouTube
                videoId={getYoutubeVideoId(investment?.video_link)}
                opts={{ width: "100%", height: "300px" }}
              />
            </div>
          )}

          <div
            style={{
              backgroundColor: "#F9FAFB",
              marginTop: "80px",
              height: "54px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="About Investment"
                  value="about"
                  sx={
                    activeTab === "about"
                      ? { ...tabStyle, color: "#000" }
                      : generalStyle
                  }
                />
                <Tab
                  label="Users Interested"
                  value="interests"
                  sx={activeTab === "interests" ? tabStyle : generalStyle}
                />

                <Tab
                  label="Users Invested"
                  value="users"
                  sx={activeTab === "users" ? tabStyle : generalStyle}
                />
                <Tab
                  label="Payment Schedule"
                  value="schedule"
                  sx={activeTab === "schedule" ? tabStyle : generalStyle}
                />
                <Tab
                  label="Payout History"
                  value="history"
                  sx={activeTab === "history" ? tabStyle : generalStyle}
                />
              </Tabs>
            </Box>
          </div>

          <div className="mt-8">
            {activeTab === "about" && <AboutInvestment />}
            {activeTab === "interests" && <UserInterested />}
            {activeTab === "users" && <UserInvested />}
            {activeTab === "schedule" && <PaymentSchedule />}
            {activeTab === "history" && <PayoutHistory />}
          </div>
        </div>
        <StartInvestmentModal
          open={openStartModal}
          setOpenStartModal={setOpenStartModal}
          invID={id}
          name={investment?.name}
          invType={investment?.investment_type}
          payout_type={investment?.payout_type}
          currency={investment?.currency}
          amount={investment?.paymentSchedules?.total_amount}
          onClose={handleCloseStartModal}
        />
        {showDeleteModal && (
          <ConfirmDeleteModal
            open={showDeleteModal}
            handleDelete={handleDelete}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
      </div>
    </DashboardLayout>
  );
}

export default InvestmentDetail;
