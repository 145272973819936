import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_FUND_WALLET } from "../../../config/api";
import { GET_WALLET_BALANCE } from "../../../config/api";
import { toast } from "react-toastify";

export const addFunds = createAsyncThunk(
  "wallet/addFunds",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await Client({
      method: "POST",
      path: API_FUND_WALLET,
      data: payload.data,
    });

    toast.success("wallet funded");
    dispatch(getBalance());
    return response.data;
  }
);

export const getBalance = createAsyncThunk("wallet/getBalance", async () => {
  try {
    const response = await Client({
      method: "GET",
      path: GET_WALLET_BALANCE,
    });

     return response.data;
  } catch (error) {
    console.error("Error fetching balance:", error);
    throw error; // Re-throw the error to be captured by the component or handle it appropriately
  }
});
