import React from "react";

function PortfolioIcon({stroke}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M6 17L6 15" stroke={stroke} stroke-width="1.5" stroke-linecap="round"/>
    <path d="M11 17L11 14" stroke={stroke} stroke-width="1.5" stroke-linecap="round"/>
    <path d="M16 17L16 12" stroke={stroke} stroke-width="1.5" stroke-linecap="round"/>
    <path d="M5 9.75C4.58579 9.75 4.25 10.0858 4.25 10.5C4.25 10.9142 4.58579 11.25 5 11.25V9.75ZM15.6512 5.8721C15.8567 5.51247 15.7317 5.05433 15.3721 4.84882C15.0125 4.64331 14.5543 4.76826 14.3488 5.1279L15.6512 5.8721ZM12.8779 4.55478C12.4692 4.62219 12.1926 5.00815 12.26 5.41684C12.3274 5.82553 12.7134 6.10219 13.1221 6.03478L12.8779 4.55478ZM14.2676 5.08568L14.1456 4.34568L14.2676 5.08568ZM15.6819 5.88953L14.9609 6.09609L15.6819 5.88953ZM15.279 7.20656C15.3931 7.60475 15.8084 7.83507 16.2066 7.72099C16.6048 7.60692 16.8351 7.19164 16.721 6.79344L15.279 7.20656ZM15.2741 5.07154L15.6448 4.41951L15.6447 4.41951L15.2741 5.07154ZM5 11.25C9.67417 11.25 13.4764 9.67797 15.6512 5.8721L14.3488 5.1279C12.5236 8.32203 9.32583 9.75 5 9.75V11.25ZM13.1221 6.03478L14.3897 5.82568L14.1456 4.34568L12.8779 4.55478L13.1221 6.03478ZM14.9609 6.09609L15.279 7.20656L16.721 6.79344L16.4029 5.68297L14.9609 6.09609ZM14.3897 5.82568C14.6742 5.77874 14.8228 5.75549 14.9273 5.75072C15.0142 5.74675 14.9719 5.76242 14.9035 5.72357L15.6447 4.41951C15.3693 4.26294 15.0902 4.24172 14.8589 4.25228C14.6451 4.26203 14.394 4.30471 14.1456 4.34568L14.3897 5.82568ZM16.4029 5.68297C16.3421 5.47103 16.2761 5.2338 16.1891 5.04105C16.0889 4.81911 15.9304 4.58186 15.6448 4.41951L14.9035 5.72357C14.8732 5.70635 14.8458 5.68248 14.8263 5.6588C14.8103 5.63917 14.8103 5.63246 14.8219 5.65818C14.8342 5.68528 14.8513 5.73033 14.8759 5.80783C14.9004 5.88523 14.9266 5.97645 14.9609 6.09609L16.4029 5.68297Z" fill={stroke}/>
    <path d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z" stroke={stroke} stroke-width="1.5"/>
  </svg>
  );
}

export default PortfolioIcon;
