import React from "react";
import UserTable from "../dashboard/user/UserTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllUser } from "../../store/actions/userManagement/management";
import InvestmenDetailTable from "../dashboard/investment/InvestmentDetailTable";

function UserInvested() {
  const investment = useSelector((state) => state?.investment?.investment);

  return (
    <div>
      <div className="mt-10 ">
        <InvestmenDetailTable allUser={investment?.investments} />
      </div>
    </div>
  );
}

export default UserInvested;
