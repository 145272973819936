import React from "react";
import { useLocation } from "react-router-dom";

const HomeIcon = ({stroke}) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M5.03656 0.25C5.7757 0.249988 6.38322 0.249978 6.87471 0.296709C7.38544 0.34527 7.84779 0.449535 8.26834 0.70725C8.68586 0.963105 9.0369 1.31414 9.29275 1.73166C9.55047 2.15221 9.65473 2.61456 9.70329 3.12529C9.75002 3.61678 9.75001 4.22429 9.75 4.96343V4.96344V5.03656V5.03658C9.75001 5.77571 9.75002 6.38322 9.70329 6.87471C9.65473 7.38544 9.55047 7.84779 9.29275 8.26834C9.0369 8.68586 8.68586 9.0369 8.26834 9.29275C7.84779 9.55047 7.38544 9.65473 6.87471 9.70329C6.38322 9.75002 5.77571 9.75001 5.03658 9.75H5.03656H4.96344H4.96343C4.22429 9.75001 3.61678 9.75002 3.12529 9.70329C2.61456 9.65473 2.15221 9.55047 1.73166 9.29275C1.31414 9.0369 0.963105 8.68586 0.70725 8.26834C0.449535 7.84779 0.34527 7.38544 0.296709 6.87471C0.249978 6.38322 0.249988 5.7757 0.25 5.03656V5.03655V4.96345V4.96344C0.249988 4.2243 0.249978 3.61679 0.296709 3.12529C0.34527 2.61456 0.449535 2.15221 0.70725 1.73166C0.963105 1.31414 1.31414 0.963105 1.73166 0.70725C2.15221 0.449535 2.61456 0.34527 3.12529 0.296709C3.61678 0.249978 4.2243 0.249988 4.96344 0.25H4.96345H5.03655H5.03656Z" fill={stroke}/>
    <path d="M5.03656 12.25C5.7757 12.25 6.38322 12.25 6.87471 12.2967C7.38544 12.3453 7.84779 12.4495 8.26834 12.7072C8.68586 12.9631 9.0369 13.3141 9.29275 13.7317C9.55047 14.1522 9.65473 14.6146 9.70329 15.1253C9.75002 15.6168 9.75001 16.2243 9.75 16.9634V16.9634V17.0366V17.0366C9.75001 17.7757 9.75002 18.3832 9.70329 18.8747C9.65473 19.3854 9.55047 19.8478 9.29275 20.2683C9.0369 20.6859 8.68586 21.0369 8.26834 21.2928C7.84779 21.5505 7.38544 21.6547 6.87471 21.7033C6.38322 21.75 5.77571 21.75 5.03658 21.75H5.03656H4.96344H4.96343C4.22429 21.75 3.61678 21.75 3.12529 21.7033C2.61456 21.6547 2.15221 21.5505 1.73166 21.2928C1.31414 21.0369 0.963105 20.6859 0.70725 20.2683C0.449535 19.8478 0.34527 19.3854 0.296709 18.8747C0.249978 18.3832 0.249988 17.7757 0.25 17.0366V17.0365V16.9635V16.9634C0.249988 16.2243 0.249978 15.6168 0.296709 15.1253C0.34527 14.6146 0.449535 14.1522 0.70725 13.7317C0.963105 13.3141 1.31414 12.9631 1.73166 12.7072C2.15221 12.4495 2.61456 12.3453 3.12529 12.2967C3.61678 12.25 4.2243 12.25 4.96344 12.25H4.96345H5.03655H5.03656Z" fill={stroke}/>
    <path d="M17.0366 0.25C17.7757 0.249988 18.3832 0.249978 18.8747 0.296709C19.3854 0.34527 19.8478 0.449535 20.2683 0.70725C20.6859 0.963105 21.0369 1.31414 21.2928 1.73166C21.5505 2.15221 21.6547 2.61456 21.7033 3.12529C21.75 3.61678 21.75 4.22429 21.75 4.96343V4.96344V5.03656V5.03658C21.75 5.77571 21.75 6.38322 21.7033 6.87471C21.6547 7.38544 21.5505 7.84779 21.2928 8.26834C21.0369 8.68586 20.6859 9.0369 20.2683 9.29275C19.8478 9.55047 19.3854 9.65473 18.8747 9.70329C18.3832 9.75002 17.7757 9.75001 17.0366 9.75H17.0366H16.9634H16.9634C16.2243 9.75001 15.6168 9.75002 15.1253 9.70329C14.6146 9.65473 14.1522 9.55047 13.7317 9.29275C13.3141 9.0369 12.9631 8.68586 12.7072 8.26834C12.4495 7.84779 12.3453 7.38544 12.2967 6.87471C12.25 6.38322 12.25 5.7757 12.25 5.03656V5.03655V4.96345V4.96344C12.25 4.2243 12.25 3.61679 12.2967 3.12529C12.3453 2.61456 12.4495 2.15221 12.7072 1.73166C12.9631 1.31414 13.3141 0.963105 13.7317 0.70725C14.1522 0.449535 14.6146 0.34527 15.1253 0.296709C15.6168 0.249978 16.2243 0.249988 16.9634 0.25H16.9635H17.0365H17.0366Z" fill={stroke}/>
    <path d="M17.0366 12.25C17.7757 12.25 18.3832 12.25 18.8747 12.2967C19.3854 12.3453 19.8478 12.4495 20.2683 12.7072C20.6859 12.9631 21.0369 13.3141 21.2928 13.7317C21.5505 14.1522 21.6547 14.6146 21.7033 15.1253C21.75 15.6168 21.75 16.2243 21.75 16.9634V16.9634V17.0366V17.0366C21.75 17.7757 21.75 18.3832 21.7033 18.8747C21.6547 19.3854 21.5505 19.8478 21.2928 20.2683C21.0369 20.6859 20.6859 21.0369 20.2683 21.2928C19.8478 21.5505 19.3854 21.6547 18.8747 21.7033C18.3832 21.75 17.7757 21.75 17.0366 21.75H17.0366H16.9634H16.9634C16.2243 21.75 15.6168 21.75 15.1253 21.7033C14.6146 21.6547 14.1522 21.5505 13.7317 21.2928C13.3141 21.0369 12.9631 20.6859 12.7072 20.2683C12.4495 19.8478 12.3453 19.3854 12.2967 18.8747C12.25 18.3832 12.25 17.7757 12.25 17.0366V17.0365V16.9635V16.9634C12.25 16.2243 12.25 15.6168 12.2967 15.1253C12.3453 14.6146 12.4495 14.1522 12.7072 13.7317C12.9631 13.3141 13.3141 12.9631 13.7317 12.7072C14.1522 12.4495 14.6146 12.3453 15.1253 12.2967C15.6168 12.25 16.2243 12.25 16.9634 12.25H16.9635H17.0365H17.0366Z" fill={stroke}/>
  </svg>
  );
};

export default HomeIcon;
