import React, { useEffect, useState } from "react";
import Loader from "../../SharedLayouts/Loader";
import Dots from "../../Icons/Dots";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllWithdrawalReqs } from "../../../store/actions/withdrawal/fetchAllWithdrawalReqs";
import WithdrawalMenu from "../../menus/WithdrawalMenu";
import ApprovewithdrawalModal from "../../modal/ApproveWithdrawalModal";
import useCurrency from "../../../hooks/useCurrency";
import CancelWithdrawal from "../../modal/CancelWithdrawal";
import { getCurrencySymbol } from "../../../Helper/getCurrencySymbol";

function WithdrawalTable() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [activeFilter, setActiveFilter] = useState("all");
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedWithdrawalId, setSelectedWithdrawalId] = useState(null);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [cancelWithdrawal, setCancelWithdrawal] = useState(false);
  const [, setShowPopper] = useState(false);
  const [_currency, getAmount] = useCurrency();

  const loading = useSelector((state) => state?.withdrawalRequests?.pending);
  const withdrawalRequests =
    useSelector((state) => state?.withdrawalRequests?.allrequests) || [];
  const { withdrawalInfo } = useSelector((state) => state?.withdrawalRequests);

  const user = useSelector((state) => state.onboarding.user);

  useEffect(() => {
    dispatch(fetchAllWithdrawalReqs());
  }, [dispatch]);

  useEffect(() => {
    if (withdrawalRequests) {
      const rows = withdrawalRequests;

      setFilteredRows(rows);
    }
  }, [withdrawalRequests, getAmount]);

  const handleContextMenu = (event, params) => {
    event.preventDefault();
    setSelectedRow(params.row);
    setSelectedWithdrawalId(params.row.id);
    if (
      params.row.requestStatus !== "approved" &&
      params.row.requestStatus !== "cancelled"
    ) {
      setContextMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    } else {
      setContextMenu(null);
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleApproveWithdrawal = () => {
    setShowApproveModal(true);
    handleClose();
  };

  const handleCancelWithdrawal = () => {
    setCancelWithdrawal(true);
    handleClose();
  };

  const columns = [
    {
      field: "userId",
      headerName: "User ID",
      renderCell: (params) => params.row?.id,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      renderCell: (params) => params.row?.user?.name?.split(" ")[0],
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      renderCell: (params) => params.row?.user?.name?.split(" ")[1],
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderCell: (params) => params.row?.user?.email,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) =>
        `${getCurrencySymbol(params.row?.currency)} ${getAmount(
          params.row?.amount
        )}`,
    },
    {
      field: "withdrawalDate",
      headerName: "Withdrawal Date",
      width: 200,
      renderCell: (params) =>
        new Date(params.row?.withdrawal_date)?.toDateString(),
    },
    {
      field: "requestStatus",
      headerName: "Request Status",
      width: 150,
      renderCell: (params) => {
        const status = params.row?.status;
        return (
          <div className="flex">
            <div
              className="px-3 py-0.5"
              style={{
                borderRadius: "20px",
                marginRight: "10px",
                color:
                  status === "cancelled"
                    ? "#B42318"
                    : status === "processing"
                    ? "#9b6700"
                    : "green",
                backgroundColor:
                  status === "approved"
                    ? "#00800052"
                    : status === "Processing"
                    ? "#aea22d30"
                    : status === "cancelled"
                    ? "#f5f0f0"
                    : "",
              }}
            >
              {status === "pending"
                ? "Pending"
                : status === "cancelled"
                ? "Cancelled"
                : status === "approved"
                ? "Approved"
                : "Processing"}
            </div>
          </div>
        );
      },
    },
    {
      width: 40,
      renderCell: (params) => (
        <>
          {user?.roles &&
            !user?.roles?.some((role) => role.name === "Admin_Tier_1") && (
              <div
                style={{ cursor: "pointer", position: "relative" }}
                onClick={(e) => handleContextMenu(e, params)}
              >
                <Dots />
              </div>
            )}
        </>
      ),
    },
  ];

  return (
    <div className="mt-10 mb-8">
      <div className="bg-white">
        {!loading ? (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              rows={filteredRows}
              columns={columns}
              pageInfo={withdrawalInfo}
              getRowId={(row) => row.id}
              // onRowClick={handleRowClick}
            />
          </Box>
        ) : (
          <Loader />
        )}
      </div>
      <WithdrawalMenu
        open={contextMenu !== null}
        onClose={handleClose}
        selectedRow={selectedRow}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        handleClose={handleClose}
        handleApproveWithdrawal={handleApproveWithdrawal}
        handleCancelWithdrawal={handleCancelWithdrawal}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
        anchorEl={anchorEl}
      />
      {showApproveModal && (
        <ApprovewithdrawalModal
          open={showApproveModal}
          selectedRow={selectedRow}
          setShowApproveModal={setShowApproveModal}
          id={selectedWithdrawalId}
          onClose={() => setShowApproveModal(false)}
        />
      )}
      {cancelWithdrawal && (
        <CancelWithdrawal
          open={cancelWithdrawal}
          selectedRow={selectedRow}
          setCancelWithdrawal={setCancelWithdrawal}
          id={selectedWithdrawalId}
          onClose={() => setCancelWithdrawal(false)}
        />
      )}
    </div>
  );
}

export default WithdrawalTable;
