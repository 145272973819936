import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "../../store/actions/userManagement/management";
import useCurrency from "../../hooks/useCurrency";
import { fetchAllTransactions } from "../../store/actions/transactions/fetchAllTransactions";
import { getAllPayout } from "../../store/actions/transactions/getAllPayout";
import { DataGrid } from "@mui/x-data-grid";
import SelectField from "../../components/inputs/SelectField";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";
import { addBulkPreviousRecords } from "../../store/actions/investment/addBulkPreviousRecords";
import { paymentType } from "../../config/data";
import { getInvestment } from "../../store/actions/investment/getInvestment";
import CustomButton from "../../components/Buttons/Button";

import ExcelImportComponent from "../../components/ImportExcel";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";
import { Modal } from "@mui/material";
import UploadDocumentModal from "../../components/modal/UploadDocumentModal";
import RecordGrid from "./RecordGrid";

function PreviousRecords() {
  const dispatch = useDispatch();

  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const [_currency, getAmount] = useCurrency();
  const [selectedId, setSelectedId] = useState();
  const [startupInvesting, setStartupInvesting] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [convertedFile, setConvertedFile] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const { allUsers: allUser, allUsersInfo } = useSelector(
    (state) => state?.user
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);

  const [allUsers, setAllUsers] = useState(allUser);
  const investments = useSelector((state) => state?.investment?.allInvestments);
  const investment = useSelector((state) => state?.investment?.investment);

  const paymentSchedules =
    investment?.paymentSchedules?.expected_date?.map((item, index) => ({
      ...item,
      amount: item?.amount,
      // roi: Number(investment?.roi).toFixed(0) + "%",
      date: new Date(item?.date),
    })) || [];
  const loading = useSelector((state) => state?.investment?.pending);
  const { id } = useParams();

  useEffect(() => {
    if (allUser) setAllUsers(allUser);
  }, [allUser]);

  useEffect(() => {
    dispatch(getAllProductInvestment());
  }, []);
  useEffect(() => {
    if (investment?.investment_category?.id === 3) {
      setStartupInvesting(true);
    } else {
      setStartupInvesting(false);
    }
  }, [investment?.investment_category?.id]);

  useEffect(() => {
    if (selectedId) dispatch(getInvestment(selectedId));
  }, [selectedId, dispatch]);

  useEffect(() => {
    dispatch(getAllUser());
    dispatch(fetchAllTransactions(currency));
    dispatch(getAllPayout());
  }, [currency, dispatch]);

  useEffect(() => {
    if (selectedIds?.length > 0) {
      const updatedInvestmentData = selectedIds?.map((user) => ({
        user_id: user?.id,
        transaction_id: "",
        amount: 10000,
        disbursed_amount: 0.0,
        payment_date: "2024-01-15",
      }));

      form.setFieldValue("investment_data", updatedInvestmentData);
    }
  }, [selectedIds, dispatch]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleDocumentManagement = (user) => {
    setSelectedUser(user);
    setSelectedFiles(user?.user_documents);
    setDocumentModalOpen(true);
  };

  const closeDocumentModal = () => {
    setSelectedUser(null);
    setDocumentModalOpen(false);
    setSelectedFiles([]); // Clear selected files when the modal is closed
  };

  const handleFileChange = (e) => {
    const files = e?.dataTransfer?.files || e?.target?.files;
    if (files && files.length > 0) {
      if (selectedFiles?.length < 4) {
        const newFileObject = {
          document_name: files[0]?.name,
          document_file: files[0],
        };

        setSelectedFiles((prevFiles) => [...prevFiles, newFileObject]);
      } else {
        const newFileObject = {
          document_name: files[0]?.name,
          document_file: files[0],
        };

        setSelectedFiles([newFileObject]);
      }
    }
  };

  const deleteFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const columns = [
    { field: "name", headerName: "Name", width: 180, editable: false },
    { field: "email", headerName: "Email", width: 180, editable: false },

    {
      field: "amount",
      headerName: "Amount Invested",
      type: "number",
      width: 210,
      editable: true,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => `${_currency} ${getAmount(params.row.amount)}`,
    },
    {
      field: "disbursed_amount",
      headerName: "Amount Disbursed",
      type: "number",
      width: 210,
      editable: true,
      align: "left",
      headerAlign: "left",
      renderCell: (params) =>
        `${_currency} ${getAmount(params.row.disbursed_amount)}`,
    },

    {
      field: "payment_date",
      headerName: "Date Paid",
      type: "date",
      width: 180,
      editable: true,
    },
    ...(startupInvesting
      ? [
          {
            field: "documents",
            headerName: "Documents",
            width: 150,
            renderCell: (params) => (
              <button
                onClick={() => handleDocumentManagement(params.row)}
                className="border p-2 rounded-[8px]"
              >
                Manage Documents
              </button>
            ),
          },
        ]
      : []),
  ];

  const columns2 = [
    {
      field: "repayment_type",
      headerName: "Repayment Type",
      width: 180,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Amount to be paid",
      type: "number",
      width: 150,
      editable: true,
      align: "left",
      headerAlign: "left",
      renderCell: (params) =>
        `${getCurrencySymbol(investment?.currency)} ${getAmount(
          params.row.amount
        )}`,
    },
    {
      field: "date",
      headerName: "Date Paid",
      type: "date",
      width: 180,
      editable: true,
    },
  ];

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const getDisbursedAmount = (amount) => {
    const payment_type = investment?.paymentSchedules?.payment_type;
    const roi = Number(investment?.roi);
    const duration = investment?.duration;
    const profit = (roi / 100) * amount;
    if (payment_type === "PACM") {
      const repayment = 0;
    }

    return 0;
  };
  const handleBulkSubmit = (values) => {
    // const bulkData = {
    //   investment_product_id: values.investment_product_id.value,
    //   disbursed_date: new Date().toISOString().substring(0, 10),
    //   investment_data: allUsers.filter((item) => item?.selected),
    // };
    const investment_data = allUsers.filter((item) => item?.selected);

    const formData = new FormData();

    formData.append(
      "investment_product_id",
      values.investment_product_id.value
    );
    // formData.append('disbursed_date', new Date().toISOString().substring(0, 10));

    investment_data?.forEach((user, index) => {
      formData.append(`investment_data[${index}][user_id]`, user.user_id);
      // formData.append(`investment_data[${index}][transaction_id]`, user.transaction_id);
      formData.append(`investment_data[${index}][amount]`, user.amount);
      formData.append(
        `investment_data[${index}][disbursed_amount]`,
        user.disbursed_amount
      );
      formData.append(
        `investment_data[${index}][payment_date]`,
        new Date(user?.payment_date).toISOString().substring(0, 10)
      );

      if (user.user_documents) {
        user?.user_documents?.forEach((document, docIndex) => {
          formData.append(
            `document_name[${user.user_id}][${docIndex}]`,
            document.document_name
          );
          formData.append(
            `document_file[${user.user_id}][${docIndex}]`,
            document.document_file
          );
        });
      }
    });

    dispatch(addBulkPreviousRecords({ data: formData }));
  };

  const processRowUpdate = (newRow) => {
    const disbursed_amount = getDisbursedAmount(newRow.amount);
    const updatedRow = {
      ...newRow,
      isNew: false,
      disbursed_amount,
    };
    setAllUsers(
      allUsers?.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const form = useFormik({
    initialValues: {
      investment_product_id: null,
      disbursed_date: null,
      investment_data: [
        {
          user_id: "",
          transaction_id: "",
          amount: "",
          disbursed_amount: 0.0,
          payment_date: "",
        },
      ],
    },
    onSubmit: (values) => handleBulkSubmit(values),
  });

  const onRowsSelectionHandler = (ids) => {
    setAllUsers(
      allUsers?.map((row) =>
        ids.includes(row?.id)
          ? { ...row, selected: true }
          : { ...row, selected: false }
      )
    );
  };

  const handleUserDataSelected = (excelData) => {
    const userData = excelData.slice(0, -1);
    setAllUsers([]);
    const data = userData?.map((row) => row);

    const final = allUser?.map((user) => {
      let foundUser = data.filter((item) => user.email.includes(item[0]));
      if (foundUser && foundUser[0] && foundUser[0][2]) {
        return {
          id: user?.id,
          user_id: user?.id,
          name: user?.name,
          amount:
            foundUser && foundUser[0] && foundUser[0][2] ? foundUser[0][2] : 0,
          email: user?.email,
          disbursed_amount: 0,
          selected: foundUser && foundUser[0] && foundUser[0][2] ? true : false,
          payment_date: new Date(),
          user_documents: null,
        };
      } else {
        return false;
      }
    });

    setAllUsers(final.filter((item) => item !== false));

    setSelectionModel(final?.map((item) => item?.selected && item?.id));
  };

  const saveDocuments = () => {
    if (selectedUser) {
      const updatedUsers = allUsers?.map((user) =>
        user.id === selectedUser.id
          ? { ...user, user_documents: selectedFiles }
          : user
      );
      setAllUsers(updatedUsers);
    }
    closeDocumentModal();
  };

  return (
    <>
      <DashboardLayout>
        <div className="px-4 lg:px-8 flex flex-col gap-10">
          <div className="flex items-center mb-8 md:flex-row md:items-center w-full">
            <Link to={"/dashboard"}>
              <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
            </Link>
            <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
            <span className="font-semibold text-[#475467] text-sm sm:text-base bg-[#F9FAFB] p-1 rounded-md  w-[max-content]">
              Previous Records
            </span>
          </div>

          <div className="flex justify-between items-center flex-wrap ">
            <h1 className="mb-2 lg:mb-0 font-[500] text-2xl lg:text-3xl">
              Previous records
            </h1>
          </div>
          <ExcelImportComponent onExcelDataChange={handleUserDataSelected} />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <RecordGrid
              type={"users"}
              rows={allUsers || []}
              newrows={allUser}
              searchValue={searchValue}
              searchPlaceholder={"Search for users"}
              pageInfo={allUsersInfo}
              columns={columns}
              getRowId={(row) => row.id}
              componentsProps={{
                row: {
                  onContextMenu: () => {},
                  style: { cursor: "context-menu" },
                },
              }}
              handleRowClick={() => {}}
            />

            <div style={{ height: 520 }}>
              <div>
                <label>Select Investment</label>
                <div className="mb-4">
                  <SelectField
                    id="investment_product_id"
                    onChange={(e) => {
                      setSelectedId(e.target?.value?.value);
                    }}
                    options={investments?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    form={form}
                    sx={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "7px",
                      borderColor: "#D0D5DD",
                      color: "#101828",
                    }}
                    fw={400}
                    fz={"16px"}
                  />
                  <div className="mt-4">
                    <span>
                      <b>Payment Type: </b>
                      {
                        paymentType.filter(
                          (item) =>
                            item.value ===
                            investment?.paymentSchedules?.payment_type
                        )[0]?.label
                      }
                    </span>
                  </div>
                  <div className="mt-4">
                    <span>
                      <b>Total amount invested: </b>
                      {getCurrencySymbol(investment?.currency)}
                      {getAmount(investment?.paymentSchedules?.total_amount) ||
                        0.0}
                    </span>
                  </div>
                </div>
              </div>

              <DataGrid
                rows={paymentSchedules}
                columns={columns2}
                pageSizeOptions={[10]}
              />
            </div>
          </div>

          <div className=" w-full mt-[85px] ml-auto">
            <div>
              <span>
                Total Amount entered:{" "}
                <strong>
                  {getCurrencySymbol(investment?.currency)}

                  {getAmount(
                    allUsers
                      ?.filter((user) => user?.selected)
                      ?.reduce((sum, item) => sum + +item?.amount, 0)
                  )}
                </strong>
              </span>
            </div>
            <div className=" ml-auto">
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children="Submit"
                loading={loading}
                width="200px"
                height="45px"
                padding="10px"
                margin="15px 0"
                color="#fff"
                onClick={form.handleSubmit}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>

      {isDocumentModalOpen && (
        <UploadDocumentModal
          open={isDocumentModalOpen}
          onClose={() => setDocumentModalOpen(false)}
          deleteFile={deleteFile}
          selectedFiles={selectedFiles}
          selectedUser={selectedUser}
          saveDocuments={saveDocuments}
          handleFileChange={handleFileChange}
        />
      )}
    </>
  );
}

export default PreviousRecords;
