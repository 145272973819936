import React from "react";
import * as XLSX from "xlsx";
import CustomButton from "../Buttons/Button";

const ExportExcel = ({ columns, type, rows }) => {
  const exportData = (columns, type, rows) => {
    if (!rows || !columns) return;

    const wb = XLSX.utils.book_new();

    if (type === "usersIntrested" || type === "usersInvested") {
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(rows));
      XLSX.writeFileXLSX(wb, "Data.xlsx");
    } else {
      const sheetData = [
        columns?.map((col) => col?.headerName),
        ...rows.map((row) =>
          type === "bip"
            ? [row?.id, row?.email]
            : type === "users"
            ? [
                row?.id,
                row?.name?.split(" ")[0],
                row?.name?.split(" ")[1],
                row?.email,
                row?.phone,
                row?.profile?.BVN ? "YES" : "NO",
                new Date(row?.account_creation_date).toDateString(),

                row?.selectedPlan === "free"
                  ? "Non-member"
                  : row?.selectedPlan === null ||
                    row?.investorProfile === null ||
                    row?.hasProfileCompleted === false
                  ? "Incomplete"
                  : "Member",
                row?.free_approved_plan === "approved" &&
                row?.selectedPlan === "free"
                  ? "Approved"
                  : row?.free_approved_plan !== "approved"
                  ? "Pending"
                  : row?.selectedPlan !== "free" && row?.selectedPlan !== null
                  ? "Subscribed"
                  : null,
              ]
            : columns?.map((col) => "--")
        ),
      ];

      const sheet = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(wb, sheet);
      XLSX.writeFile(wb, "Data.xlsx");
    }
  };

  return (
    <div
      className="flex items-center border py-2 px-4 rounded-lg cursor-pointer"
      onClick={() => exportData(columns, type, rows)}
    >
      <img
        src="/Assets/cloud.png"
        alt=""
        className="object-contain w-4 h-6 mr-2"
      />
      <span className="font-[500] text-[10px] sm:text-sm">Download Report</span>
    </div>
  );
};

export default ExportExcel;

// import React from 'react'
// import * as XLSX from 'xlsx';
// import CustomButton from '../Buttons/Button';

// const ExportExcel = ({rows}) => {

//     const exportData = () => {
//         const wb = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(rows));
//         XLSX.writeFileXLSX(wb, "Data.xlsx");
//       }

//   return (
//     <div className="flex items-center border p-2 rounded-lg cursor-pointer" onClick={exportData}>
//     <img src="/Assets/cloud.png" alt="" className="w-4 h-6 mr-2" />
//     <span className="font-[600] text-[10px] sm:text-base">Download Report</span>
//   </div>
//   )
// }
