import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useCurrency = () => {
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  // const currency = "NGR";
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(currency === "USD" ? "$" : "₦");
  }, [currency]);

  return [data, convertAmount];
};
const convertAmount = (digit, decimalPlaces = 0, toKobo = false) => {
  const unit = 100;
  let amount = toKobo ? Number(digit) * unit : Number(digit);

  return (
    amount.toFixed(decimalPlaces).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ??
    0.0
  );
};
export default useCurrency;
