import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";

import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "../../store/actions/userManagement/management";
import { Link } from "react-router-dom";

import { getUserAnalytcs } from "../../store/actions/userManagement/getUserAnalytcs";
import WaitingUsers from "../../components/dashboard/user/WaitingUsers";

const WaitList = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.portfolio.pending) || false;
  const {
    allUsers: allUser,
    pending,
    getUserAnalytcsData,
    allUsersPending,
  } = useSelector((state) => state?.user) || [];

  const [userGroup, setUserGroup] = useState("halvest");

  useEffect(() => {
    dispatch(getAllUser());
    dispatch(getUserAnalytcs());
  }, []);
  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <div className="flex items-center mb-6 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <div className=" font-semibold text-[#475467] text-sm sm:text-base bg-[#F9FAFB] p-1 px-3 rounded-md  ">
            Waitlist
          </div>
        </div>

        {(loading || pending) && <Loader />}

        <>
          <div className="mt-6">
            <WaitingUsers />
          </div>
        </>
      </div>
    </DashboardLayout>
  );
};

export default WaitList;
