import React, { useEffect, useState } from "react";
import SimpleButton from "../Buttons/SimpleButton";
// import { showModal } from "../../store/reducers/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const WithStatusAndButton = ({
  cardTitle,
  btnTitle,
  status,
  bgColor,
  route,
  info,
  disabled,
  onClick,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.onboarding.user);

  const userID = user && user[0]?.user._id;

  const transactions = useSelector(
    (state) => state?.transactions?.transactions
  );

  const getStatus = transactions && transactions[0]?.status;

  useEffect(() => {
    // dispatch(fetchTransactions());
  }, []);

  const isCompleted =
    status === "Completed" ||
    status === "Uploaded" ||
    status === "Verified" ||
    status === "Done" ||
    status === "Eligible";

  // const [isButtonDisabled, setIsButtonDisabled] = useState(isCompleted);

  // useEffect(() => {
  //   if (getStatus === "contract signed" || getStatus === "credit disbursed") {
  //     setIsButtonDisabled(false);
  //   }
  // }, [getStatus]);

  const handleClick = () => {
    // navigate(route);
  };
  return (
    <div
      className={`rounded-lg bg-[#F0F0F0] w-[256px] min-w-[256px] h-[171px] flex flex-col  justify-between gap-4 px-4 py-4`}
    >
      <div className="flex gap-1 items-center">
        <h3 className="text-[#1F1F1F] text-sm font-medium leading-4">
          {cardTitle}
        </h3>
        <div className="flex items-center justify-center">
          {status && (
            <div
              className={`flex justify-center items-center py-1 px-2 rounded-[6px] ${
                isCompleted ? "bg-[#01B72F]" : "bg-[#FF5555]"
              }`}
            >
              <h5 className="text-[10px] leading-3 text-[#EBF9EE]">{status}</h5>
            </div>
          )}
        </div>
      </div>
      <div>
        <h4 className="text-[#9a9a9a] text-xs w-[95%] ">{info}</h4>
      </div>
      <SimpleButton
        handleClick={handleClick}
        title={btnTitle}
        disabled={disabled}
      />
    </div>
  );
};

export default WithStatusAndButton;
