import React from "react";

function PayoutIcon() {
  return (
    <div>
      <img
        src={"/Assets/payout.png"}
        alt="Payout Icon"
        style={{ width: "24px", height: "24px" }}
      />
    </div>
  );
}

export default PayoutIcon;
