import { Popover } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { rejectApplication } from "../../store/actions/applications/rejectApplication";
import { approveApplication } from "../../store/actions/applications/approveApplication";
import { useNavigate } from "react-router-dom";

const ApplicationMenu = ({ close, anchorEl, selectedRow, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleView = () => {
    // navigate(`/application-summary/${selectedRow?.id}`);
    navigate(`/application-details/${selectedRow?.id}`);
  };

  const { rejectingApplication, approvingApplication } = useSelector(
    (state) => state?.transactions
  );

  const handleReject = (invoiceID) => {
    const body = {
      status: "rejected",
    };
    dispatch(rejectApplication({ body, id: invoiceID }));
  };
  const handleApprove = (invoiceID) => {
    const body = {
      status: "approved",
      profitPercentage: selectedRow?.profitPercentage,
      repaymentDuration: selectedRow?.repaymentDuration,
      fullAmount: selectedRow?.fullAmount,
    };
    dispatch(approveApplication({ body, id: invoiceID }));
  };

  return (
    <Popover onClose={close} handleClose={close} {...props}>
      <div
        style={{
          borderRadius: 20,

          display: "flex",
          flexDirection: "column",
          width: "178px",
          padding: "16px 10px",
          cursor: "pointer",
        }}
      >
        <span
          className=" font-[400] hover:bg-[rgba(43,47,50,0.3)] rounded-md text-[#2B2F32] cursor-pointer text-[14px] font-Poppins p-3  w-full"
          onClick={handleView}
        >
          View Details
        </span>
        <span
          className={`font-[400] hover:bg-[rgba(43,47,50,0.3)] font-Poppins rounded-md text-[#2B2F32] cursor-pointer text-[14px] p-3  w-full ${
            approvingApplication
              ? "bg-[rgba(43,47,50,0.3)] flex justify-center items-center"
              : ""
          }`}
          onClick={
            approvingApplication
              ? null
              : () => handleApprove(selectedRow?.invoiceID)
          }
        >
          {approvingApplication ? (
            <img
              width="20px"
              height="20px"
              alt="Button Icon"
              src={"/Assets/loading.gif"}
            />
          ) : (
            "Approve"
          )}
        </span>
        <span
          className=" font-[400]  hover:bg-[rgba(43,47,50,0.3)] rounded-md font-Poppins text-[#2B2F32] cursor-not-allowed text-[14px] p-3  w-full"
          // onClick={handleView}
        >
          Send Term Sheet
        </span>
        <span
          className={`font-[400] hover:bg-[rgba(255,85,85,0.3)] font-Poppins rounded-md text-[#2B2F32] cursor-pointer text-[14px] p-3  w-full ${
            rejectingApplication
              ? "bg-[rgba(255,85,85,0.3)] flex justify-center items-center"
              : ""
          }`}
          onClick={
            approvingApplication
              ? null
              : () => handleReject(selectedRow?.invoiceID)
          }
        >
          {rejectingApplication ? (
            <img
              width="20px"
              height="20px"
              alt="Button Icon"
              src={"/Assets/loading.gif"}
            />
          ) : (
            "Reject"
          )}
        </span>
      </div>
    </Popover>
  );
};

export default ApplicationMenu;
