import React from "react";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import numberInputFormatter from "../../Helper/numberInputFormatter";

const PhoneInputField = ({
  form,
  name, // The 'name' prop is used to manage value in Formik
  placeholder,
  activeInput,
  setActiveInput,
  label,
  disabled,
  value,
  fullWidth,
  height,
}) => {
  const onFocus = () => {
    setActiveInput && setActiveInput(name);
  };

  return (
    <>
      <div
        className=" phone-input-container1 w-full   lg:w-full"
        style={{ marginBottom: "20px", width: fullWidth ? "100%" : null }}
      >
        <PhoneInput
          className="w-full "
          disabled={disabled}
          style={{ width: "100%", height: height || "65px" }}
          defaultCountry="NG"
          value={form.values[name]} // Use 'name' to retrieve value from Formik state
          onChange={(phoneNumber) => {
            form?.setFieldValue(name, phoneNumber);
          }}
          onFocus={onFocus}
          focusInputOnCountrySelection={false}
          // onCountryChange={(code) => {
          //   if (code) {
          //     const countryCode = "+" + getCountryCallingCode(code);
          //     form?.setFieldValue("phoneCode", countryCode);
          //   }
          // }}
          numberInputProps={{
            height: 10,
          }}
          countrySelectProps={{
            arrowComponent: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginLeft: 10, marginRight: 15 }}>
                  {form?.values?.phoneCode}
                </span>
                <ExpandMoreIcon />
              </div>
            ),
          }}
        />
        {form?.errors[name] && (
          <div className="text-red-500 text-xs mt-1 ml-1">
            {form?.errors[name]}
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneInputField;
