import { createSlice } from "@reduxjs/toolkit";

import {
  createDisbursement,
  getDisbursement,
  getDisbursementLog,
} from "../actions/disbursement/disbursement";
import { submitPayout } from "../actions/disbursement/submitPayout";
import { getPaymentSchedules } from "../actions/investment/getPaymentSchedules";

export const disbursementSlice = createSlice({
  name: "disbursement",
  initialState: {
    error: null,
    pending: false,
    allDisbursement: [],
    allDisbursementLog: [],
    paymentSchedules: [],
    getUser: {},
    success: false,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(submitPayout.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(submitPayout.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(submitPayout.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getDisbursement.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getDisbursement.fulfilled, (state, action) => {
      state.pending = false;
      state.allDisbursement = action.payload;
    });
    builder.addCase(getDisbursement.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getDisbursementLog.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getDisbursementLog.fulfilled, (state, action) => {
      state.pending = false;
      state.allDisbursementLog = action.payload;
    });
    builder.addCase(getDisbursementLog.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getPaymentSchedules.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getPaymentSchedules.fulfilled, (state, action) => {
      state.pending = false;
      state.paymentSchedules = action.payload;
    });
    builder.addCase(getPaymentSchedules.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
  },
});

export default disbursementSlice.reducer;
