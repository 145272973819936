import { createAsyncThunk } from "@reduxjs/toolkit";
import { logoutUser } from "../../reducers/onboardingSlice";
import { Cookies } from "react-cookie";

export const logout = createAsyncThunk(
  "users/logout",
  async (data, { dispatch }) => {
    const cookies = new Cookies();

    cookies.remove("accessToken");
    dispatch(logoutUser());
  }
);
