import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { approveWithdrawalReqs } from "../../store/actions/withdrawal/approveWithdrawalReqs";
import useCurrency from "../../hooks/useCurrency";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";

function ApprovewithdrawalModal({
  open,
  onClose,
  selectedRow,
  setShowApproveModal,
}) {
  const [_currency, getAmount] = useCurrency();
  const userSubPackagePending = useSelector(
    (state) => state?.withdrawalRequests?.approveRequestPending
  );
  const dispatch = useDispatch();
  const handleApprove = async () => {
    dispatch(
      approveWithdrawalReqs({
        amount: selectedRow?.amount,
        withdrawal_request_id: selectedRow?.id,
        currency: selectedRow?.wallet?.currency,
        callback: () => setShowApproveModal(false),
      })
    );
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 540,

          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-3">
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div>
            <h1 className="font-semibold text-2xl mb-4">
              Approve withdrawal Request
            </h1>

            <p className="text-sm mb-4">
              <strong>Acount Name :</strong> {selectedRow?.bank_holder}
            </p>
            <p className="text-sm mb-4">
              <strong>Acount Number :</strong> {selectedRow?.bank_account}
            </p>
            {selectedRow?.currency === "USD" ? (
              <>
                {selectedRow?.dollar_Account?.bank_type ? (
                  <p className="text-sm mb-4">
                    <strong>Bank Type :</strong>{" "}
                    {selectedRow?.dollar_Account?.bank_type}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.beneficiary_name ? (
                  <p className="text-sm mb-4">
                    <strong>Beneficiary Name :</strong>{" "}
                    {selectedRow?.dollar_Account?.beneficiary_name}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.beneficiary_address ? (
                  <p className="text-sm mb-4">
                    <strong>Beneficiary Address :</strong>{" "}
                    {selectedRow?.dollar_Account?.beneficiary_address}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.beneficiary_bank_address ? (
                  <p className="text-sm mb-4">
                    <strong>Beneficiary Bank Address :</strong>{" "}
                    {selectedRow?.dollar_Account?.beneficiary_bank_address}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.beneficiary_country ? (
                  <p className="text-sm mb-4">
                    <strong>Beneficiary Country :</strong>{" "}
                    {selectedRow?.dollar_Account?.beneficiary_country}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.routing_number?.length > 3 ? (
                  <p className="text-sm mb-4">
                    <strong>Routing Number :</strong>{" "}
                    {selectedRow?.dollar_Account?.routing_number}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.sort_code?.length > 3 ? (
                  <p className="text-sm mb-4">
                    <strong>Sort Code :</strong>{" "}
                    {selectedRow?.dollar_Account?.sort_code}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.swift_code?.length > 3 ? (
                  <p className="text-sm mb-4">
                    <strong>Swift Code :</strong>{" "}
                    {selectedRow?.dollar_Account?.swift_code}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.IBAN?.length > 3 ? (
                  <p className="text-sm mb-4">
                    <strong>IBAN :</strong> {selectedRow?.dollar_Account?.IBAN}
                  </p>
                ) : (
                  <></>
                )}
                {selectedRow?.dollar_Account?.SWIFT_BIC?.length > 3 ? (
                  <p className="text-sm mb-4">
                    <strong>SWIFT BIC :</strong>{" "}
                    {selectedRow?.dollar_Account?.SWIFT_BIC}
                  </p>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <p className="text-sm mb-4">
                <strong>Bank :</strong> {selectedRow?.bank_name}
              </p>
            )}
            <p className="text-sm mb-4">
              <strong>Amount :</strong>{" "}
              {getCurrencySymbol(selectedRow?.currency) +
                getAmount(selectedRow?.amount)}
            </p>
            <p className="text-sm mb-4">
              <strong>
                Balance :{" "}
                {getCurrencySymbol(selectedRow?.currency) +
                  getAmount(selectedRow?.wallet_balance)}
              </strong>
            </p>
            <p className="text-sm mb-4">
              <strong>Withdrawal Date :</strong>{" "}
              {new Date(selectedRow?.withdrawal_date).toDateString()}
            </p>

            <div className="flex mt-6 w-full items-center">
              <CustomButton
                variant="outlined"
                customColor="#fff"
                children="Cancel"
                width="100%"
                height="44px"
                borderColor="#D0D5DD"
                color="#344054"
                iconWidth="22px"
                margin="0px 25px 0px 0px"
                onClick={onClose}
              />

              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children={"Approve Request"}
                width="100%"
                height="44px"
                loading={userSubPackagePending}
                padding="10px"
                color={"#fff"}
                onClick={handleApprove}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ApprovewithdrawalModal;
