import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API_DELETE_TEMPLATE, API_GET_ALL_TEMPLATES, API_GET_ALL_TEMPLATEs, API_SAVE_TEMPLATE, API_SEND_EMAIL } from "../../../config/api";
import { getAllTemplates } from "./getAllTemplates";

export const sendEmail = createAsyncThunk(
  "user/sendEmail",
  async (formData, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_SEND_EMAIL}`,
        contentType: "multipart/form-data",
        serializerConfig: { indexes: null },
        data: formData
      });

      toast.success('Email Sent Successfuly')
      return response?.data;
    } catch (error) {
      //const msg = proccessErrorMessage(error);
      toast.error(error);
      return rejectWithValue(error.message);
    }
  }
);


export const deleteTemplate = createAsyncThunk(
  "template/delete",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "DELETE",
        path: `${API_DELETE_TEMPLATE}/${id}`,
    
      });
toast.success('Template Deleted Succesfully')
dispatch(getAllTemplates())
      return response?.data;
    } catch (error) {
      toast.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const saveTemplate = createAsyncThunk(
  "template/save",
  async ({data, callback}, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_SAVE_TEMPLATE}`,
        data: {
          template_title: data?.title,
          template_content: data?.content
        }
      });

      toast.success('Draft Saved Successfuly')
      dispatch(getAllTemplates())
      callback()
      return response?.data;
    } catch (error) {
      //const msg = proccessErrorMessage(error);
      toast.error(error);
      return rejectWithValue(error.message);
    }
  }
);
