import React from "react";

const TextArea = ({
  label,
  placeholder,
  blackBorder,
  onChange,
  name,
  id,
  value,
  disabled,
}) => {
  return (
    <div>
      <div
        className={`input-container ${
          blackBorder || (value && "input-border")
        }`}
      >
        <div>
          <label htmlFor="Fname">{label}</label>
          <textarea
            disabled={disabled}
            name={name}
            value={value}
            id={id}
            rows="5"
            cols="10"
            onChange={onChange}
            placeholder={placeholder}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default TextArea;
