import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({
  children,
  variant,
  customColor,
  onClick,
  width,
  disabled,
  height,
  padding,
  loading,
  icon,
  margin,
  borderColor,
  iconWidth,
  color,
  fz,
  className,
  ...props
}) => {
  const buttonStyles = {
    backgroundColor: customColor,
    width: width || "auto",
    height: height || "auto",
    opacity: disabled ? 0.6 : 1,
    padding: padding,
    margin: margin,
    borderRadius: 8,
    borderColor: borderColor,
    color: color,
    iconWidth: iconWidth,
    textTransform: "none",
    fontSize: fz,
  };

  return (
    <Button
      variant={variant}
      onClick={disabled ? null : onClick}
      style={buttonStyles}
      className={`font-Source-Sans-Pro font-[500] text-[red]!min-w-[max-content] ${className}`}
    >
      {icon && (
        <span style={{ marginRight: "8px", width: iconWidth }}>{icon}</span>
      )}
      {loading ? (
        <img
          width="24px"
          height="24px"
          alt="Button Icon"
          src={"/Assets/loading.gif"}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default CustomButton;
