import React from "react";

function AdminUserIcon() {
  return (
    <div>
      <img
        src={"/Assets/admin-user.png"}
        alt="User Icon"
        style={{ width: "48px", height: "48px" }}
      />
    </div>
  );
}

export default AdminUserIcon;
