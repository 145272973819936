import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const BarChartComponent = ({ activeDuration }) => {
  const data = [
    { month: "Jan", totalUsers: 120, newSignUp: 90, renewal: 100 },
    { month: "Feb", totalUsers: 130, newSignUp: 95, renewal: 110 },
    { month: "Mar", totalUsers: 140, newSignUp: 100, renewal: 120 },
    { month: "Apr", totalUsers: 150, newSignUp: 110, renewal: 130 },
    { month: "May", totalUsers: 160, newSignUp: 120, renewal: 140 },
    { month: "Jun", totalUsers: 170, newSignUp: 130, renewal: 150 },
    { month: "Jul", totalUsers: 180, newSignUp: 140, renewal: 160 },
    { month: "Aug", totalUsers: 190, newSignUp: 150, renewal: 170 },
    { month: "Sep", totalUsers: 200, newSignUp: 160, renewal: 180 },
    { month: "Oct", totalUsers: 210, newSignUp: 170, renewal: 190 },
    { month: "Nov", totalUsers: 220, newSignUp: 180, renewal: 200 },
    { month: "Dec", totalUsers: 230, newSignUp: 190, renewal: 210 },
  ];

  const filteredData = data.filter((entry) => {
    switch (activeDuration) {
      case "12months":
        return true;
      case "3months":
        return data.indexOf(entry) >= data?.length - 3;
      case "30days":
        return data.indexOf(entry) >= data?.length - 30;
      case "7days":
        return data.indexOf(entry) >= data?.length - 7;
      case "24hours":
        return data.indexOf(entry) >= data?.length - 1;
      default:
        return true;
    }
  });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={filteredData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis domain={[0, 200]} ticks={[0, 100, 120, 140, 160, 180, 200]} />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalUsers" fill="#FF9C66" />
        <Bar dataKey="newSignUp" fill="#004EEB" />
        <Bar dataKey="renewal" fill="#E478FA" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
