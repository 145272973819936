import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_ADD_BULK_PREVIOUS_RECORDS } from "../../../config/api";
import { getAllUser } from "../userManagement/management";

export const addBulkPreviousRecords = createAsyncThunk(
  "investment/addBulkPreviousRecords",
  async ( payload, {dispatch, rejectWithValue }) => {

    try {
      const response = await Client({
        method: "POST",
        path: API_ADD_BULK_PREVIOUS_RECORDS,
        contentType: "multipart/form-data",
        data: payload.data
      });
      toast.success("Records added Successfully");
      dispatch(getAllUser());

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(msg);
    }
  }
);
