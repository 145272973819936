import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";

import ActiveIcon from "../../components/Icons/ActiveIcon";

import ReturnIcon from "../../components/Icons/ReturnIcon";
import PayoutIcon from "../../components/Icons/PayoutIcon";
import InvestmentCard from "../../components/cards/InvestmentCard";

import { useDispatch, useSelector } from "react-redux";

import NewBarCharts from "../../components/charts/NewBarCharts";
import RevenueCharts from "../../components/charts/RevenueCharts";
import { getAllUser } from "../../store/actions/userManagement/management";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";
import { getUserAnalytcs } from "../../store/actions/userManagement/getUserAnalytcs";
import { getInvestmentMetrics } from "../../store/actions/transactions/getInvestmentMetrics";

const Dashboard = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const user = useSelector((state) => state.onboarding.user);
  const { investmentMetrics } = useSelector((state) => state?.investment);
  const { allUsers: allUser, getUserAnalytcsData } =
    useSelector((state) => state?.user) || [];
  const allInvestment = useSelector(
    (state) => state?.investment?.allInvestments
  );

  useEffect(() => {
    dispatch(getAllUser());
    dispatch(getUserAnalytcs());
    dispatch(getAllProductInvestment());
  }, []);

  useEffect(() => {
    dispatch(getInvestmentMetrics());
  }, [currency]);

  let investmentDetails = [
    {
      id: 1,
      icon: <ActiveIcon />,
      iconColor: "#daf1f3",
      title: "Active Investments",
      investment: investmentMetrics?.total_investments || "--",
    },
    {
      id: 2,
      icon: <ReturnIcon />,
      iconColor: "#daf1f3",
      title: `Total Funding Raised`,
      investment: 0,
    },
    {
      id: 3,
      icon: <PayoutIcon />,
      iconColor: "#f9e3be",
      title: `Upcoming Maturities`,
      investment: 0,
    },
    {
      id: 4,
      icon: <ActiveIcon />,
      iconColor: "#daf1f3",
      title: `Total Number of Users`,
      investment: getUserAnalytcsData?.total_user,
    },
    {
      id: 5,
      icon: <ReturnIcon />,
      iconColor: "#daf1f3",
      title: `New User Applications`,
      investment: 0,
    },
    {
      id: 6,
      icon: <PayoutIcon />,
      iconColor: "#f9e3be",
      title: `Member Subscriptions`,
      investment: getUserAnalytcsData?.users_with_premium_plan,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DashboardLayout>
      <div className="px-8 ">
        <div className="mb-6 mt-12">
          <h1 className="text-2xl capitalize  mb-2">
            Welcome back,
            <span className="text-2xl capitalize font-bold">
              {" "}
              {user?.name?.split(" ")[0]}
            </span>
          </h1>
          {/* <span>Welcome back</span> */}
        </div>

        <div className="col-span-12 md:col-span-8">
          <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
            {investmentDetails?.map((detail) => (
              <div key={detail.id} className="col-span-1 mb-2 md:mb-4 w-full ">
                <InvestmentCard
                  width={"100%"}
                  height={188}
                  icon={detail.icon}
                  title={detail.title}
                  iconColor={detail.iconColor}
                  investment={detail.investment}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4  mb-8  bg-white rounded-l-xl shadow-l">
          <NewBarCharts allUser={allUser} />
        </div>

        <div>
          <RevenueCharts />
        </div>

        <div className="mb-3 mt-5 "></div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
