import { Menu } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const WaitListMenu = ({
  type,

  handleClose,

  anchorEl,

  selected,
  handleView = { handleView },

  ...props
}) => {
  const user = useSelector((state) => state.onboarding.user);

  const dispatch = useDispatch();

  return (
    <Menu {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "220px",
          paddingLeft: 5,
          paddingRight: 5,
          cursor: "pointer",
        }}
      >
        <span
          className=" font-[500] hover:bg-gray-100  cursor-not-allowed p-3  hover:rounded-lg w-full"
          onClick={handleView}
        >
          View
        </span>
      </div>
    </Menu>
  );
};

export default WaitListMenu;
