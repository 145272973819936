import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_LOGIN } from "../../../config/api";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";

// import { processErrorMessage } from "../../../Helper/processErrorMessage";

export const login = createAsyncThunk(
  "users/login",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_LOGIN,
        data,
      });
      if (response.data && response?.data?.data?.access_token) {
        const { access_token } = response?.data?.data;

        const date = new Date();
        const nextDate = date.setMinutes(date.getMinutes() + 20);
        const cookies = new Cookies();

        cookies.set("accessToken", access_token, {
          expires: new Date(nextDate),
        });

        return response?.data?.data;
      } else {
        toast.error("Login failed: Access token not found in the response");
        return rejectWithValue("Access token not found in the response");
      }
    } catch (error) {
      toast.error("Login failed: Invalid email or password");

      return rejectWithValue(
        error.response?.data || "An unexpected error occurred"
      );
    }
  }
);
