import { createSlice } from "@reduxjs/toolkit";
import { login } from "../actions/onboarding/login";
import { logout } from "../actions/user/logout";
import { forgotPassword } from "../actions/onboarding/forgotPassword";
import { loginWithGoogle } from "../actions/onboarding/loginWithGoogle";
import { resetPassword } from "../actions/onboarding/resetPassword";
import { resendOTP } from "../actions/onboarding/resendotp";

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    user: null,
    error: null,
    // pending: false,
    success: false,
    forgotPasswordSuccess: false,
    resetPasswordSuccess: false,
    forgotPasswordFailed: false,
    resetPasswordFailed: false,
  },

  reducers: {
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.pending = false;
      state.success = false;
      state.failed = false;
      state.tokenIsValid = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.pending = false;
        state.user = action.payload.user;
        state.auth = action.payload;
        state.pending = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(resendOTP.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.pending = false;
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.pending = false;
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.pending = true;
        state.success = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.pending = false;
        state.error = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.pending = false;
        state.resetPasswordSuccess = true;
        state.forgotPasswordSuccess = false;
      })

      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        state.pending = false;
        state.user = action.payload;
      })

      .addCase(forgotPassword.rejected, (state, action) => {
        state.pending = false;
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.pending = false;
        state.forgotPasswordSuccess = true;
        state.resetPasswordSuccess = false;
      })

      .addCase(logout.fulfilled, (state, action) => {
        state.user = null;
        state.pending = false;
      });
  },
});

export const { logoutUser, setNetwork } = onboardingSlice.actions;

export default onboardingSlice.reducer;
