import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_CREATE_DISBURSEMENT } from "../../../config/api";

export const submitPayout = createAsyncThunk(
  "disburse/create",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CREATE_DISBURSEMENT,
        data,
      });
      toast.success("Payout processed successfully");
      data.callback();
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
