import React from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";

import Loader from "../../components/SharedLayouts/Loader";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import BIPUsersList from "../../components/dashboard/user/BIPUsersList";

const BIPUsers = () => {
  const loading = useSelector((state) => state.portfolio.pending) || false;
  const { allUsers: allUser, pending } =
    useSelector((state) => state?.user) || [];

  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <div className="flex items-center mb-6 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <div className=" font-semibold text-[#475467] text-sm sm:text-base bg-[#F9FAFB] p-1 px-3 rounded-md  ">
            BIP Users
          </div>
        </div>

        {(loading || pending) && <Loader />}

        <>
          <div className="mt-6">
            <BIPUsersList />
          </div>
        </>
      </div>
    </DashboardLayout>
  );
};

export default BIPUsers;
