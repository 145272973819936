import React, { useEffect } from "react";
import CustomTable from "./customTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../store/actions/userManagement/getTransactionHistory";
import { useParams } from "react-router-dom";
import Loader from "../SharedLayouts/Loader";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";
import useCurrency from "../../hooks/useCurrency";
import GridTable from "./gridTable";

function UserTransactionTable() {
  const dispatch = useDispatch();
  const [_currency, getAmount] = useCurrency();
  const { id } = useParams();
  const loading = useSelector((state) => state?.user?.loading);
  const { userTransactions, userTransactionsDetails } = useSelector(
    (state) => state?.user
  );

  useEffect(() => {
    dispatch(fetchTransactions(id));
  }, []);

  const columns = [
    {
      field: "transactionID",
      headerName: "Transaction ID",
      width: 200,
      renderCell: (params) => params.row?.transaction_id,
    },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      width: 200,
      renderCell: (params) => params.row?.transaction_type,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) =>
        params.row?.currency === "NGR"
          ? `₦${getAmount(params.row?.amount)}`
          : `$${getAmount(params.row?.amount)}`,
    },

    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params) =>
        new Date(params.row?.transaction_date).toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    },

    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return params.row?.status === "success" ? (
          <div className="bg-[#ecfdf3] text-[#067647] p-2 rounded-[5px]">
            {params.row?.status}
          </div>
        ) : (
          <div className="bg-[#ff00001a] text-[red]">{params.row?.status}</div>
        );
      },
    },
  ];

  return (
    <div style={{ height: "330px" }}>
      {loading ? (
        <Loader />
      ) : (
        <GridTable
          type={"transaction"}
          rows={userTransactions}
          pageInfo={userTransactionsDetails}
          columns={columns}
        />
      )}
    </div>
  );
}

export default UserTransactionTable;
