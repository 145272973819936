import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  API_DELETE_USER,
  API_RESET_USER_TRANSACTION_PIN,
} from "../../../config/api";
import { getAllUser } from "./management";

export const resetTransactionPin = createAsyncThunk(
  "user/resetTransactionPin",
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: `${API_RESET_USER_TRANSACTION_PIN}${data?.user_id}`,
      });

      toast.success("User Transaction Pin Reset Successfuly");
      callback();

      dispatch(getAllUser());
      return response?.data;
    } catch (error) {
      //const msg = proccessErrorMessage(error);
      toast.error(error);
      return rejectWithValue(error.message);
    }
  }
);
