import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_GET_BIP_USERS } from "../../../config/api";

export const getBIPUsers = createAsyncThunk(
  "getBIPUsers/get",
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await Client({
        method: "GET",
        path: API_GET_BIP_USERS,
      });

      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
