import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_FETCH_ALL_WITHDRAWAL_REQUESTS } from "../../../config/api";

export const fetchAllWithdrawalReqs = createAsyncThunk(
  "withdrawalRequests/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_FETCH_ALL_WITHDRAWAL_REQUESTS}`,
      });
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(msg);
    }
  }
);
